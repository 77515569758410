/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { MenuItems } from "./AsideMenuItems";
import { connect } from 'react-redux'

const AsideMenuList = ({ layoutProps, me}) => {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  const getMenu = (parentPath, menuItems) => {
    let menus = menuItems.map((item, index) => {
      let path = parentPath + item.path
      return me && item.accessTypes.includes(me.user_type) ? (
        <li className={`menu-item ${getMenuItemActive(path, false)}`}
          aria-haspopup="true"
          key={`menu-items-${index + 1}`}>
          <NavLink className="menu-link" to={path}>
            {
              item.iconSvg ? (
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl(item.iconSvg)} />
                </span>
              ) :
                (
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                )
            }
            <span className="menu-text">{item.label}</span>
            {item.hasSubMenu && <i className="menu-arrow" />}
          </NavLink>
          {item.hasSubMenu ? (
            <div className="menu-submenu ">
              <ul className="menu-subnav">
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">{item.label}</span>
                    </span>
                  </li>
                  {getMenu(path + '/', item.subMenuItems)}
                </ul>
              </ul>
            </div>
          ) : null}
        </li>
      ) : <></>
    })
    return menus
  }
  return (
    <ul className={`menu-nav ${layoutProps.ulClasses}`}>
      {getMenu("/", [...MenuItems])}
    </ul>
  );
};

const mapStateToProps = function (state) {
  return {
    me: state.me,
  }
}

export default connect(mapStateToProps)(AsideMenuList);
