// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import {FileUpload} from "../../../Utils/Components/FileUpload/FileUpload";


const FILE_SIZE = 1024 * 1024 * 2;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
//   "application/msword",
//   "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const validationSchema = Yup.object().shape({
    bill: Yup.mixed().when('delivery_status', {
        is: 'In-Progress',
        then: Yup.mixed()
        // .required('Test report is Required!')
        .test(
            "fileSize",
            "Bill file size should not be greater than 2mb",
            function (value) {
                if (!value) return true;
                if(typeof(value) === "string") return true;
                return value.size <= FILE_SIZE
            })
        .test(
        "fileFormat",
        "Bill format should be jpg, jpeg, png, pdf",
        function (value) {
            if (!value) return true;
            if(typeof(value) === "string") return true;
            return SUPPORTED_FORMATS.includes(value.type)
        })
      }),})

export default function App(props) {
    const {user_type, Quotedata, EditQuoteData} = props
    const [error, setError] = React.useState(<p></p>)
    const [billError, setBillError] = React.useState(<p></p>)

    // console.log('Quotedata', Quotedata);

    const initialValues = {
        delivery_status : Quotedata?.order?.delivery_status,
        delivery_info : Quotedata?.order?.delivery_info ? Quotedata?.order?.delivery_info : "",
        bill : Quotedata?.order?.bill_path ? Quotedata?.order?.bill_path : "",
    }
    
    const bill_path = Quotedata?.order?.bill_path ? Quotedata?.order?.bill_path : ""


     return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    
                    if (values.bill === "") {
                        setBillError(<p style={{color:"red"}} >Bill is Required!</p>)
                    } else {
                        const formData = new FormData();
                        formData.append("delivery_status", values.delivery_status)
                        formData.append("delivery_info", values.delivery_info)
                        if (typeof(values.bill) === "string") {
                            formData.append("bill", "")
                        } else {
                            formData.append("bill", values.bill)
                        }
                        EditQuoteData(formData)
                    }
                }
    }
            >
                {({ handleSubmit, setFieldValue, resetForm, handleChange, handleBlur, touched, values, errors, dirty }) => (
                    <>
                        {!(initialValues.delivery_status === "Cancelled") && <Form className="form form-label-right"> 

                <div className=" row" >
                <div className="col-lg-6">
                <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-age-native-simple">Status</InputLabel>
                    <Select
                    native
                    value={values.delivery_status}
                    name="delivery_status"
                    onBlur={handleBlur}
                    onChange={e => {
                        if (values.delivery_status === "Pending" && e.target.value === "In-Progress") {
                            setFieldValue("delivery_status", e.target.value)
                        }
                        if (values.delivery_status === "In-Progress" && e.target.value === "Completed") {
                            if (values.delivery_info === "") {
                                setError(<p style={{color:"red"}} >Shipping Details is Required!</p>)
                            } else {
                                if (values.bill === "") {
                                    setBillError(<p style={{color:"red"}} >Bill is Required!</p>)
                                } else {
                                    setFieldValue("delivery_status", e.target.value)
                                    
                                }
                            }
                        }
                    }}
                    label="Status"
                    >
                    <option value="Pending">Pending</option>
                    <option value="In-Progress">In-Progress</option>
                    <option value="Completed">Completed</option>
                    </Select>
                </FormControl>
                </div>
                <div className="col-lg-6">
                        {values.delivery_status === "In-Progress" && <TextField
                        required
                        fullWidth
                        id="outlined-textarea"
                        label="Shipping Details"
                        placeholder="Shipping Details"
                        multiline
                        variant="outlined"
                        value={values.delivery_info}
                        name='delivery_info'
                        onChange={e => {
                            setFieldValue("delivery_info", e.target.value)
                            setError(<p></p>)
                        }}
                        onBlur={handleBlur}
                        />}
                        {error}
                        </div>
                    </div>
                    <div className=" row" >
                <div className="col-lg-6">
                </div>
                <div className="col-lg-6">
                        {values.delivery_status === "In-Progress" && 
                        <>
                        <FileUpload
                        mandatory
                        label='Upload Bill'
                        errors={errors.bill}
                        name="bill"
                        value={values.bill}
                        onChange={value => {
                            setFieldValue("bill", value)
                            setBillError(<p></p>)
                            console.log('value', value);
                        }}
                        onBlur={handleBlur}
                        />
                        { !values.bill.name && bill_path &&
                        <a href={bill_path} target="_blank" download="filename">View uploaded bill</a>
                        }
                        </>}
                        {billError}
                        </div>
                    </div>

                    <div className=" row" >
                <div className="col-lg-10">
                        </div>
          <div className="col-lg-2" style={{display: 'flex', justifyContent: 'flex-end'}}>
                    {Quotedata?.order?.delivery_status === "Completed" ? (<></>) : (<button style={{width:'130px'}}
                    disabled={!dirty}
                    type="submit"
                    className="btn btn-primary"
                    >
                    Save
                    </button>)}
                    </div>
                    </div>
                        </Form>}
                    </>
                )}
            </Formik>
        </>
    );
}
