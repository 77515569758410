import React from "react";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import getSymbolFromCurrency from 'currency-symbol-map'
import { GetCurrencyCode } from "../../../../Utils/GetData";
import { Card } from 'react-bootstrap';

const useStyles = makeStyles(theme => ({
    label: {
        // width: '150px',
        color: 'black',
  
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '26px',
        letterSpacing: '-0.008em',
  
      },
      content : {
        color: 'black',
        marginTop: '10px',
        marginBottom: '5px',
  
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '21px',
      }
    }));


export function Records(props) {
    const classes = useStyles();
    const { OrderData } = props
    // console.log('records', OrderData.records);
    return (
      <>
        {OrderData && OrderData.records && OrderData.records.map((item) => (
          <>
          <Card bg="light">
            <Card.Body>
              <Typography className={classes.content}>
                Appointment ID : {item?.unique_id}
                </Typography>

                <Typography className={classes.content}>
                Tax : {getSymbolFromCurrency(GetCurrencyCode())} {item?.tax}
                </Typography>

                <Typography className={classes.content}>
                Commission : {getSymbolFromCurrency(GetCurrencyCode())} {item?.commission}
                </Typography>
                
                <Typography className={classes.content}>
                Total : {getSymbolFromCurrency(GetCurrencyCode())} {item?.total}
                </Typography>
            </Card.Body>
          </Card>
          <br/>
          </>
        ))
        }
        </>
    );
}
