import React, {useRef, useState, forwardRef} from 'react'
import DatePicker from 'react-datepicker';
import moment from 'moment'

function Input (props) {
    let {
        name,
        type = 'text',
        onChange = () => { },
        onBlur = () => { },
        hasError = false,
        feedback,
        touched,
        errors,
        mandatory = false,
        required = false,
        value,
        onFocus,
        placeholder,
        ...rest
    } = props
    const label = props.label || name || ''
    value = (value === '' || value === null || typeof value === "undefined") ? null : new Date(value)
    mandatory = mandatory ? mandatory : required;

    
    if (touched && errors && !hasError) {
        hasError = true
        if (!feedback) {
            feedback = errors
        }
    }
    
	//Ref needed for custom input
	const refCustomInput = useRef();

	//State hooks
	const [stateHasFocus, setStateHasFocus] = useState(false); //Initial value

	//Custom input
	const CustomInput = forwardRef((props, ref) => {
		const {autoFocus, ...remaining} = props;

		return <input type="text" ref={ref} autoFocus={stateHasFocus} {...remaining} />;
	});

    return (<>
        <label>{label}</label>{mandatory && <span className="text-danger">&nbsp;*</span>}
        <div className={"form-group form-focus" + (value || value === 0 ? ' focused' : '')}>
            
            <DatePicker
            customInput={<CustomInput ref={refCustomInput} />}
                className={hasError ? 'form-control is-invalid' : 'form-control'}
                  selected={ value }
                  onChange={val => {
                      if (val && (new Date("9999-12-31") >= val)) {
                        const date = val ? moment(val).format('YYYY-MM-DD') : ''
                        onChange(String(date))
                      }
                  }}
                  onFocus={evt => {
                      setStateHasFocus(true);
      
                      //Notify parent
                      if (onFocus) {
                          onFocus.call(this, evt);
                      }
                  }}
                  onBlur={evt => {
                      setStateHasFocus(false);
      
                      //Notify parent
                      if (onBlur) {
                          onBlur.call(this, evt);
                      }
                  }}
                  name={name}
                  dateFormat="dd/MM/yyyy"
                  {...rest}
                  showMonthDropdown
                  showYearDropdown
                  />
            {!value && <label className="focus-label">dd/mm/yyyy</label>}
            {hasError && feedback && (
                <div style={{ margin: 0 }} className="invalid-feedback d-block">{feedback}</div>
            )}
        </div>
    </>)
}

export default Input