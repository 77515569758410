
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import CheckBox from "./CheckBox";
import DeleteModal from "./page-components/DeleteModal";
import EditModel from "./page-components/EditModel";
import getSymbolFromCurrency from 'currency-symbol-map';
import { GetCurrencyCode } from "../../../Utils/GetData";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 1200,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

export default function SimpleTable(props) {
  const classes = useStyles();
  const { Quotedata, searchlist, SaveNewQuoteData, user_type } = props
  const currency_code = getSymbolFromCurrency(GetCurrencyCode())
  // console.log('Quotedata', Quotedata);

  const GetMedicineTotal = (totals, row) => {
    var price = row.price
    var quantity = row.unit
    if (!price) {
        price = 0
    }
    if (!quantity) {
        quantity = 0
    }
    return ((parseFloat(totals) + (parseFloat(price) * parseFloat(quantity))))
}


  return (
    <Paper className={classes.root}>
    <div className={classes.tableWrapper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {/* <TableCell align="right"></TableCell> */}
            <TableCell>Composition</TableCell>
            <TableCell align="right"style={{width:'150px'}}>Brand Name</TableCell>
            <TableCell align="right">Manufacturer</TableCell>
            <TableCell style={{width: '150px'}} align="right">Dosage</TableCell>
            <TableCell align="right">Duration</TableCell>
            {<TableCell align="right" style={{width:'200px'}}>Substitution Allowed</TableCell>}
            <TableCell style={{width:'200px'}} align="right">Note</TableCell>
            <TableCell  align="right" style={{width:'300px'}}>Instructions</TableCell>
            {/* {parseInt(Quotedata.status) === 1 && <TableCell align="center">Unit Price ({getSymbolFromCurrency(GetCurrencyCode())})</TableCell>}
            {parseInt(Quotedata.status) === 1 && <TableCell align="center">Unit</TableCell>} */}
            {/* {parseInt(Quotedata.status) === 0 && <TableCell align="center">Actions</TableCell>} */}
          </TableRow>
        </TableHead>
        <TableBody>
          {Quotedata.medicine_list[0] != null && Quotedata.medicine_list.map((row, index) => (
            <TableRow >
            {/* <TableCell align="center">
            <CheckBox />
            </TableCell> */}
              <TableCell component="th" scope="row">
                {row.medicine && row.medicine.composition}
              </TableCell>
              <TableCell align="right" style={{width:'150px'}}>{row.medicine_name ? (row.medicine_name) : (row.medicine && row.medicine.name)}</TableCell>
              <TableCell align="right">{row.medicine && row.medicine.manufacturer}</TableCell>
              <TableCell style={{width: '150px'}} align="right">
                    {row.dosage ? (row.dosage ? row.dosage : "NA") : (row.medicine && row.medicine.dosage ? row.medicine.dosage : "NA")}
              </TableCell>
              <TableCell align="right">{row.duration ? (row.duration ? row.duration : "NA") : (row.medicine && row.medicine.duration ? row.medicine.duration : "NA")}</TableCell>
              {row.substitution_allowed ? (<TableCell style={{width:'200px'}} align="right">
              {row.substitution_allowed == '1' && 'Yes'}
                {row.substitution_allowed == '0' && 'No'}
              {row.medicine.substitution_allowed == '1' && 'Yes'}
                {row.medicine.substitution_allowed == '0' && 'No'}
              </TableCell>) : <TableCell style={{width:'200px'}} align="right">No</TableCell>}
              <TableCell  style={{width:'200px'}} align="right">{row.note ? row.note : "NA"}</TableCell>
              <TableCell  align="right" style={{width:'200px'}}>{row.instructions ? (row.instructions ? row.instructions : "NA") : (row.medicine && row.medicine.instructions ? row.medicine.instructions : "NA")}</TableCell>
              {/* {parseInt(Quotedata.status) === 1 && <TableCell align="center">
                {row?.price}
              </TableCell>}
              {parseInt(Quotedata.status) === 1 && <TableCell align="center">
                {row?.unit}
              </TableCell>} */}
              {/* {parseInt(Quotedata.status) === 0 && <TableCell align="center">
              <div className="d-flex justify-content-center">
                  <div className="p-2 col-example text-left">
              {row.substitution_allowed == '1' && 
                  <EditModel 
                  searchlist={searchlist}
                  Quotedata={Quotedata}
                  index={index}
                  SaveNewQuoteData={SaveNewQuoteData}
                  user_type={user_type}
                  />
              }
                  </div>
                  <div className="p-2 col-example text-left">
                  <DeleteModal
                    Quotedata={Quotedata}
                    index={index}
                    SaveNewQuoteData={SaveNewQuoteData}
                    user_type={user_type}
                    />
                  </div>
                </div>
              </TableCell>} */}
            </TableRow>
          ))}

          {/* {parseInt(Quotedata.status) === 1 &&  Quotedata?.medicine &&  
            <>
            <TableRow>
            <TableCell colSpan={5} rowSpan={4} />
            <TableCell colSpan={2} >Sub Total :</TableCell>
            <TableCell colSpan={2} align="right">{currency_code} {Quotedata.medicine.medicine_list.reduce((totals, row) => GetMedicineTotal(totals, row), 0)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} >Home Delivery Charge :</TableCell>
            <TableCell colSpan={2} align="right">{currency_code} {Quotedata.medicine.delivery_charge}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} >Discount :</TableCell>
            <TableCell colSpan={2} align="right">{currency_code} {Quotedata.medicine.discount}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} >Total :</TableCell>
            <TableCell colSpan={2} align="right">{currency_code} {Quotedata.medicine.total}</TableCell>
          </TableRow>
          </>
          } */}
        </TableBody>
      </Table>
    </div>
    </Paper>
  );
}
