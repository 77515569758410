import React from "react";

 export function ImageLoad(props) {

  const onOpenPdf = () => {
    window.open(props.image)
  }
   return (
     <div>
       {props.image && <embed 
     onClick={onOpenPdf}
      src={props.image}
      alt="image"
      style={props.style}
      />
      }  
     </div>
     
   );
 }