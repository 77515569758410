import React from "react";
import { ViewCard } from "./ViewCard";
import PharmacyTable from "./PharmacyTable";
import LaboratoryTable from "./LaboratoryTable";
import Spinner from "../../../Utils/Components/CustomSpinner";
import { AddPharmacyQuote, AddLaboratoryQuote } from "../../../modules/Apis/Pharmacy/Quotes/QuotesApi";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import { useHistory } from "react-router-dom";
import SendForm from "./SendForm";




export default function ShowForm (props) {
    const history = useHistory();
    const {Quotedata, searchlist, SaveNewQuoteData, previousStep, user_type} = props
    const [loading, setLoading] = React.useState(false)
    // console.log('Quotedata', Quotedata);

    var rows
    if (user_type == "PHARMACIST") {
        rows = Quotedata.medicine_list
    }
    if (user_type == "LABORATORY") {
        rows = Quotedata.test_list
    }

    

const GetPharmacyTotal = (totals, row) => {
    var price = row.medicine.price_per_strip
    var quantity = row.quantity
    if (!price) {
        price = 0
    }
    if (!quantity) {
        quantity = 0
    }
    return (parseFloat(totals) + (parseFloat(price) * parseFloat(quantity)))
}

const GetLaboratoryTotal = (totals, row) => {
    var price = row.test.price
    if (!price) {
        price = 0
    }
    return (parseFloat(totals) + parseFloat(price))
}

var total = 0
if (user_type == "PHARMACIST") {
    total = rows.reduce((totals, row) => GetPharmacyTotal(totals, row), 0);
}
if (user_type == "LABORATORY") {
    total = rows.reduce((totals, row) => GetLaboratoryTotal(totals, row), 0);
}


const onClickAddPharmacyQuote = (values) => {
    setLoading(true)
    const medList = []
    Quotedata.medicine_list.map((item) =>{
        // console.log('item', item);
    const value = {
        medicine_id : item.medicine.id,
        price : item.medicine.price_per_strip,
        unit : item.quantity,
        dosage : item.dosage,
        duration : item.duration,
        instructions : item.instructions,
        no_of_refill : item.no_of_refill,
    }
        medList.push(value)
    })
    const data = {
        quote_request_id : Quotedata.id,
        medicine_list : medList,
        total : (parseFloat(total) + parseFloat(values.delivery_charge)) - parseFloat(values.discount),
        discount : values.discount,
        delivery_charge : values.delivery_charge,
        valid_till: values.valid_till
    }
    AddPharmacyQuote(data)
        .then(items => {
            SuccessToast("Quote generated successfully")
          setLoading(false)
          history.push('/quote/request/list')
        })
        .catch(err => {
          ErrorToast(err)
          setLoading(false)
      })
}


const onClickAddLaboratoryQuote = (values) => {
    setLoading(true)
    const TestList = []
    Quotedata.test_list.map((item) =>{
    const value = {
        test_id : item.test.id,
        price : item.test.price,
        instructions : item.instructions,
    }
    TestList.push(value)
    })
    const data = {
        quote_request_id : Quotedata.id,
        test_list : TestList,
        total : (parseFloat(total) + parseFloat(values.delivery_charge)) - parseFloat(values.discount),
        discount : values.discount,
        delivery_charge : values.delivery_charge,
        valid_till: values.valid_till
    }
    AddLaboratoryQuote(data)
        .then(items => {
          SuccessToast("Quote generated successfully")
          setLoading(false)
          history.push('/quote/request/list')
        })
        .catch(err => {
          ErrorToast(err)
          setLoading(false)
      })
}



if (loading) {
    return (
      <Spinner />
    )
  }

    return (
        <div>
            <ViewCard Quotedata={Quotedata} />
                {user_type == "PHARMACIST" && <PharmacyTable 
                Quotedata={Quotedata} 
                SaveNewQuoteData={SaveNewQuoteData}
                user_type={user_type}
                />}

                {user_type == "LABORATORY" && <LaboratoryTable 
                Quotedata={Quotedata} 
                SaveNewQuoteData={SaveNewQuoteData}
                user_type={user_type}
                />}

            <SendForm 
            total = {total}
            user_type ={user_type} 
            previousStep = {previousStep}
            Quotedata = {Quotedata}
             onClickAddPharmacyQuote = {onClickAddPharmacyQuote} 
             onClickAddLaboratoryQuote = {onClickAddLaboratoryQuote} />
        </div>
    )
}