import http from "../../../../Utils/http";

export function loadTotalTestData() {
  const pharams = "paginate=0"
  return http.get(`api/guest/labtest/?${pharams}`);
}

  export function loadSelectedTestData() {
    return http.get(`api/laboratory/test`);
  }

  export function AddTestData(data) {
    return http.post(`api/laboratory/test`, data);
  }