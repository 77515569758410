import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { getCode } from '../helpers/api/googleApi'
import { HomeDelivery } from '../helpers/general/Form/SepcificSelect';
import { toastSuccess } from '../helpers/util/toast';
import autoCatch from '../helpers/util/autoCatch';
import ButtonWithSpinner from '../helpers/general/Form/ButtonWithSpinner';
import Input from './../helpers/general/Form/Input';
import pharmacyRegister from '../helpers/api/pharmacy/pharmacyRegister';
import GoogleMap from "./../helpers/general/GoogleMaps"; 
import CurrencyCode from '../helpers/general/Form/CurrencyCode'
import { connect } from "react-redux";
import { setPharmacyAddressData } from '../../../../../../redux';
import { useDispatch } from 'react-redux';


const initialValues = {
    pincode: '',
    street_name: '',
    city_village: '',
    district: '',
    state: '',
    country: '',
    latitude: 9.6984195,
    longitude: 76.768277,
    position: null,
    home_delivery: 'NO',
    amount: 0,
    currency_code: 'INR'
  }

const mapStateToProps = state => {
    const { pharmacyId } = state;
    return { pharmacyId };
  };
const PharmacyAddress = (props) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);
    // const pincode = Yup.number().min(100000, "Must be 6 digit").max(999999, "Must be 6 digit")

    const validationSchema = Yup.object({
      // pincode: Yup.number()
      //     .min(100000, "Must be 6 digit")
      //     .max(999999, "Must be 6 digit")
      //     .required('Pincode field is required'),
        pincode: Yup.string()
        .matches(/^[0-9]*$/, 'Invalid Pincode')
        .required("Pincode is Required!")
        // .test('len', 'Invalid Pincode', val => val.length === 6)
        .nullable(),
      street_name: Yup.string()
          .required('Area/Street/Sector field is required'),
      city_village: Yup.string()
          .required('City/village/Society field is required'),
      district: Yup.string()
          .required('District field is required'),
      state: Yup.string()
          .required('State field is required'),
      country: Yup.string()
          .required('Country field is required'),
      home_delivery: Yup.string()
        .required('Home delivery field field is required'),
      amount: Yup.number().when('home_delivery', {
          is: 'YES',
          then: Yup.number('enter a valid amount')
          .required('Amount is required')
        }),

  })
  
    return (
        <>
         <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
            setSubmitting(true);
            let home_delivery = values.home_delivery === 'YES' ? 1 : 0;
            let inputData = {
              ...values,
              data_id: props.pharmacyId.id,
              latitude: values.position.lat,
              longitude: values.position.lng,
              order_amount: values.amount,
              home_delivery: home_delivery,
            }
            delete inputData.amount;
            delete inputData.position;
            
            autoCatch(pharmacyRegister.pharmacyAddress(inputData).then(res => {
              const step2Id = res.data.data_id
              dispatch(setPharmacyAddressData(step2Id))
            if (res.status === 200) {
                toastSuccess('Registration Successful')
                props.onSubmit(3)
            }
            setLoading(true);
            setSubmitting(false);
            }), data => {
            if (data && data.errors) {
                setErrors(data.errors)
            }
            setSubmitting(false);
            })

        }}
        >{({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        dirty,
        isValid,
        }) => (
            <form onSubmit={handleSubmit}>
            <h4 className="card-title my-4">Pharmacy Address</h4>
            <div className="row form-row">
                <div className="col-lg-4 col-md-12">
                <div className="form-group">
                <label>Pincode</label>
                    <Input
                      value={values.pincode}
                      onChange={handleChange}
                      onBlur={e => {
                        const val = e.target.value
                        // setFieldTouched('pincode', true);
                        if (!val) return;
                        getCode(val, addr => {
                          if (!addr) {
                            return
                          }
      if (addr.country){
        setFieldValue("country", addr.country)
      } else {
        setFieldValue("country", '')
      }
      if (addr.state){
        setFieldValue("state", addr.state)
      } else {
        setFieldValue("state", '')
      }
      if (addr.district){
        setFieldValue("district", addr.district)
      } else {
        setFieldValue("district", '')
      }
      // if (addr.position.lng){
      //   setFieldValue("longitude", addr.position.lng)
      // }
      // if (addr.position.lat){
      //   setFieldValue("latitude", addr.position.lat)
      // }
                        })

                      }}
                      name="pincode"
                      touched={touched.pincode}
                      errors={errors.pincode}
                      required
                      label="Pincode" />
                </div>
                </div>
                <div className="col-lg-4 col-md-12">
                <div className="form-group">
                <label>Country</label>
                    <Input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.country}
                      required
                      hasError={touched.country && errors.country}
                      feedback={errors.country}
                      type="text"
                      name="country"
                      label="Country" />
                </div>
                </div>
                <div className="col-lg-4 col-md-12">
                <div className="form-group">
                <label>State</label>
                    <Input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.state}
                      hasError={touched.state && errors.state}
                      feedback={errors.state}
                      required
                      type="text"
                      name="state"
                      label="State" />
                </div>
                </div>
            </div>

            
            <div className="row form-row">
                <div className="col-lg-4 col-md-12">
                <div className="form-group">
                <label>District</label>
                    <Input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      value={values.district}
                      hasError={touched.district && errors.district}
                      feedback={errors.district}
                      type="text"
                      name="district"
                      label="District" />
                </div>
                </div>
                <div className="col-lg-4 col-md-12">
                <div className="form-group">
                <label>City/Village/Society </label>
                    <Input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city_village}
                      hasError={touched.city_village && errors.city_village}
                      feedback={errors.city_village}
                      type="text"
                      required
                      name="city_village"
                      label="City/Village/Society" />   

                </div>
                </div>

                <div className="col-lg-4 col-md-12">
                <div className="form-group">
                    <label>Area/Street/Sector </label>
                    <Input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.street_name}
                      hasError={touched.street_name && errors.street_name}
                      feedback={errors.street_name}
                      type="text"
                      required
                      name="street_name"
                      label="Area/Street/Sector" />
                </div>
                </div>
                <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <label>Latitude</label>
                      <Input
                        label="Latitude"
                        type="number"
                        disabled
                        name="latitude"
                        value={
                          values.position && values.position.lat
                            ? values.position.lat
                            : ""
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label>Longitude</label>
                      <Input
                        label="Longitude"
                        type="number"
                        disabled
                        name="longitude"
                        value={
                          values.position && values.position.lng
                            ? values.position.lng
                            : ""
                        }
                      />
                    </div>
              <div className="form-group">
                <label>Home delivery <span className="text-danger">&nbsp;*</span></label>           
                <HomeDelivery
                  value={values.home_delivery}
                  onChange={handleChange}
                  onBlur={handleBlur} />
                  </div>
                  {values.home_delivery === 'YES' &&(
                  <div className="form-group">
                  <label>Minimum value of home delivery <span className="text-danger">&nbsp;*</span></label>
                  <div className="phone-number">
                  <div>
                  <div>
                  <CurrencyCode
                    defaultValue="INR"
                    name="currency_code"
                    onChange={setFieldValue}
                    value={values.currency_code}
                  />
                  </div>
                  </div>
                    <Input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.amount}
                      type="number"
                      hasError={touched.amount && errors.amount}
                      feedback={errors.amount}
                      name="amount"
                       />
                  </div>
                  </div>
                  )}
                  </div>
                  <div className="col-lg-8 col-md-12" style={{ height: "350px" }}>
                  <GoogleMap
                    name="position"
                    onChange={setFieldValue}
                    position={values.position}
                  />
                  </div>
                </div>
                
      
            <div className="row form-row">
                <div className="col-12">
                <div className="form-group text-right">
                    <ButtonWithSpinner
                    isLoading={isSubmitting}
                    type="submit"
                    disabled={!dirty || !isValid || isSubmitting} >
                    Next
                    </ButtonWithSpinner>
                </div>
                </div>
            </div>

            </form>
        )}
        </Formik>
        </>

    )
}

export default connect(mapStateToProps, {})(PharmacyAddress);