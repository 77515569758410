import React from 'react';
import { Button, Modal } from "react-bootstrap";

 
export default class Example extends React.Component {
    constructor(props, context) {
      super(props, context);
  
      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.onSubmit = this.onSubmit.bind(this);
  
      this.state = {
        show: false,
      };
    }
  
    handleClose() {
      this.setState({ show: false });
    }
  
    handleShow() {
      this.setState({ show: true });
    }

    onSubmit() {
        this.handleClose()
        this.props.EditQuote()
    }
  
    render() {
      return (
        <>
          <Button variant="primary" onClick={this.handleShow}>
          Process
          </Button>
  
          <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Process Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to process ?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={this.onSubmit}>
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }