import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";
import List from "./List"
import { DetailView } from "./DetailView";
import { connect } from 'react-redux';

const Dispense = (props) => {
  const user_type = props && props.me && props.me.user_type
  var title
  if (user_type == "PHARMACIST") {
    title = "Medicine Orders"
  }
  if (user_type == "LABORATORY") {
    title = "Test Orders"
  }

  const suhbeader = useSubheader();
  suhbeader.setTitle(title);

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/dispense"
        to="/dispense/list"
      />
      <ContentRoute from="/dispense/list" component={List} />
      <ContentRoute from="/dispense/view" component={DetailView} />
    </Switch>
  );
};
const mapStateToProps = function (state) {
  return {
    me: state.me,
  }
}

export default connect(mapStateToProps)(Dispense);
