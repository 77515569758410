// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../../_metronic/_partials/controls";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import {AddTestData} from "../../../modules/Apis/Laboratory/Tests/TestsApi";

// Validation schema
const CategoryEditSchema = Yup.object().shape({
    id: Yup.string()
        .nullable()
        .required("Test Name is required"),
});

export function StoreEditForm (props) {
    const {selectdata, lab_tests, btnRef} = props
    var filterdList = [...selectdata]
    var outList = []
    
    // console.log('lab_tests', lab_tests);


    if (lab_tests[0]) {
        lab_tests.map((values) => {
        outList = filterdList.filter((item) => parseInt(item.id) !== parseInt(values.id));
        filterdList = [...outList]
    })}

    // console.log('filterdList', filterdList);


    
    const initValues = {
        id : null,
        sample_collect : false
    }

    const editDetails = (data) => {
        const reqData = {
            lab_tests : data
        }
        AddTestData(reqData)
          .then(items => {
              props.updateData()
            if (items && items.data && items.data.message) {
              SuccessToast(items.data.message)
            }
            
          })
          .catch(err => {
            ErrorToast(err)
        })
      }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initValues}
                validationSchema={CategoryEditSchema}
                onSubmit={(values) => {
                    const data = values
                    data['id'] = parseInt(values.id)
                    if (values.sample_collect == true) {
                        data['sample_collect'] = 1
                    }
                    if (values.sample_collect == false) {
                        data['sample_collect'] = 0
                    }
                    const reqList = lab_tests
                    reqList.push(data)
                    editDetails(reqList)
                    
                }}
            >
                {({ handleSubmit, setFieldValue,  resetForm, handleChange, handleBlur, errors, touched, values }) => (
                    <>
                        <Form className="form form-label-right">
                            <div className="form-group row">
                                <div className="col-lg-8">
                                    <Select
                                    value={values.bank_account_type}
                                    onChange={(e => {
                                        setFieldValue('id', e.target.value)
                                    })}
                                    onBlur={handleBlur}
                                    label="Test Name"
                                    name="id"
                                    >
                                    <option value={''}>None</option>
                                        {filterdList.map((item) => 
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                        )}
                                    </Select>
                                        {errors.id && touched.id && (
                                        <p style={{color:"red"}}>{errors.id}</p>
                                        )}
                                </div>
                            </div>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={values.sample_collect}
                                    onChange={handleChange}
                                    name="sample_collect"
                                    color="primary"
                                />
                            }
                            label="Sample collect from home"
                          />
                            <button
                                type="submit"
                                style={{ display: "none" }}
                                ref={btnRef}
                                onSubmit={() => handleSubmit()}
                            ></button>
                            {/* <button
                                type="button"
                                style={{ display: "none" }}
                                ref={resetbtnRef}
                                onClick={() => resetForm()}
                            ></button> */}
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
