import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSubheader } from "../../../../_metronic/layout";
import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";
import ProfilePage from "./Profile";

export const PharmacyProfilePage = (props) => {

//   const suhbeader = useSubheader();
//   suhbeader.setTitle("Pharmacy Info");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/pharmacy/profile"
        to="/pharmacy/profile/view"
      />
      <ContentRoute from="/pharmacy/profile/view" component={ProfilePage} />
    </Switch>
  );
};
