import React, { Component, PureComponent, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import Marker from "./Marker";

export default class GoogleMap extends PureComponent {
 
  setPosition = (position) => {
    this.props.onChange(this.props.name || "position", position);
  };

  /* const onChange = (place) => {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    setPosition({ lat, lng });
  }; */
  render() {
    const { position } = this.props;
    const defaultPosition = {
      lat: 8.4998965,
      lng: 76.8541262,
    };
    return (
      <div style={{ ...this.props.styles }}>
        {/* <Autocomplete
        className="form-control"
        style={{
          borderRadius: 0,
          margin: "5px auto",
          width: "96%",
          padding: "10px",
        }}
        onPlaceSelected={onChange}
        types={["(regions)"]}
      />*/}
        <Marker
          position={{ ...defaultPosition, ...position }}
          styles={{ ...this.props.styles }}
          onChange={this.setPosition}
        />
      </div>
    );
  }
}
