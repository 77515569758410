
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import getSymbolFromCurrency from 'currency-symbol-map';
import PriceField from "./page-components/PriceField";
import UnitField from "./page-components/UnitField";
import { GetCurrencyCode } from "../../../Utils/GetData";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));



export default function SimpleTable(props) {
  const {Quotedata, SaveNewQuoteData, user_type} = props
  const rows = Quotedata.test_list
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="left">Test Name</TableCell>
            <TableCell align="left">Instructions</TableCell>
            <TableCell align="center">Price ({getSymbolFromCurrency(GetCurrencyCode())})</TableCell>
            {/* <TableCell align="center">Quantity</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="left">{row.test_name}</TableCell>
              <TableCell align="left">{row.instructions ? row.instructions : "NA"}</TableCell>
              <TableCell align="center">
                <PriceField row={row} rows={rows} Quotedata={Quotedata} index={index} SaveNewQuoteData={SaveNewQuoteData} user_type={user_type} />
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
