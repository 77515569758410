import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { EditPharmacyForm } from "./EditPharmacyForm";
import { EditLaboratoryForm } from "./EditLaboratoryForm";
class Example extends React.Component {
    constructor(props, context) {
      super(props, context);
  
      this.state = {
        show: false,
      };
  
      this.handleShow = () => {
        this.setState({ show: true });
      };
  
      this.handleHide = () => {
        this.setState({ show: false });
      };
    }

    
  
    render() {
      return (
        <>
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={this.handleShow}
        >
            <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                />
            </span>
        </a>
  
          <Modal
            show={this.state.show}
            onHide={this.handleHide}
          >
            <Modal.Header closeButton>
            <Modal.Title >
                Edit Medicine
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.props.user_type == "PHARMACIST" && <EditPharmacyForm
                    searchlist={this.props.searchlist}
                    Quotedata={this.props.Quotedata}
                    index={this.props.index}
                    onEdit={this.props.onEdit} 
                    handleHide={this.handleHide} 
                    row={this.props.row}
                    SaveNewQuoteData={this.props.SaveNewQuoteData} />}
              {this.props.user_type == "LABORATORY" && <EditLaboratoryForm
                    searchlist={this.props.searchlist}
                    Quotedata={this.props.Quotedata}
                    index={this.props.index}
                    onEdit={this.props.onEdit} 
                    handleHide={this.handleHide} 
                    row={this.props.row}
                    SaveNewQuoteData={this.props.SaveNewQuoteData} />}
            </Modal.Body>
            
          </Modal>
        </>
      );
    }
  }
  
 export default Example;
  