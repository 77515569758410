
import { PhoneNumberUtil } from 'google-libphonenumber'
import { countryCodeData } from './countyCodeMapping';

function getAge(dateString) {
    if(!dateString){
        return 0
    }
    const today = new Date();
    const birthDate = new Date(dateString);
    if (today < birthDate) {
        return 0
    }
    const m = today.getMonth() - birthDate.getMonth();
    let age = today.getFullYear() - birthDate.getFullYear();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

const passwordRegex = new RegExp(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&.])/)
const pharmacyRegex = new RegExp(/^(?=.*[a-zA-Z\d].*)[a-zA-Z\d!@#$%&*]{7,}$/)
const nameRegex = new RegExp(/^[A-Za-z ]+$/)
const mobileNumberRegex = new RegExp(/^[+0-9 ()-]+$/)
const gstinRegex = new RegExp(/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/)
const otp6 = new RegExp(/^[0-9]+$/)
const regId = new RegExp(/^[0-9-!@#$%&*?/|]*$/)
const ifsc_code = new RegExp(/^[A-Za-z]{4}[a-zA-Z0-9]{7}$/)

// Parse number with country code and keep raw input.
export const handleNumber = (input, countryCodeInt) => {

    // Get an instance of `PhoneNumberUtil`.
    const phoneUtil = PhoneNumberUtil.getInstance();
    if (!input || !countryCodeInt) return false;

    try {
        const number = phoneUtil.parseAndKeepRawInput(input, countryCodeInt);
        const isValid = phoneUtil.isValidNumber(number);
        return isValid;
    } catch (e) {
        console.log(e)
    }
    return false;
}

// Parse number with country code and keep raw input.
export const handleNumberWithCode = (input, code) => {

    // Get an instance of `PhoneNumberUtil`.
    if (!input) return false;
    let countryCode = "IN"
    const countryCodeobj = countryCodeData.find(item => item.value === code)
    if(countryCodeobj && !countryCodeobj.code){
        countryCode = countryCodeobj.code || "IN"
    }
     
    const phoneUtil = PhoneNumberUtil.getInstance();
    input = input + ""
    try {
        const number = phoneUtil.parseAndKeepRawInput(input, countryCode);
        const isValid = phoneUtil.isValidNumber(number);
        return isValid;
    } catch (e) {
        console.log(e)
    }
    return false;
}

export {
    getAge,
    gstinRegex,
    passwordRegex,
    nameRegex,
    mobileNumberRegex,
    otp6,
    regId,
    pharmacyRegex,
    ifsc_code

}