import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from 'react-bootstrap/Button';
import Grid from '@material-ui/core/Grid';

export function EditPharmacyForm(props) {
  const { searchlist, Quotedata, index, SaveNewQuoteData } = props
  // const [value, setValue] = React.useState(Quotedata.medicine_list[index].medicine_name + ", " + Quotedata.medicine_list[index].medicine.composition);
  const [value, setValue] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState(<p></p>);
  // console.log(Quotedata.medicine_list[index]);

  var tempOptions = searchlist
  Quotedata.medicine_list.map((item) => {
    tempOptions = tempOptions.filter((it) => it.id != item.medicine_id )
  })
  const options = (tempOptions.map((item) => item.name + ", " + item.composition))
  // options.push(value)

const onClickSave = () => {
  if (value) {
  const medicineDetail = (searchlist.filter((item) => item.name + ", " + item.composition == value))[0]
  var newMedicineList = Quotedata.medicine_list
  newMedicineList[index] = {
    dosage: Quotedata.medicine_list[index].dosage,
    duration: Quotedata.medicine_list[index].duration,
    instructions: Quotedata.medicine_list[index].instructions,
    medicine_status: Quotedata.medicine_list[index].medicine_status,
    no_of_refill: Quotedata.medicine_list[index].no_of_refill,
    prescription_id: Quotedata.medicine_list[index].prescription_id,
    substitution_allowed: Quotedata.medicine_list[index].substitution_allowed,
    medicine_name: medicineDetail.name,
    medicine_id: medicineDetail.id,
    composition: medicineDetail.composition,
    manufacturer: medicineDetail.manufacturer,
    medicine:medicineDetail,
  }
  const data = Quotedata
  data['medicine_list'] = newMedicineList
  SaveNewQuoteData(data)
  props.handleHide()
  } else {
    setErrorMessage(<p style={{color:'red'}}>Medicine is required !</p>)
  }
}

  return (
    <div>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          setErrorMessage(<p></p>)
        }}
        id="controllable-states-demo"
        options={options}
        renderInput={(params) => <TextField {...params} label="Search Medicine" variant="outlined" />}
      />
      {errorMessage}
      
      <Grid container spacing={3} style={{marginTop:'2%'}}>
                                <Grid item xs={6}>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button style={{margin:'3px'}} variant="secondary" onClick={props.handleHide}>
                                        Close
                                    </Button>
                                    <Button style={{margin:'3px'}} onClick={onClickSave} variant="primary" >
                                        Save Changes
                                    </Button>
                                </Grid>
                            </Grid>
    </div>
    
  );
}
