import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { countryCodeData as options } from '../../../Utils/countyCodeMapping';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import VisibilityIcon from '@material-ui/icons/Visibility';
// import Api from "../../../../crud/auth/services/apiAxios";
// import {removeToken, getToken} from "../../../../crud/getToken";
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import {getCode} from '../../../Utils/googleApi';
import {EditData} from "../../../modules/Apis/Laboratory/Profile/Profile";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import FileUpload from '../../../Utils/Components/FileUpload';
import { ImageLoad } from "../../../Utils/Components/ImageLoad";
import {currencyCodeData} from '../../../Utils/currencyCodeMapping';
import getSymbolFromCurrency from 'currency-symbol-map'
import { DatePickerField } from "./../../../../_metronic/_partials/controls/forms/DatePickerField";



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const gstinRegex = new RegExp(/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/)

export default function Details(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const data = props.data
  let intValues = {
    laboratory_name:data.laboratory_name,
    country_code:data.user.country_code,
    mobile_number:data.user.mobile_number,
    email:data.user.email,
    alt_mobile_number:data.alt_mobile_number,	
    alt_country_code:data.alt_country_code,
    gstin:data.gstin	,
    lab_reg_number:data.lab_reg_number	,
    lab_issuing_authority:data.lab_issuing_authority	,
    lab_date_of_issue:data.lab_date_of_issue	,
    lab_valid_upto:data.lab_valid_upto,
    lab_file:data.lab_file,
    upload_dl:'',
    sample_collect:data.sample_collect,
    lab_tests:data.lab_tests,
    payout_period: data.payout_period,

    pincode:data.address[0].pincode,
    street_name:data.address[0].street_name,
    city_village:data.address[0].city_village,
    district:data.address[0].district,
    state:data.address[0].state,
    country:data.address[0].country,
    sample_collection:String(data.sample_collection),
    order_amount:data.order_amount,
    latitude:data.address[0].latitude,
    longitude:data.address[0].longitude,
    currency_code:data.user.currency_code,

    bank_account_number:data.bank_account_details[0].bank_account_number,
    bank_account_holder:data.bank_account_details[0].bank_account_holder,
    bank_name:data.bank_account_details[0].bank_name	,
    bank_city:data.bank_account_details[0].bank_city,
    bank_ifsc:data.bank_account_details[0].bank_ifsc,
    bank_account_type:data.bank_account_details[0].bank_account_type,
  }

  if (intValues.alt_country_code == null) {
    intValues.alt_country_code = '+91'
  }
  if (intValues.country_code == null) {
    intValues.country_code = '+91'
  }

  if (intValues.alt_mobile_number == 'null') {
    intValues.alt_mobile_number = ''
  }

  if (intValues.currency_code == null) {
    intValues['currency_code'] = 'INR'
  }


  

  

  

    
      const ifsc_code = new RegExp(/^[A-Za-z]{4}[a-zA-Z0-9]{7}$/)

    const formik = useFormik({
      initialValues: intValues,
      validationSchema: Yup.object({
        laboratory_name: Yup.string()
          .required("Laboratory name is Required!"),
        email: Yup.string()
          .email("Invalid email format")
          .required("Email is Required!"),
        gstin: Yup.string()
          .matches(gstinRegex, "Must be a standard gst number.")
          .max(15, 'Must be 15 characters.')
          .min(15, 'Must be 15 characters.')
          .required('GSTIN number field is required '),
          lab_reg_number: Yup.string()
          .required("Lab registration number is Required!"),
        lab_issuing_authority: Yup.string()
         .required("Lab issuing authority is Required!"),
         lab_date_of_issue: Yup.date('Must be a valid date')
         .nullable()
         .max(new Date(), 'Date of issue cannot be a future date' )
         .required('The Date of issue field is required.'),
        lab_valid_upto: Yup.date('Must be a valid date')
        .nullable()
        .min(new Date(), "Date must be in the future")
        .required('The Date of validity field is required.'),
        alt_mobile_number: Yup.string()
        // .test('len', 'Invalid phone number''Invalid phone number', val => val.length === 10)
        .max(10, 'Invalid phone number')
        .min(10, 'Invalid phone number')
        .nullable()
        ,
          mobile_number: Yup.string()
          .required('Mobile number is required')
          .max(10, 'Invalid phone number')
          .min(10, 'Invalid phone number')
          .nullable()
          ,
          // bank_account_number: Yup.string()
          //   .required("Account number is Required!"),
          // bank_account_holder: Yup.string()
          //   .required("Account holder name is Required!"),
          // bank_name: Yup.string()
          //   .required("Bank name is Required!"),
          // bank_city: Yup.string()
          //  .required("Bank city is Required!"),
          //  bank_account_type: Yup.string()
          //  .required("Bank account type is Required!"),
          // bank_ifsc: Yup.string()
          // .matches(ifsc_code, 'Must be a valid IFSC code')
          // .required('The IFSC field is required.'),
          pincode: Yup.string()
          .matches(/^[0-9]*$/, 'Invalid Pincode')
          .required("Pincode is Required!")
          .test('len', 'Invalid Pincode', val => val.length === 6)
          .nullable(),
          country: Yup.string()
           .required("Country is Required!"),
          state: Yup.string()
            .required("State is Required!"),
          district: Yup.string()
            .required("District is Required!"),
          city_village: Yup.string()
            .required("City/Village/Society is Required!"),
          street_name: Yup.string()
           .required("Area/Street/Sector is Required!"),
      }),
      onSubmit: values => {
        props.setLoading(true)
        setLoading(true)
        let formData = new FormData();
        formData.append('laboratory_name', values.laboratory_name)
        formData.append('country_code', values.country_code)
        formData.append('mobile_number', String(values.mobile_number))
        formData.append('email', values.email)
        formData.append('alt_mobile_number', values.alt_mobile_number)
        formData.append('alt_country_code', values.alt_country_code)
        formData.append('gstin', values.gstin)
        formData.append('lab_reg_number', values.lab_reg_number)
        formData.append('lab_issuing_authority', values.lab_issuing_authority)
        formData.append('lab_date_of_issue', values.lab_date_of_issue)
        formData.append('lab_valid_upto', values.lab_valid_upto)
        formData.append('lab_file', values.upload_dl)
        // formData.append('row', JSON.stringify([...values.lab_tests]))
        // formData.append('row', values.lab_tests)
        // values.lab_tests.forEach((item, index) => {
        //   formData.append('row['+index+']', JSON.stringify(item) );
        //  });
        // var arr = values.lab_tests;
        // for (var i = 0; i < arr.length; i++) {
        //   formData.append('row', arr[i]);
        // }
        formData.append('pincode', values.pincode)
        formData.append('street_name', values.street_name)
        formData.append('city_village', values.city_village)
        formData.append('district', values.district)
        formData.append('state', values.state)
        formData.append('country', values.country)
        formData.append('sample_collection', values.sample_collection)
        formData.append('order_amount', values.order_amount)
        formData.append('latitude', values.latitude)
        formData.append('longitude', values.longitude)
        formData.append('bank_account_number', values.bank_account_number)
        formData.append('bank_account_holder', values.bank_account_holder)
        formData.append('bank_name', values.bank_name)
        formData.append('bank_city', values.bank_city)
        formData.append('bank_ifsc', values.bank_ifsc)
        formData.append('bank_account_type', values.bank_account_type)
        formData.append('currency_code', values.currency_code)
        formData.append('payout_period', values.payout_period)

        EditData(formData)
        .then(items => {
          SuccessToast(items.data.message)
          props.loadSaveData()
          setLoading(false)
        })
        .catch(err => {
          ErrorToast(err)
          setLoading(false)
      })
      }
    });

    const onOpenPdf = () => {
      window.open(formik.values.lab_file)
    }

    const changePincode = (event) => {
      const pincodeVal = event.target.value
      formik.setFieldValue("pincode", pincodeVal)
      getCode(pincodeVal, addr => {
        if (!addr) {
          return
        }
        if (addr.country){
          formik.setFieldValue("country", addr.country)
        } else {
          formik.setFieldValue("country", '')
        }
        if (addr.state){
          formik.setFieldValue("state", addr.state)
        } else {
          formik.setFieldValue("state", '')
        }
        if (addr.district){
          formik.setFieldValue("district", addr.district)
        } else {
          formik.setFieldValue("district", '')
        }
        if (addr.longitude){
          formik.setFieldValue("longitude", addr.longitude)
        }
        if (addr.latitude){
          formik.setFieldValue("latitude", addr.latitude)
        }
  
      })
    }

    let valueField = <p></p>
    if (formik.values.sample_collection === "1") {
        valueField = <div>
            <Select
            required
            style={{marginRight:"2%", marginBottom:"2%", width:"30%"}}
            name="currency_code"
            type="text"
            value={formik.values.currency_code}
            variant="outlined"
            onChange={formik.handleChange}
            >
              {
                currencyCodeData.map(
                  (item) => (
                  <MenuItem id={item.id} value={item.value}>{item.value}</MenuItem>
                  )
                )
              }
            </Select>
            <FormControl style={{width:'68%'}} variant="outlined"
            required>
            <InputLabel htmlFor="outlined-adornment-amount">minimum value of sample collection from home</InputLabel>
            <OutlinedInput
            fullWidth
            required
                name="order_amount"
                type="number"
                value={formik.values.order_amount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                startAdornment={<InputAdornment position="start">{getSymbolFromCurrency(formik.values.currency_code)}</InputAdornment>}
                label='Minimum value of sample collection from home'
            />
            </FormControl>
        
            </div>
    } else if (formik.values.sample_collection === "0") {
        valueField = <p></p>
        formik.values.order_amount = 0.00
    }
        
    

      
    

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Laboratory Details</h6>
      <Grid container spacing={3} style={{marginBottom:"2%", marginTop:"2%"}}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            required
            label="Laboratory Name"
            name="laboratory_name"
            type="text"
            value={formik.values.laboratory_name}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.laboratory_name && formik.touched.laboratory_name && (
            <p style={{color:"red"}}>{formik.errors.laboratory_name}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <Select
          disabled
            required
            style={{marginRight:"2%",  width:"33%"}}
            name="country_code"
            type="text"
            value={formik.values.country_code}
            variant="outlined"
            onChange={formik.handleChange}
            >
              {
                options.map(
                  (item) => (
                  <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                  )
                )
              }
            </Select>
            <FormControl style={{width:"65%"}} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Mobile Number</InputLabel>
            <OutlinedInput
          fullWidth
          disabled
                name="mobile_number"
                type="number"
                value={formik.values.mobile_number}
                onChange={formik.handleChange}
                startAdornment={<InputAdornment position="start">{formik.values.country_code}</InputAdornment>}
                labelWidth={50}
                onBlur={formik.handleBlur}
                label='Mobile Number'
            />
            {formik.errors.mobile_number && formik.touched.mobile_number && (
            <p style={{color:"red"}}>{formik.errors.mobile_number}</p>
             )}
            </FormControl>
        </Grid>
        <Grid item xs={4}>
        <TextField
          disabled
          fullWidth
          required
            label="Email ID"
            name="email"
            type="email"
            value={formik.values.email}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.email && formik.touched.email && (
            <p style={{color:"red"}}>{formik.errors.email}</p>
             )}
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={4}>
        <TextField
          fullWidth
          required
            // style={{margin:"2%", width:"200px"}}
            label="GSTN Number"
            name="gstin"
            type="text"
            value={formik.values.gstin}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.gstin && formik.touched.gstin && (
            <p style={{color:"red"}}>{formik.errors.gstin}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
          fullWidth
          required
            label="Registration Number"
            name="lab_reg_number"
            type="text"
            value={formik.values.lab_reg_number}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.lab_reg_number && formik.touched.lab_reg_number && (
            <p style={{color:"red"}}>{formik.errors.lab_reg_number}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
          fullWidth
          required
            label="Issuing Authority"
            name="lab_issuing_authority"
            type="text"
            value={formik.values.lab_issuing_authority}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.lab_issuing_authority && formik.touched.lab_issuing_authority && (
            <p style={{color:"red"}}>{formik.errors.lab_issuing_authority}</p>
             )}
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{marginBottom:"2%", marginTop:"2%"}}>
        <Grid item xs={4}>
        {/* <TextField
          fullWidth
          required
            label="Date Of Registration"
            name="lab_date_of_issue"
            type="date"
            value={formik.values.lab_date_of_issue}
            variant="outlined"
            onChange={e => {
              if (new Date("9999-12-31") >= new Date(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            InputLabelProps={{
                shrink: true,
              }}
              onBlur={formik.handleBlur}
              />
              {formik.errors.lab_date_of_issue && formik.touched.lab_date_of_issue && (
              <p style={{color:"red"}}>{formik.errors.lab_date_of_issue}</p>
               )} */}

                   <DatePickerField 
                                mandatory
                                name="lab_date_of_issue"
                                label="Date Of Registration"
                                onChange={val => {
                                  formik.setFieldValue('lab_date_of_issue', String(val))
                                }}
                                onBlur={e => {
                                  formik.handleBlur(e);
                                }}
                                value={formik.values.lab_date_of_issue}
                                touched={formik.touched.lab_date_of_issue}
                                errors={formik.errors.lab_date_of_issue}
                                 />
        </Grid>
        <Grid item xs={4}>
        {/* <TextField
          fullWidth
          required
            label="Registration valid upto"
            name="lab_valid_upto"
            type="date"
            value={formik.values.lab_valid_upto}
            variant="outlined"
            onChange={e => {
              if (new Date("9999-12-31") >= new Date(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            InputLabelProps={{
                shrink: true,
              }}
              onBlur={formik.handleBlur}
              />
              {formik.errors.lab_valid_upto && formik.touched.lab_valid_upto && (
              <p style={{color:"red"}}>{formik.errors.lab_valid_upto}</p>
               )} */}

                   <DatePickerField 
                                mandatory
                                name="lab_valid_upto"
                                label="Registration valid upto"
                                onChange={val => {
                                  formik.setFieldValue('lab_valid_upto', String(val))
                                }}
                                onBlur={e => {
                                  formik.handleBlur(e);
                                }}
                                value={formik.values.lab_valid_upto}
                                touched={formik.touched.lab_valid_upto}
                                errors={formik.errors.lab_valid_upto}
                                 />
        </Grid>
        <Grid item xs={4}>
        <Select
        required
            style={{marginRight:"2%", width:"33%"}}
            name="alt_country_code"
            type="text"
            value={formik.values.alt_country_code}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            >
              {
                options.map(
                  (item) => (
                  <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                  )
                )
              }
            </Select>
            <FormControl style={{width:"65%"}} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Alternative Number</InputLabel>
            <OutlinedInput
                name="alt_mobile_number"
                type="number"
                value={formik.values.alt_mobile_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                startAdornment={<InputAdornment position="start">{formik.values.alt_country_code}</InputAdornment>}
                labelWidth={130}
            />
            </FormControl>
            {formik.errors.alt_mobile_number && formik.touched.alt_mobile_number && (
            <p style={{color:"red"}}>{formik.errors.alt_mobile_number}</p>
             )}
        </Grid>
      </Grid>


      <Grid container spacing={3} style={{marginBottom:"2%", marginTop:"2%"}}>
        <Grid item xs={1}>
            {formik.values.lab_file && <IconButton
              color='primary'
              onClick={onOpenPdf}>
              <VisibilityIcon />
            </IconButton>}
            
            {/* <ImageLoad image={formik.values.lab_file} style={{width:'50px', height:'50px', margin:'3%'}} /> */}
                            
        </Grid>
        <Grid item xs={3}>
        <label >Upload Registration Certificate</label>
          <FileUpload 
              name="upload_dl"
              onChange={value => {
                  formik.setFieldValue("upload_dl", value)
              }}
          onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={4}>
        </Grid>
        <Grid item xs={4}>
        </Grid>
      </Grid>


      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Laboratory Address</h6>
      <Grid container spacing={3} style={{marginBottom:"2%", marginTop:"2%"}}>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            label="Pincode"
            name="pincode"
            type="number"
            value={formik.values.pincode}
            variant="outlined"
            onChange={changePincode}
            // onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.pincode && formik.touched.pincode && (
            <p style={{color:"red"}}>{formik.errors.pincode}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            label="Country"
            name="country"
            type="text"
            value={formik.values.country}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.country && formik.touched.country && (
            <p style={{color:"red"}}>{formik.errors.country}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            label="State"
            name="state"
            type="text"
            value={formik.values.state}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.state && formik.touched.state && (
            <p style={{color:"red"}}>{formik.errors.state}</p>
             )}
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{marginBottom:"2%", marginTop:"2%"}}>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            label="District"
            name="district"
            type="text"
            value={formik.values.district}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.district && formik.touched.district && (
            <p style={{color:"red"}}>{formik.errors.district}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            label="City/Village/Society"
            name="city_village"
            type="text"
            value={formik.values.city_village}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.city_village && formik.touched.city_village && (
            <p style={{color:"red"}}>{formik.errors.city_village}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            label="Area/Street/Sector"
            name="street_name"
            type="text"
            value={formik.values.street_name}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
              {formik.errors.street_name && formik.touched.street_name && (
              <p style={{color:"red"}}>{formik.errors.street_name}</p>
               )}
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{marginBottom:"2%", marginTop:"2%"}}>
        <Grid item xs={4}>
        <FormControl component="fieldset" 
            fullWidth>
            <FormLabel component="legend">Sample Collection From Home</FormLabel>
            <RadioGroup row aria-label="sample_collection" name="sample_collection" value={formik.values.sample_collection} onChange={formik.handleChange}>
                <FormControlLabel value= "1" control={<Radio color="primary"  />} label="Yes" />
                <FormControlLabel value= "0" control={<Radio color="primary"  />} label="No" />
            </RadioGroup>
            </FormControl>
        </Grid>
        <Grid item xs={4}>
        {valueField}
        </Grid>
        <Grid item xs={4}>
        </Grid>
      </Grid>

      
      <Grid container spacing={3} style={{marginBottom:"2%", marginTop:"2%"}}><Grid item xs={4}>
        <FormControl fullWidth variant="outlined" className={classes.formControl}>
          <InputLabel fullWidth htmlFor="outlined-age-native-simple">Payout Period</InputLabel>
          <Select
          fullWidth
            value={formik.values.payout_period}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label="payout_period"
            name="payout_period"
          >
            <option value={0}>Monthly</option>
            <option value={1}>Weekly</option>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
      
      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Banking Details</h6>
      <Grid container spacing={3} style={{marginBottom:"2%", marginTop:"2%"}}>
        <Grid item xs={4}>
        <TextField
          fullWidth
          
            label="Bank Account Number"
            name="bank_account_number"
            type="text"
            value={formik.values.bank_account_number}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_account_number && formik.touched.bank_account_number && (
            <p style={{color:"red"}}>{formik.errors.bank_account_number}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
          fullWidth
          
            label="Account Holder Full Name"
            name="bank_account_holder"
            type="text"
            value={formik.values.bank_account_holder}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_account_holder && formik.touched.bank_account_holder && (
            <p style={{color:"red"}}>{formik.errors.bank_account_holder}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
          fullWidth
          
            label="Bank Name"
            name="bank_name"
            type="text"
            value={formik.values.bank_name}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_name && formik.touched.bank_name && (
            <p style={{color:"red"}}>{formik.errors.bank_name}</p>
             )}
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{marginBottom:"2%", marginTop:"2%"}}>
        <Grid item xs={4}>
        <TextField
          fullWidth
          
            label="Bank City"
            name="bank_city"
            type="text"
            value={formik.values.bank_city}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_city && formik.touched.bank_city && (
            <p style={{color:"red"}}>{formik.errors.bank_city}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
          fullWidth
          
            label="Bank IFSC Code"
            name="bank_ifsc"
            type="text"
            value={formik.values.bank_ifsc}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            
            {formik.errors.bank_ifsc && formik.touched.bank_ifsc && (
            <p style={{color:"red"}}>{formik.errors.bank_ifsc}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <FormControl fullWidth variant="outlined" className={classes.formControl}>
        <InputLabel fullWidth htmlFor="outlined-age-native-simple">Account Type</InputLabel>
        <Select
        fullWidth
          value={formik.values.bank_account_type}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label="Account Type"
          name="bank_account_type"
        >
          <option value={'CURRENT'}>CURRENT</option>
          <option value={'SAVINGS'}>SAVINGS</option>
          <option value={'SALARY'}>SALARY</option>
          <option value={'FIXED DEPOSIT'}>FIXED DEPOSIT</option>
          <option value={'RECURRING DEPOSIT'}>RECURRING DEPOSIT</option>
          <option value={'NRI'}>NRI</option>
          <option value={'NRO'}>NRO</option>
          <option value={'NRE'}>NRE</option>
          <option value={'FCNR'}>FCNR</option>
        </Select>
      </FormControl>
            {formik.errors.bank_account_type && formik.touched.bank_account_type && (
            <p style={{color:"red"}}>{formik.errors.bank_account_type}</p>
             )}
        </Grid>
      </Grid>
      <button
            type="submit"
            style={{ display: "none" }}
            ref={props.btnRef}
            // disabled={!formik.dirty}
        ></button>
      </form>
            
    </div>
  );
}
