// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Card } from 'react-bootstrap';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Spinner from "../../../Utils/Components/CustomSpinner";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    label: {
        // width: '150px',
  
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '26px',
        letterSpacing: '-0.008em',
  
      },
      content1 : {
          width: '415px',
  
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '26px',
          letterSpacing: '-0.008em',
  
        },
      content2 : {
        color: '#77777A',
        // height: '21px',
        marginTop: '10px',
        marginBottom: '5px',
  
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '21px',
      }
    }));


export function ViewCard(props) {
    const classes = useStyles();
    const { Quotedata } = props
    const [loading, setLoading] = React.useState(false)
    // console.log('Quotedata', Quotedata);

    
    const onOpenPdf = () => {
      window.open(Quotedata.prescription.pdf_url)
    }
    if (loading) {
        return (
        <Spinner />
        )
    }
    return (
        <div>
            <Card border="dark" >
              <Card.Header className={classes.label} >General Details</Card.Header>
                <Card.Body>
                    
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                    <Typography className={classes.label}>
                      Quote Request
                    </Typography>

                    <Typography className={classes.content2}>
                    {Quotedata.unique_id}
                    </Typography>
                    </Grid>
                    <Grid item xs={4}>
                    <Typography className={classes.label}>
                      Reciever Details
                    </Typography>

                      {Quotedata?.prescription?.purchase_type === "Appointment" && <Typography className={classes.content2}>
                      {Quotedata.prescription.appointment && Quotedata.prescription.appointment.current_patient_info && Quotedata.prescription.appointment.current_patient_info.info && Quotedata.prescription.appointment.current_patient_info.info.first_name && Quotedata.prescription.appointment.current_patient_info.info.first_name} {Quotedata.prescription.appointment && Quotedata.prescription.appointment.current_patient_info && Quotedata.prescription.appointment.current_patient_info.info && Quotedata.prescription.appointment.current_patient_info.info.middle_name && Quotedata.prescription.appointment.current_patient_info.info.middle_name} {Quotedata.prescription.appointment && Quotedata.prescription.appointment.current_patient_info && Quotedata.prescription.appointment.current_patient_info.info && Quotedata.prescription.appointment.current_patient_info.info.last_name && Quotedata.prescription.appointment.current_patient_info.info.last_name}
                      </Typography>}
                      {Quotedata?.prescription?.purchase_type === "Ecommerce" && <Typography className={classes.content2}>
                      {Quotedata?.prescription?.patient?.first_name && Quotedata.prescription.patient.first_name} {Quotedata?.prescription?.patient?.middle_name && Quotedata.prescription.patient.middle_name} {Quotedata?.prescription?.patient?.last_name && Quotedata.prescription.patient.last_name}
                      </Typography>}

                      {Quotedata?.prescription?.purchase_type === "Appointment" && <Typography className={classes.content2}>
                      {Quotedata.prescription.appointment && Quotedata.prescription.appointment.current_patient_info && Quotedata.prescription.appointment.current_patient_info.info && Quotedata.prescription.appointment.current_patient_info.info.email && Quotedata.prescription.appointment.current_patient_info.info.email}
                      </Typography>}
                      {Quotedata?.prescription?.purchase_type === "Ecommerce" && <Typography className={classes.content2}>
                      {Quotedata?.prescription?.patient?.email && Quotedata.prescription.patient.email}
                      </Typography>}

                      {Quotedata?.prescription?.purchase_type === "Appointment" && <Typography className={classes.content2}>
                      {Quotedata.prescription.appointment && Quotedata.prescription.appointment.current_patient_info && Quotedata.prescription.appointment.current_patient_info.info && Quotedata.prescription.appointment.current_patient_info.info.country_code && Quotedata.prescription.appointment.current_patient_info.info.country_code} {Quotedata.prescription.appointment && Quotedata.prescription.appointment.current_patient_info && Quotedata.prescription.appointment.current_patient_info.info && Quotedata.prescription.appointment.current_patient_info.info.mobile_number && Quotedata.prescription.appointment.current_patient_info.info.mobile_number}
                      </Typography>}
                      {Quotedata?.prescription?.purchase_type === "Ecommerce" && <Typography className={classes.content2}>
                      {Quotedata?.prescription?.patient?.country_code && Quotedata.prescription.patient.country_code} {Quotedata?.prescription?.patient?.mobile_number && Quotedata.prescription.patient.mobile_number}
                      </Typography>}

                      {Quotedata?.prescription?.appointment?.current_patient_info?.address && Quotedata?.prescription?.purchase_type === "Appointment" &&  <Typography className={classes.content2}>
                      {Quotedata.prescription.appointment && Quotedata.prescription.appointment.current_patient_info && Quotedata.prescription.appointment.current_patient_info.address && Quotedata.prescription.appointment.current_patient_info.address.street_name}, {Quotedata.prescription.appointment && Quotedata.prescription.appointment.current_patient_info && Quotedata.prescription.appointment.current_patient_info.address && Quotedata.prescription.appointment.current_patient_info.address.city_village}, {Quotedata.prescription.appointment && Quotedata.prescription.appointment.current_patient_info && Quotedata.prescription.appointment.current_patient_info.address && Quotedata.prescription.appointment.current_patient_info.address.district}, {Quotedata.prescription.appointment && Quotedata.prescription.appointment.current_patient_info && Quotedata.prescription.appointment.current_patient_info.address && Quotedata.prescription.appointment.current_patient_info.address.state}, {Quotedata.prescription.appointment && Quotedata.prescription.appointment.current_patient_info && Quotedata.prescription.appointment.current_patient_info.address && Quotedata.prescription.appointment.current_patient_info.address.country}, {Quotedata.prescription.appointment && Quotedata.prescription.appointment.current_patient_info && Quotedata.prescription.appointment.current_patient_info.address && Quotedata.prescription.appointment.current_patient_info.address.pincode}
                      </Typography>}
                    </Grid>
                    <Grid item xs={4}>
                    <Typography className={classes.label}>
                      Prescription No:
                    </Typography>

                    <Typography className={classes.content2}>
                    {Quotedata.prescription && Quotedata.prescription.unique_id && Quotedata.prescription.unique_id}
                    </Typography>
                    {Quotedata.prescription && Quotedata.prescription.pdf_url && <button
                    // style={{marginTop:'25px'}}
                        type="submit"
                        className="btn btn-primary"
                        onClick={onOpenPdf}
                      >
                        View Attached Prescription
                        </button>}
                    </Grid>
                </Grid>
                </Card.Body>
            </Card>
            <br />
        </div>
    );
}
