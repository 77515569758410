import React from 'react'

function Select(props) {
    const {
        onChange = () => { },
        onBlur = () => { },
        name,
        options,
        value = '',
        className = 'form-control',
        ...rest
    } = props;
    const val = options.indexOf(value) >= 0 ? value : options[0];
    return <select className={className} value={val} onChange={onChange} onBlur={onBlur} name={name}  {...rest} >
        {options.map((option, index) => {
            let text = option
            let val = option
            if (option.value) {
                text = option.text;
                val = option.value
            }
            return <option key={index} value={val}>{text}</option>

        })}
    </select>
}


export const Relationship = props => {
    const {
        value = 'FATHER',
        name = 'relationship',
        options = [
            'FATHER',
            'MOTHER',
            'DAUGHTER',
            'SON',
            'HUSBAND',
            'WIFE',
            'GRANDFATHER',
            'GRANDMOTHER',
            'OTHERS'
        ],
        ...rest
    } = props
    return <Select options={options} name={name} value={value} {...rest} />
}


export const GenderSelect = props => {
    const {
        options = ['MALE', 'FEMALE', 'OTHERS'],
        value = 'MALE',
        name = 'gender',
        ...rest
    } = props
    return <Select options={options} name={name} value={value} {...rest} />
}

export const HomeDelivery = props => {
    const {
        options = ['YES', 'NO'],
        value = 'YES',
        name = 'home_delivery',
        ...rest
    } = props
    return <Select options={options} name={name} value={value} {...rest} />
}

export const AccountTypes = props => {
    const {
        options = ['CURRENT', 'SAVINGS', 'SALARY', 'FIXED DEPOSIT', 'RECURRING DEPOSIT', 'NRI', 'NRO', 'NRE', 'FCNR'],
        value = 'SAVINGS',
        name = 'account_type',
        ...rest
    } = props
    return <Select options={options} name={name} value={value} {...rest} />
}

export const CourseSelect = props => {
    const {
        options = ['D.Pharm', 'B.Pharm ', 'Pharm.D', 'Others'],
        value = 'D.Pharm',
        name = 'course',
        ...rest
    } = props
    return <Select options={options} name={name} value={value} {...rest} />
}

export const MaritalStatusSelect = props => {
    const {
        options = ['SINGLE', 'MARRIED', 'DIVORCED', 'WIDOWED'],
        value = 'SINGLE',
        name = 'marital_status',
        ...rest
    } = props
    return <Select options={options} name={name} value={value} {...rest} />
}

export const BloodGroupSelect = props => {
    const {
        options = [
            'A+',
            'A-',
            'B+',
            'B-',
            'O+',
            'O-',
            'AB+',
            'AB-',
            'Other'
        ],
        value = 'A+',
        name = 'blood_group',
        ...rest
    } = props
    return <Select options={options} name={name} value={value} {...rest} />
}

export const AddressTypeSelect = props => {
    const {
        options = [
            'HOME',
            'WORK',
            'OTHERS',
        ],
        value = 'HOME',
        name = 'address_type',
        ...rest
    } = props
    return <Select options={options} name={name} value={value} {...rest} />
}

export const NameTitleSelect = props => {
    const {
        options = [
            'Mr.',
            'Ms.',
            'Mrs.',
        ],
        value = 'Mr.',
        name = 'title',
        ...rest
    } = props
    return <Select options={options} name={name} value={value} {...rest} />
}


export const ServiceSelect = props => {
    const {
        value = 'INPATIENT',
        name = 'service',
        options = [
            'INPATIENT',
            'OUTPATIENT',
        ],
        ...rest
    } = props
    return <Select options={options} name={name} value={value} {...rest} />
}

export const CancelTimePeriod = props => {
    let {
        value = '12 hours',
        name = 'cancel_time_period',
        options = [
            '12 hours',
            '24 hours',
            '48 hours',
            '72 hours',
            '96 hours',
            '120 hours'
        ],
        ...rest
    } = props
    
    options =  options.map((item) => typeof item === 'string' ? item.split(' ')[0] : '' )

    return <Select options={options} name={name} value={value} {...rest} />
}
export const RescheduleTimePeriod = props => {
    let {
        value = '12 hours',
        name = 'reschedule_time_period',
        options = [
            '12 hours',
            '24 hours',
            '48 hours',
            '72 hours',
            '96 hours',
            '120 hours'
        ],
        ...rest
    } = props

    options =  options.map((item) => typeof item === 'string' ? item.split(' ')[0] : '' )

    return <Select options={options} name={name} value={value} {...rest} />
}

export const Followup = props => {
    const {
        value = '1',
        name = 'followup',
        options = [
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10'
        ],
        ...rest
    } = props
 
    return <Select options={options} name={name} value={value} {...rest} />
}

export const FollowupAfter = props => {
    let {
        value = '1 weeks',
        name = 'followup_after',
        options = [
            '1 weeks',
            '2 weeks',
            '3 weeks',
            '4 weeks',
            '5 weeks',
            '6 weeks'
        ],
        ...rest
    } = props

    options =  options.map((item) => typeof item === 'string' ? item.split(' ')[0] : '' )

    return <Select options={options} name={name} value={value} {...rest} />
}

export const SpecilizationSelect = props => {
    const {
        name = 'specialization',
        options = [],
        onChange = () => { },
        onBlur = () => { },
        value:values = [],
        className = 'form-control',
        ...rest
    } = props
    const selectOption = options.map(item => {
        return { text: item.name, value: item.id + "" }
    })
    
    let selectedValues = selectOption.filter(item => values.includes(item.value));
    if(!selectedValues){
        selectedValues = []
    }
    selectedValues = selectedValues.map(item => Number(item.value))
    console.log(values,"selectedValues", selectedValues)
    return <select
        multiple
        className={className}
        value={selectedValues}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        {...rest} >
        {selectOption.map((option, index) => {
            let text = option.text
            let val = Number(option.value)
            return <option  key={index} value={val}>{text}</option>

        })}
    </select>
}

export default Select;