import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { ChangePassword } from "../../modules/Apis/Auth/ForgetPasswordApi";
import { ErrorToast } from "../../modules/Toast/ErrorSnakBar";
import { SuccessToast } from "../../modules/Toast/SuccessSnackBar";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { logOut } from "../../modules/Auth/Logout";

const passwordRegex = new RegExp(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&.])/)

export default function App (props) {
  const initialValues = {
    current_password: "",
    password: "",
    password_confirmation: "",
  };
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    current_password: Yup.string()
      // .min(8, 'Must be 8 characters or more.')
      // .max(20, 'Minimum length of 20 characters.')
      // .matches(passwordRegex, "Include at least  one upper case, lower case, numbers and symbols.")
      .required('The current password field is required.'),
    password: Yup.string()
      .min(8, 'Must be 8 characters or more.')
      .max(20, 'Minimum length of 20 characters.')
      .matches(passwordRegex, "Include at least  one upper case, lower case, numbers and symbols.")
      .required('The new password field is required.'),
    password_confirmation: Yup.string()
      .min(8, 'Must be 8 characters or more.')
      .max(20, 'Minimum length of 20 characters.')
      .matches(passwordRegex, "Include at least  one upper case, lower case, numbers and symbols.")
      .required('The confirm new password field is required.')
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      ChangePassword(values)
        .then((items) => {
            if (items && items.data && items.data.message) {
                SuccessToast(items.data.message)
              }
              logOut()
          disableLoading();
          setSubmitting(false);
        })
        .catch((err) => {
            ErrorToast(err)
          setSubmitting(false);
          disableLoading();
        });
    },
  });

  

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (

    <div class="row">
        <div class="col-md-3 col-md-offset-3">
        </div>
      <div class="col-md-6 col-md-offset-3">

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        

                {/* begin: Password */}
                <div className="form-group fv-plugins-icon-container">
          <TextField
              fullWidth
              id="outlined-adornment-password"
              variant="outlined"
              type={showCurrentPassword ? 'text' : 'password'}
              placeholder="Current Password"
              name="current_password"
              value={formik.values.current_password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="Toggle password visibility"
                      onClick={handleClickShowCurrentPassword}
                    >
                      {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formik.touched.current_password && formik.errors.current_password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.current_password}</div>
              </div>
            ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <TextField
              fullWidth
              id="outlined-adornment-password"
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              placeholder="New Password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="Toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <TextField
              fullWidth
              id="outlined-adornment-Confirm-password"
              variant="outlined"
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder="Confirm New Password"
              name="password_confirmation"
              value={formik.values.password_confirmation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="Toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.password_confirmation}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}

        {/* begin: Terms and Conditions */}
        {/* <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            />
            <Link
              to="/terms"
              target="_blank"
              className="mr-1"
              rel="noopener noreferrer"
            >
              I agree the Terms & Conditions
            </Link>
            <span />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div> */}
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid 
            }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Change Password</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      
      </div>
        <div class="col-md-3 col-md-offset-3">
        </div>
    </div>
  );
}
