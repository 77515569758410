import React, { useState } from 'react';



const FileUpload = props => {

    const [file, setFile] = useState();
    const { name, label, onChange = () => { }, errors, onBlur = () => { } } = props

    return (<div><input
        style={{ display: "none" }}
        id={name}
        name={name}
        type="file"
        onBlur={() => onBlur(name, true)}
        onChange={event => {
            onChange(event.currentTarget.files[0])
            setFile(event.currentTarget.files[0])
        }}
        className="form-control"
    />
        <label htmlFor={name} className="fileUpload pt-2 pb-2" style={props.labelStyle || {} }>
            {
                file && file.name ? (<><i className="fa fa-file text-success"></i><span className="ml-2">{file.name}</span></>) : (
                    <><i className="fa fa-file-upload"></i> <span className="ml-2">Choose File <span className="text-danger">*</span></span></>
                )
            }
        </label>
        {errors && <label style={{ display: "block", position: "absolute" }}
            className="is-invalid text-danger">{errors}</label>}
    </div>)
}

export default FileUpload;