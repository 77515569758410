import { useSelector, useDispatch } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup'
import React, { useState, useMemo } from 'react' 
import { mobileNumberRegex,otp6 } from '../helpers/util/formUtil';
import { showErrorToast, showSuccessToast, login } from '../../../../../../redux';
import pharmacyRegister from '../helpers/api/pharmacy/pharmacyRegister'
import ButtonWithSpinner from '../helpers/general/Form/ButtonWithSpinner';
import Input from '../helpers/general/Form/Input';
import axiosApi from "../helpers/api/axios"
import { toast } from "../helpers/util/toast";
import Countdown from "react-countdown";
import { ErrorToast } from "../../../../Toast/ErrorSnakBar";
import { SuccessToast } from "../../../../Toast/SuccessSnackBar";

const validationSchemaWEB = Yup.object({

    mobile_number: Yup.string()
        .nullable()
        .matches(mobileNumberRegex, "Must be a valid mobile number")
        .required('Mobile Number is required.'),
    email: Yup.string()
        .nullable()
        .email('The email must be a valid email address.')
        .required('The email field is required.'),
    mobile_otp: Yup.string() //('Must be a number')
        .nullable()
        //.min(100000, 'Must be a 6 digit')
        //.max(999999, 'Must be a 6 digit')
        .matches(otp6,"Must be a valid number")
        .length(6, "Must be a 6 digit")
        .required('The Mobile OTP field is required.'),
    email_otp: Yup.string() //('Must be a number')
        .nullable()
        //.min(100000, 'Must be a 6 digit')
        //.max(999999, 'Must be a 6 digit')
        .matches(otp6,"Must be a valid number")
        .length(6, "Must be a 6 digit")
        .required('The email OTP field is required.'),
})
function PharmacyOTPVerification() {
    let userData = useSelector(item => item.user)
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation()
    const [emailResending, setEmailResending] = useState(false)
    const [mobileResending, setMobileResending] = useState(false)
    const [showResendBlock, setShowResendBlock] = useState(false)

    const [OTPvalidationMessage, setOTPvalidationMessage] = useState(null);  
    let countDownTimeInminutes = useMemo(() => Date.now() + (1000 * 60 * 2) , []); // 2 minutes
    const [countDownTime, setCountDownTime] = useState(countDownTimeInminutes); 

    const [showOTPVerified, setShowOTPVerified] = useState({
        email : false, 
        phone : false 
    });

    let via = new URLSearchParams(location.search).get('via') || 'WEB'
    let step = new URLSearchParams(location.search).get('step')
    const redirectTo_ = new URLSearchParams(location.search).get('to')
    console.log(redirectTo_,"redirectTo_")
    if(via){
        via = via.toUpperCase()
    }
    let isContinue =  false
    if(step && step === 'continue'){
        const _otp = JSON.parse(sessionStorage.getItem('otp'))
        console.log(_otp,step)
        if(_otp && _otp.email && _otp.mobile_number){
            isContinue = true
            userData = _otp 
        }
    }
    
    //const isFbOrGoogle = via && (via === 'FACEBOOK'  || via === "GOOGLE")
    const resendEmail = email => {
        setShowResendBlock(false);
        setCountDownTime( countDownTimeInminutes )

        if (!email) {
            return;
        }
        setEmailResending(true)
        const inputData = {
            email: email
        }
        pharmacyRegister.pharmacyResendMailOtp(inputData).then(res => {
            if (res && res.data && res.data.message) {
                SuccessToast(res.data.message)
              }
            const { data: { message } = {} } = res
            if (message) {
                dispatch(showSuccessToast(message));
            }
            setEmailResending(false)
        }).catch((err) => {
            // const {
            //     response: {
            //         data: { message } = {}
            //     }
            // }
            ErrorToast(err)

            // dispatch(showErrorToast(message));
            setEmailResending(false)
        })
    }

    const resendMobileOtp = mobile_number => {
        setShowResendBlock(false);
        setCountDownTime( countDownTimeInminutes )

        if (!mobile_number) {
            return;
        }
        setMobileResending(true)
        console.log(mobile_number)
        const inputData = {
            mobile_number: mobile_number
        }
        pharmacyRegister.pharmacyResendMobileOtp(inputData).then(res => {
            if (res && res.data && res.data.message) {
                SuccessToast(res.data.message)
              }
            const { data: { message } = {} } = res
            if (message) {
                dispatch(showSuccessToast(message));
            }
            setMobileResending(false)
        }).catch((err) => {
            ErrorToast(err)

            // dispatch(showErrorToast(message));
            setMobileResending(false)

        })
    }
    const initialValues = {
        mobile_number: userData.mobile_number || '',
        mobile_otp: '',
        email: userData.email || '',
        email_otp: '',
        via: via || 'WEB'
    }
    
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) { 
          setShowResendBlock(true);
          return true;
        } else {
            // Render a countdown
            return <div style={{
                alignItems: 'baseline',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '1px 8px',
                borderRadius: '7px'
            }}> 
              <i className="mr-2 fas fa-stopwatch"></i>
              <span style={{
                backgroundColor: 'rgb(235 249 228)',
              }}>
                  {hours}:{minutes}:{seconds}
              </span>
            </div>;
        }
    };

    const validateOTP = (type, values) => {
        if (type === 'email' && values.email_otp.length < 6) {
            return ;
        }

        if (type === 'mobile' && values.mobile_otp.length < 6) {
            return ;
        } 

        axiosApi.post('oauth/pharmacy/otp/verify/mobileandemail', {
            mobile_number: values.mobile_number,
            mobile_otp: values.mobile_otp,
            email: values.email,
            email_otp: values.email_otp
        })
        .then((res) => {
            // if (res && res.data && res.data.message) {
            //     SuccessToast(res.data.message)
            //   }
            setShowOTPVerified({
                ...showOTPVerified, 
                phone: true,
                email: true,
            })
        })
        .catch((res) => {
            console.log(res)
            ErrorToast(res)
            
        //     let msgs = []
        //     let allowedMsgs = ['Incorrect Email OTP', 'Incorrect Mobile number OTP']
        //     let messageToShow = 'Incorrect Email/Mobile OTP entered. Please enter correct OTP.'

        //     if (res.response.data?.errors.email || 
        //         res.response.data?.errors.mobile_number ||  
        //         res.response.data?.errors.email_otp ||  
        //         res.response.data?.errors.mobile_otp
        //     ) {
        //         // msgs.push(res.response.data.errors.email_otp)
        //         // msgs.push(res.response.data.errors.mobile_otp)
        //         if (res && res.response.data && res.response.data.errors){
        //             if (res.response.data.errors.mobile_otp){
        //                 res.response.data.errors.mobile_otp.map(item => {
        //                     msgs.push(item)
        //                 })
        //             }
        //             if (res.response.data.errors.email_otp){
        //                 res.response.data.errors.email_otp.map(item => {
        //                     msgs.push(item)
        //                 })
        //             }
        //         }

        //         msgs.every((msg, index) => {
        //             if (allowedMsgs.includes(msg)) {
        //                 setOTPvalidationMessage(messageToShow)
        //                 return false
        //             }
        //         });
        //     }
            
        //     if (! ('email_otp' in res.response.data?.errors) ) {
        //         setShowOTPVerified({
        //             ...showOTPVerified,  
        //             email: true
        //         })
        //     } else if (! ('mobile_otp' in res.response.data?.errors)) {
        //         setShowOTPVerified({
        //             ...showOTPVerified, 
        //             phone: true
        //         })
        //     } else if (('email_otp' in res.response.data?.errors) || 'email' in res.response.data?.errors) {
        //         setShowOTPVerified({
        //             ...showOTPVerified,  
        //             email: false
        //         })

        //         if (res.response.data.errors.email_otp === 'Incorrect Email OTP') {
        //             setOTPvalidationMessage('Incorrect Email/Mobile OTP entered. Please enter correct OTP')
        //         } 
        //     } else if (('mobile_otp' in res.response.data?.errors) || 'mobile_number' in res.response.data?.errors) {
        //         alert(1);setShowOTPVerified({
        //             ...showOTPVerified, 
        //             phone: false
        //         })
             
        //         if (res.response.data.errors.mobile_otp === 'Incorrect Mobile number OTP') {
        //             toast('Incorrect Email/Mobile OTP entered. Please enter correct OTP', 'error')
        //         }    
        //     }  
        });
    }

    return (

            <div className="content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-md-12">

                            <div className="account-content">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-lg-12 login-right mt-2 mb-5">
                                        <div className="login-header">
                                            <h3 className="mb-5">Confirm OTP</h3>
                                        </div>
                                        <Formik
                                            initialValues={initialValues}
                                            validationSchema={validationSchemaWEB}
                                            onSubmit={(values, { setErrors, setSubmitting }) => {
                                                setSubmitting(true);
                                                
                                                pharmacyRegister.pharmacyOtp(values).then((res) => {
                                                    const message = res.data && res.data.message ? res.data.message : null ;
                                                    const { data : { access_token = null, user_type ='PATIENT' } = {} } = res;
                                                    if(res.data && access_token){
                                                        dispatch(login(res.data));
                                                    } 
                                                    if(message){
                                                        dispatch(showErrorToast([message]))
                                                    }
                                                    setSubmitting(false);
                                                    history.push("/auth/login");
                                                    SuccessToast("You will receive notification mail once Admin approves registration.")
                                                }).catch(error => {
                                                    let {
                                                        response: {
                                                            data: {
                                                                message, errors = {}
                                                            } = {}
                                                        } } = error

                                                    let errorMessages = [...Object.values(errors)]
                                                    if (errorMessages && errorMessages.length > 0) {
                                                        setErrors(errors)
                                                    }
                                                    dispatch(showErrorToast([message]))
                                                    setSubmitting(false)
                                                })

                                            }}
                                        >{({
                                            values,
                                            errors,
                                            touched,
                                            isValid,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting,
                                            setFieldValue,
                                            dirty
                                        }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <Input
                                                        value={values.email}
                                                        name="email"
                                                        type="email"
                                                        label="Email"
                                                        placeholder="Email"
                                                        disabled={userData.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        feedback={errors.email}
                                                        hasError={touched.email && errors.email}
                                                    />
                                                    
                                                    <div style={{
                                                    }} className="w-100 position-relative">
                                                        <Input
                                                            value={values.email_otp}
                                                            name="email_otp"
                                                            label="Email OTP"
                                                            placeholder="Email OTP"
                                                            type="text"
                                                            onChange={(e) => { 
                                                                setFieldValue('email_otp', e.target.value)
                                                                // validateOTP('email', values);
                                                            }}
                                                            onBlur={(e) => { 
                                                                setFieldValue('email_otp', e.target.value)
                                                                // validateOTP('email', values);
                                                            }}
                                                            feedback={errors.email_otp}
                                                            hasError={touched.email_otp && errors.email_otp}
                                                        />
                                                        {showOTPVerified.email && 
                                                            <i class="fas fa-check-circle" style={{
                                                                position: 'absolute',
                                                                top:0,
                                                                right:0,
                                                                padding:"10px",
                                                                fontSize: '1.6rem',
                                                                color: '#78d078'
                                                            }} /> 
                                                        }
                                                    </div>
                                                    <Input
                                                        value={values.mobile_number}
                                                        name="mobile_number"
                                                        label="Mobile phone number"
                                                        placeholder="Mobile phone number"
                                                        disabled={userData.mobile_number}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        feedback={errors.mobile_number}
                                                        hasError={touched.mobile_number && errors.mobile_number}
                                                    />
                                                    
                                                    <div className="position-relative">
                                                        <Input
                                                            value={values.mobile_otp}
                                                            name="mobile_otp"
                                                            label="Mobile Number OTP"
                                                            placeholder="Mobile Number OTP"
                                                            type="text"
                                                            onChange={(e) => {
                                                                setFieldValue('mobile_otp', e.target.value)
                                                                validateOTP('mobile', values);
                                                            }}
                                                            onBlur={(e) => { 
                                                                // handleBlur()
                                                                setFieldValue('mobile_otp', e.target.value)
                                                                validateOTP('mobile', values);
                                                            }}
                                                            feedback={errors.mobile_otp}
                                                            hasError={touched.mobile_otp && errors.mobile_otp}
                                                        />


                                                        {showOTPVerified.phone && 
                                                            <i class="fas fa-check-circle" style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 0,
                                                                padding: "10px",
                                                                fontSize: '1.6rem',
                                                                color: '#78d078'
                                                            }} /> 
                                                        } 
                                                    </div>

                                                    <div className="btn-group mb-3 w-100" role="group" aria-label="Basic example">
                                                        { ! showResendBlock &&
                                                            <Countdown 
                                                                date={countDownTime}
                                                                renderer={renderer} 
                                                            />
                                                        }
                                                    </div>           

                                                    {showResendBlock &&
                                                    <div className="btn-group mb-3 w-100" role="group" aria-label="Basic example">
                                                        <ButtonWithSpinner
                                                            isLoading={emailResending}
                                                            disabled={!values.email}
                                                            variant="outline-secondary"
                                                            style={{ width: "50%" }}
                                                            onClick={() => {
                                                                resendEmail(values.email);
                                                            }} >Resend Email OTP
                                                        </ButtonWithSpinner>
                                                        <ButtonWithSpinner
                                                            isLoading={mobileResending}
                                                            disabled={!values.mobile_number}
                                                            variant="outline-secondary"
                                                            style={{ width: "50%" }}
                                                            onClick={() => {
                                                                resendMobileOtp(values.mobile_number)
                                                            }} >Resend Phone OTP
                                                        </ButtonWithSpinner>
                                                    </div>
                                                    }

                                                    {/* <div className="btn-group mb-3 w-100" role="group" aria-label="Basic example">
                                                        <ButtonWithSpinner
                                                            isLoading={emailResending}
                                                            disabled={!values.email}
                                                            variant="outline-secondary"
                                                            style={{ width: "50%" }}
                                                            onClick={() => {
                                                                resendEmail(values.email);
                                                            }} >Resend Email OTP
                                                        </ButtonWithSpinner>
                                                        <ButtonWithSpinner
                                                            isLoading={mobileResending}
                                                            disabled={!values.mobile_number}
                                                            variant="outline-secondary"
                                                            style={{ width: "50%" }}
                                                            onClick={() => {
                                                                resendMobileOtp(values.mobile_number)
                                                            }} >Resend Phone OTP
                                                        </ButtonWithSpinner>
                                                    </div> */}
                                                    <Button disabled={!(showOTPVerified.email && showOTPVerified.phone) || !dirty || !isValid || isSubmitting} variant="primary" size="lg" style={{ height: "52px" }} block type="submit">
                                                        {isSubmitting ? (
                                                            <Spinner animation="border" />
                                                        ) : "Register"}
                                                    </Button>
                                                </form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
}


export default PharmacyOTPVerification;