import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

class Example extends React.Component {
    constructor(props, context) {
      super(props, context);
  
      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
  
      this.state = {
        show: false,
      };
    }
  
    handleClose() {
      this.setState({ show: false });
    }
  
    handleShow() {
      this.setState({ show: true });
    }

    onDelete = () => {
      if (this.props.user_type == "PHARMACIST") {
        const newMedicineList = this.props.Quotedata.medicine_list.filter((item) => item.id !== this.props.Quotedata.medicine_list[this.props.index].id)
        const data = this.props.Quotedata
        data['medicine_list'] = newMedicineList
        this.props.SaveNewQuoteData(data)
        this.handleClose()
    }

    if (this.props.user_type == "LABORATORY") {
      const newTestList = this.props.Quotedata.test_list.filter((item) => item.id !== this.props.Quotedata.test_list[this.props.index].id)
      const data = this.props.Quotedata
      data['test_list'] = newTestList
      this.props.SaveNewQuoteData(data)
      this.handleClose()
  }
}
  
    render() {
      return (
        <>
          <a
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            onClick={this.handleShow}
        >
            <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
        </a>
  
          <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure want to delete ?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={this.onDelete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }
  
  export default Example;
  
  