import http from "../../../Utils/http";

    export function LoadMedicineData(data) {
      return http.get(`api/pharmacy/quote?page=${data.no}`);
    }

    export function LoadMedicineDataID(id) {
      return http.get(`api/pharmacy/quote/${id}`);
    }

    export function LoadLaboratoryData(data) {
      return http.get(`api/laboratory/quote?page=${data.no}`);
    }

    export function LoadLaboratoryDataID(id) {
      return http.get(`api/laboratory/quote/${id}`);
    }

    export function EditPharmacyOrder(id, data) {
      return http.post(`api/orders/${id}`, data);
    }