// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Card } from 'react-bootstrap';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import getSymbolFromCurrency from 'currency-symbol-map';
import { GetCurrencyCode } from "../../../Utils/GetData";

const useStyles = makeStyles(theme => ({
    label: {
        // width: '150px',
  
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '26px',
        letterSpacing: '-0.008em',
  
      },
      content : {
        color: '#77777A',
        marginTop: '10px',
        marginBottom: '5px',
  
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '21px',
      }
    }));


export function ViewCard(props) {
    const classes = useStyles();
    const { Quotedata } = props
    // console.log('Quotedata', Quotedata);
    const address = Quotedata?.order?.billing_address[0] && Quotedata.order.billing_address[0].street_name+", "+Quotedata.order.billing_address[0].city_village+", "+Quotedata.order.billing_address[0].district+", "+Quotedata.order.billing_address[0].state+", "+Quotedata.order.billing_address[0].country+", "+Quotedata.order.billing_address[0].pincode+", "+Quotedata.order.billing_address[0].land_mark
    const country_code = Quotedata?.order?.billing_address[0] && Quotedata.order.billing_address[0].country_code ? Quotedata.order.billing_address[0].country_code : "+91"
    const mobile = Quotedata?.order?.billing_address[0] && Quotedata.order.billing_address[0].contact_number

    return (
        <div>
            <Card border="dark" >
                <Card.Body>
                    
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                    <Typography className={classes.label}>
                      Patient Details
                    </Typography>

                    <Typography className={classes.content}>
                      {
                        Quotedata?.prescription?.purchase_type === "Appointment" && [
                          Quotedata.prescription.appointment?.current_patient_info?.info?.first_name,
                          Quotedata.prescription.appointment?.current_patient_info?.info?.middle_name,
                          Quotedata.prescription.appointment?.current_patient_info?.info?.last_name
                        ].join(" ")
                      }
                      {
                        Quotedata?.prescription?.purchase_type === "Ecommerce" && [
                          Quotedata.prescription.patient?.first_name,
                          Quotedata.prescription.patient?.middle_name,
                          Quotedata.prescription.patient?.last_name
                        ].join(" ")
                      }
                    </Typography>

                    <Typography className={classes.content}>
                      {address}
                    </Typography>

                    <Typography className={classes.content}>
                      {country_code} {mobile}
                    </Typography>

                    </Grid>

                    <Grid item xs={6}>
                    <Typography className={classes.label}>
                      Payment details
                    </Typography>
                    
                    <Typography className={classes.content}>
                    Paid amount : {getSymbolFromCurrency(GetCurrencyCode())} {Quotedata?.order?.total}
                    </Typography>
                    <Typography className={classes.content}>
                    Payment ID : {Quotedata?.order?.payments?.unique_id}
                    </Typography>
                    <Typography className={classes.content}>
                    Date : {Quotedata?.order?.created_at}
                    </Typography>
                    </Grid>
                  </Grid>
                </Card.Body>
            </Card>
            <br />
        </div>
    );
}
