import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Spinner from "../../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import { PaymentData } from "../../../modules/Apis/Payment/PaymentApi";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Table from "./Table"
import { connect } from 'react-redux';

export function Payout (props) {
  const user_type = props && props.me && props.me.user_type
  var type 
  if (user_type == "PHARMACIST") {
    type = "MED"
    }
  if (user_type == "LABORATORY") {
    type = "LAB"
    }
  const [data, setData] = React.useState(undefined)
  const [filterData, setFilterData] = React.useState({
    today : '',
    last_30_days : '',
    financial_year : '',
    start_date : '',
    end_date : '',
  })
  const [dateError, setDateError] = React.useState(<p></p>)
  const [mounted, setMounted] = React.useState(true)
  const [loading, setLoading] = React.useState(true)
  const [state, setState] = React.useState({
    filter: ' ',
    start_date : '',
    end_date : '',
  });

  const loadData = (data, page=1) => {
    PaymentData(data, type, page)
      .then(items => {
        setLoading(false)
        setData(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
        setLoading(false)
    })
  }

  const loadPageData = (no) => {
    loadData(filterData, no);
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({
      ...state,
      [name]: value,
    });
    const data = {
      today : '',
      last_30_days : '',
      financial_year : '',
      start_date : '',
      end_date : '',
    }
    if (value !== "custom") {
      setData(undefined)
      data[value] = 1
      loadData(data)
      setFilterData(data)
    } else {
      const newData = {
        "filter": 'custom',
        "start_date": '',
        "end_date": '',
      }
    setState(newData);
    }
  };

  const handleDateChange = (event) => {
    setDateError(<p></p>)
    const name = event.target.name;
    const value = event.target.value;
    setState({
      ...state,
      [name]: value,
    });
    const data = {
      today : '',
      last_30_days : '',
      financial_year : '',
      start_date : state.start_date,
      end_date : state.end_date,
    }
    data[name] = value
    if ((data.start_date && data.end_date) || (!data.start_date && !data.end_date)) {
      if (data.start_date > data.end_date) {
        setDateError(<p style={{color:"red"}}>Invalid Date Range</p>)
      } else {
        setData(undefined)
        loadData(data)
        setFilterData(data)
      }
    }
  };

  

  React.useEffect(() => {
    if (mounted) {
      const page = 1
      const data = {
        today : '',
        last_30_days : '',
        financial_year : '',
        start_date : '',
        end_date : '',
      }
      loadData(data, page)
      setMounted(false)
    }
  }, [loadData, setLoading])

  if (loading) {
    return (
      <Spinner />
    )
  }

  return (
    <Card>
      <CardHeader title="Payouts">
        <CardHeaderToolbar>
          
        {state.filter === "custom" && <div>
          <TextField
            id="start_date"
            label="Start Date"
            type="date"
            variant="outlined"
            onChange={handleDateChange}
            margin="dense"
            name='start_date'
            defaultValue={state.start_date}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
           style={{marginLeft:"25px", marginRight:"25px"}}  
            id="date"
            label="End Date"
            type="date"
            variant="outlined"
            onChange={handleDateChange}
            margin="dense"
            name='end_date'
            defaultValue={state.end_date}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {dateError}
        </div>}
          
          <FormControl variant="outlined" >
            <InputLabel htmlFor="outlined-age-native-simple">Filter</InputLabel>
            <Select
              native
              value={state.filter}
              onChange={handleChange}
              label="Filter"
              margin="dense"
              inputProps={{
                name: 'filter',
                id: 'outlined-age-native-simple',
              }}
            >
              <option value=" ">All</option>
              <option value="today">Today</option>
              <option value="last_30_days">Last 30 days</option>
              <option value="financial_year">The financial year</option>
              <option value="custom">Custom</option>
            </Select>
          </FormControl>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {data && <Table type={type} data={data} loadPageData={loadPageData} />}
      </CardBody>
    </Card>
  );
};

const mapStateToProps = function (state) {
  return {
    me: state.me,
  }
}

export default connect(mapStateToProps)(Payout);
