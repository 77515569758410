import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
// import {ProfileData} from "../../../modules/Apis/Pharmacy/Profile/Profile";
import Spinner from "../../Utils/Components/CustomSpinner";
import { Select, Search } from "../../Components";
import PharmacyTable from "./PharmacyTable";
import LaboratoryTable from "./LaboratoryTable";
import { QuotePharmacyData, QuoteLaboratoryData, DoctorList, LoadPageQuotePharmacyData, LoadPageQuoteLaboratoryData } from "../../modules/Apis/Pharmacy/Quotes/QuotesApi";
import {ErrorToast} from "../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../modules/Toast/SuccessSnackBar";
import { connect } from 'react-redux';

const List = (props) => {
  const user_type = props && props.me && props.me.user_type
  const [mounted, setMounted] = React.useState(true)
  const [loading, setLoading] = React.useState(true)
  const [quoteList, setQuoteList] = React.useState()
  const [doctorList, setDoctorList] = React.useState()
  const [doctor, setDoctor] = React.useState(" ")
  const [status, setStatus] = React.useState(0)
  const [searchValue, setSearchValue] = React.useState(" ")

  const StatusOptions = [
    {value:1, label:"Quote Generated"},
    {value:0, label:"New"},
  ]

 
  const loadPharmacyQuoteData = (data) => {
    QuotePharmacyData(data)
      .then(items => {
        setLoading(false)
        // console.log('items.data', items.data);
        setQuoteList(items.data)
      })
      .catch(err => {
        setLoading(false)
        setQuoteList(undefined)
        ErrorToast(err)
    })
  }

  const loadLaboratoryQuoteData = (data) => {
    QuoteLaboratoryData(data)
      .then(items => {
        setLoading(false)
        setQuoteList(items.data)
      })
      .catch(err => {
        setLoading(false)
        setQuoteList(undefined)
        ErrorToast(err)
    })
  }

  const loadPageData = (no) => {
    setLoading(true)
    setQuoteList(undefined)
    const data = {
      search : searchValue,
      doctor : doctor,
      status : status,
    }
    if (user_type == "PHARMACIST") {
    LoadPageQuotePharmacyData(no, data)
      .then(items => {
        setLoading(false)
        setQuoteList(items.data)
      })
      .catch(err => {
        setLoading(false)
        setQuoteList(undefined)
        ErrorToast(err)
    })
  }
  if (user_type == "LABORATORY") {
    LoadPageQuoteLaboratoryData(no, data)
      .then(items => {
        setLoading(false)
        setQuoteList(items.data)
      })
      .catch(err => {
        setLoading(false)
        setQuoteList(undefined)
        ErrorToast(err)
    })
  }
  }

  

  const SearchData = (searchValue) => {
    const data = {
      search : searchValue,
      doctor : doctor,
      status : status,
    }
    if (user_type == "PHARMACIST") {
        loadPharmacyQuoteData(data)
      }
    if (user_type == "LABORATORY") {
        loadLaboratoryQuoteData(data)
      }
  }

  const loadDoctorData = () => {
    DoctorList()
      .then(items => {
        setLoading(false)
        // console.log('items.data', items.data);
        const list = []
        items.data.map((item) => {
          if (item.middle_name != null) {
          list.push({
            label : item.first_name + ' ' + item.middle_name + ' ' + item.last_name,
            value : item.first_name,
          })}
          if (item.middle_name == null) {
            list.push({
              label : item.first_name + ' ' + item.last_name,
              value : item.first_name,
            })}
        })
        setDoctorList(list)
      })
      .catch(err => {
        setLoading(false)
        ErrorToast(err)
    })
  }


  

  React.useEffect(() => {
    const data = {
      search : searchValue,
      doctor : doctor,
      status : status,
    }
    if (user_type == "PHARMACIST") {
      if (mounted) {
        loadDoctorData()
        loadPharmacyQuoteData(data)
        setMounted(false)
      }
    }
    if (user_type == "LABORATORY") {
      if (mounted) {
        loadDoctorData()
        loadLaboratoryQuoteData(data)
        setMounted(false)
      }
    }
  }, [loadPharmacyQuoteData, loadLaboratoryQuoteData, loadDoctorData])

  

  const backToProductsList = () => props.history.goBack()
  const saveProduct = () => { }
  const btnRef = useRef();
  const resetbtnRef = useRef()
  const saveProductClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
      
    }
  };
  const resetForm = () => {
    if(resetbtnRef && resetbtnRef.current){
      resetbtnRef.current.click()
    }
  }

  if (loading) {
    return (
      <Spinner />
    )
  }

  return (
    <Card>
      <CardHeader title="Received Quote Request">
        <CardHeaderToolbar>
          <Search
          SearchData={SearchData}
          setSearchValue={setSearchValue}
          />
          <Select
          margin="dense"
          none={true}
          value={doctor}
          onChange = {(value) => {
            setDoctor(value)
            const data = {
              search : searchValue,
              doctor : value,
              status : status,
            }
            if (user_type == "PHARMACIST") {
                loadPharmacyQuoteData(data)
              }
            if (user_type == "LABORATORY") {
                loadLaboratoryQuoteData(data)
              }
          }}
          label="Doctor"
          options={doctorList}
           />
          <Select
          margin="dense"
          none={true}
          value={status}
          onChange = {(value) => {
            setStatus(value)
            const data = {
              search : searchValue,
              doctor : doctor,
              status : value,
            }
            if (user_type == "PHARMACIST") {
                loadPharmacyQuoteData(data)
              }
            if (user_type == "LABORATORY") {
                loadLaboratoryQuoteData(data)
              }
          }}
          label="Status"
          options={StatusOptions}
           />
        </CardHeaderToolbar>
      </CardHeader>
        
      <CardBody>
        <div className="mt-5">
          {quoteList && user_type == "PHARMACIST" && <PharmacyTable 
          loadPageData={loadPageData}
          data={quoteList}
          user_type={user_type}
          />}

          {quoteList && user_type == "LABORATORY" && <LaboratoryTable 
          loadPageData={loadPageData}
          data={quoteList}
          user_type={user_type}
          />}
        </div>
      </CardBody>
    </Card>
  )
};

const mapStateToProps = function (state) {
  return {
    me: state.me,
  }
}

export default connect(mapStateToProps)(List);
