import React from "react";
import { ViewCard } from "./ViewCard";
import Spinner from "../../../Utils/Components/CustomSpinner";
import { LoadMedicineDataID, LoadLaboratoryDataID, EditPharmacyOrder } from "../../../modules/Apis/Dispense/DispenseApi";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import { useHistory } from "react-router-dom";
import PharmacyEditOrder from "./PharmacyEditOrder";
import LaboratoryEditOrder from "./LaboratoryEditOrder";
import { Alert } from 'react-bootstrap';




export function ShowForm (props) {
    const history = useHistory();
    const {detail, user_type} = props
    const [mounted, setMounted] = React.useState(true)
    const [loading, setLoading] = React.useState(true)
    const [Quotedata, setQuotedata] = React.useState()

    
    const loadPharmacyQuoteData = () => {
      const id = detail.id
        LoadMedicineDataID(id)
          .then(items => {
            setLoading(false)
            // console.log('items.data', items.data);
            setQuotedata(items.data)
          })
          .catch(err => {
            setLoading(false)
            setQuotedata(undefined)
            ErrorToast(err)
        })
      }

      const loadLaboratoryQuoteData = () => {
        const id = detail.id
        LoadLaboratoryDataID(id)
            .then(items => {
              setLoading(false)
              // console.log('items.data', items.data);
              setQuotedata(items.data)
            })
            .catch(err => {
              setLoading(false)
              setQuotedata(undefined)
              ErrorToast(err)
          })
        }

      const EditQuoteData = (data) => {
        setLoading(true)
        const id = detail.order.id
        EditPharmacyOrder(id, data)
          .then(items => {
            // console.log('items.data', items.data);
            if (items && items.data && items.data.message) {
              SuccessToast(items.data.message)
            }
            if (user_type == "PHARMACIST") {
              loadPharmacyQuoteData()
            }
            if (user_type == "LABORATORY") {
              loadLaboratoryQuoteData()
            }
          })
          .catch(err => {
            ErrorToast(err)
            setLoading(false)
        })
      }

      React.useEffect(() => {
        if (user_type == "PHARMACIST") {
          if (mounted) {
            loadPharmacyQuoteData()
            setMounted(false)
          }
        }
        if (user_type == "LABORATORY") {
          if (mounted) {
            loadLaboratoryQuoteData()
            setMounted(false)
          }
        }
      }, [loadPharmacyQuoteData, loadLaboratoryQuoteData])





if (loading) {
    return (
      <Spinner />
    )
  }

    return (
        <div>
            {(Quotedata?.order?.delivery_status === "Cancelled") && 
            <Alert variant="danger">
              Order has been cancelled!
            </Alert>}
            {Quotedata && <ViewCard user_type={user_type} Quotedata={Quotedata} />}
            {user_type == "PHARMACIST" && Quotedata && <PharmacyEditOrder user_type={user_type} Quotedata={Quotedata} EditQuoteData={EditQuoteData} />}
            {user_type == "LABORATORY" && Quotedata && <LaboratoryEditOrder user_type={user_type} Quotedata={Quotedata} EditQuoteData={EditQuoteData} />}
        </div>
    )
}