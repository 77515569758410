import React from "react";
import { Card } from 'react-bootstrap';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import getSymbolFromCurrency from 'currency-symbol-map'
import { GetCurrencyCode } from "../../../../Utils/GetData";

const useStyles = makeStyles(theme => ({
    label: {
        // width: '150px',
        color: 'black',
  
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '26px',
        letterSpacing: '-0.008em',
  
      },
      content : {
        color: '#77777A',
        marginTop: '10px',
        marginBottom: '5px',
  
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '21px',
      }
    }));


export function ViewCard(props) {
    const classes = useStyles();
    const { OrderData } = props
    // console.log('OrderData', OrderData);
    return (
        <div>
            <Grid container spacing={3} style={{margin:"2px"}}>
              <Grid item xs={6}>
              {/* <Typography className={classes.label}>
                Pharmacy details
              </Typography> */}

              <Typography className={classes.content}>
              Payout ID : {OrderData?.payout_id}
              </Typography>

              <Typography className={classes.content}>
              Payout cycle : {OrderData?.cycle}
              </Typography>

              <Typography className={classes.content}>
              Payout period : {OrderData?.period}
              </Typography>

              <Typography className={classes.content}>
              Date & Time : {OrderData?.time}
              </Typography>
            
              </Grid>
              <Grid item xs={6}>

              <Typography className={classes.content}>
              Total Payable : {getSymbolFromCurrency(GetCurrencyCode())} {OrderData?.total_payable}
              </Typography>

              <Typography className={classes.content}>
              Total Paid : {getSymbolFromCurrency(GetCurrencyCode())} {OrderData?.total_paid}
              </Typography>

              <Typography className={classes.content}>
              Balance : {getSymbolFromCurrency(GetCurrencyCode())} {OrderData?.balance}
              </Typography>

              <Typography className={classes.content}>
              Previous Due : {getSymbolFromCurrency(GetCurrencyCode())} {OrderData?.previous_due}
              </Typography>

              <Typography className={classes.content}>
              Current Due : {getSymbolFromCurrency(GetCurrencyCode())} {OrderData?.current_due}
              </Typography>
            
              </Grid>
            </Grid>
        </div>
    );
}
