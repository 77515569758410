
import React from 'react';
import TextField from '@material-ui/core/TextField';


export default function Text(props) {
  const {row, rows, Quotedata, index, SaveNewQuoteData, user_type} = props
  var name
  if (user_type == "PHARMACIST") {
    name = row.medicine.price_per_strip
  }
  if (user_type == "LABORATORY") {
    name = row.test.price
  }

  const [values, setValues] = React.useState({
    name: name
  });

  const numberandfullRex = /^\d*\.?\d*$/

  const handleChange = name => event => {
    if (numberandfullRex.test(event.target.value)) {
      setValues({ ...values, [name]: event.target.value });
      if (user_type == "PHARMACIST") {
        row['medicine']['price_per_strip'] = event.target.value
        rows[index] = row
        Quotedata['medicine_list'] = rows
        SaveNewQuoteData(Quotedata)
      }
      if (user_type == "LABORATORY") {
        row['test']['price'] = event.target.value
        rows[index] = row
        Quotedata['test_list'] = rows
        SaveNewQuoteData(Quotedata)
      }
    }
  };

  return (
    <form>
      <TextField
        style={{width:'100px'}}
        id="outlined-name"
        value={values.name}
        onChange={handleChange('name')}
        margin="dense"
        variant="outlined"
        // type="number"
      />
    </form>
  );
}
