import http from "../../../../Utils/http";

export function QuotePharmacyData(data) {
    return http.get(`api/pharmacy/quote/request?filter[search]=${data.search}&filter[doctor]=${data.doctor}&filter[status]=${data.status}`);
  }

  export function AddPharmacyQuote(data) {
    return http.post(`api/pharmacy/quote`, data);
  }

  export function QuoteLaboratoryData(data) {
    return http.get(`api/laboratory/quote/request?filter[search]=${data.search}&filter[doctor]=${data.doctor}&filter[status]=${data.status}`);
  }

  export function AddLaboratoryQuote(data) {
    return http.post(`api/laboratory/quote`, data);
  }

  export function MedicineData() {
      return http.get(`api/search/medicine/?paginate=0`);
    }

  export function TestData() {
      return http.get(`api/guest/search/labtest/?paginate=0`);
    }

    export function DoctorList() {
      return http.get(`api/list/doctor`);
    }

    export function LoadPageQuotePharmacyData(no, data) {
      return http.get(`api/pharmacy/quote/request?page=${no}&filter[search]=${data.search}&filter[doctor]=${data.doctor}&filter[status]=${data.status}`);
    }

    export function LoadPageQuoteLaboratoryData(no, data) {
      return http.get(`api/laboratory/quote/request?page=${no}&filter[search]=${data.search}&filter[doctor]=${data.doctor}&filter[status]=${data.status}`);
    }


    export function LoadNewMedicineData(id) {
      return http.get(`api/pharmacy/quote/${id}/request`);
    }

    export function LoadNewLaboratoryData(id) {
      return http.get(`api/laboratory/quote/${id}/request`);
    }

    

  export function EditPharmacyQuote(data) {
    return http.post(`api/pharmacy/quote/request`, data);
  }

  export function EditLaboratoryQuote(data) {
    return http.post(`api/laboratory/quote/request`, data);
  }
