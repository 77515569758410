import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from 'react-bootstrap/Button';
import Grid from '@material-ui/core/Grid';

export function EditLaboratoryForm(props) {
  const { searchlist, Quotedata, index, SaveNewQuoteData } = props
  const [value, setValue] = React.useState(Quotedata.test_list[index].test_name);

  const options = searchlist.map((item) => item.name)

const onClickSave = () => {
  const medicineDetail = (searchlist.filter((item) => item.name == value))[0]
  var newTestList = Quotedata.test_list
  newTestList[index] = {
    id: Quotedata.test_list[index].id,
    instructions: Quotedata.test_list[index].instructions,
    test_status: Quotedata.test_list[index].test_status,
    laboratory_id: Quotedata.test_list[index].laboratory_id,
    prescription_id: Quotedata.test_list[index].prescription_id,
    test_name: medicineDetail.name,
    lab_test_id: medicineDetail.id,
    test:medicineDetail,
  }
  const data = Quotedata
  data['test_list'] = newTestList
  SaveNewQuoteData(data)
  props.handleHide()
}

  return (
    <div>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        id="controllable-states-demo"
        options={options}
        renderInput={(params) => <TextField {...params} label="Search Medicine" variant="outlined" />}
      />
      
      <Grid container spacing={3} style={{marginTop:'2%'}}>
                                <Grid item xs={6}>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button style={{margin:'3px'}} variant="secondary" onClick={props.handleHide}>
                                        Close
                                    </Button>
                                    <Button style={{margin:'3px'}} onClick={onClickSave} variant="primary" >
                                        Save Changes
                                    </Button>
                                </Grid>
                            </Grid>
    </div>
    
  );
}
