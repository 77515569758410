import React, { useEffect } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

function Maps(props) {
  const { position = {}, onChange = () => {} } = props;

  const onMarkerDragEnd = (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();

    onChange({ lat, lng });
  };

  const getLocation = () => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const coords = pos.coords;
        const lat = coords.latitude;
        const lng = coords.longitude;

        onChange({ lat, lng });
      });
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const location = {
      latitude: position.lat,
      longitude: position.lng,
    };
  };

  const centerMoved = (mapProps, map, coord) => {
    const lat = map.getCenter().lat();
    const lng = map.getCenter().lng();
    if (!lat || !lng) return;
    onChange({ lat, lng });
  };
  const styles = {
    width: "96%",
    maxWidth: "600px",
    height: "300px",
    margin: "0 auto",
    ...props.styles,
  };
  return (
    <Map
      google={props.google}
      style={styles}
      onDragend={centerMoved}
      zoom={14}
      center={{
        lat: position.lat,
        lng: position.lng,
      }}
      initialCenter={{
        lat: position.lat,
        lng: position.lng,
      }}
    >
      <Marker
        position={position}
        draggable={true}
        onDragend={(t, map, coord) => onMarkerDragEnd(coord)}
      />
    </Map>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBOmdfLTmy6_KzaN2mNQfBCl96S4SU4hHk",
})(Maps);
