import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import toastReducer from "./toast/toastReducers";
import userReducer from "./user/userReducers";
import pharmacyReducer from './pharmacy/pharmacyReducer'
import labReducer from './laboratory/labReducer'
import fillAppointmentReducer from './Doctor/Appointment/fillAppointmentReducer';
import meReducer from './me/meReducer';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  toast: toastReducer,
  user: userReducer,
  pharmacyId: pharmacyReducer,
  labId: labReducer,
  doctorFillAppointment: fillAppointmentReducer,
  me: meReducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
