import React from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import Spinner from "../../../../Utils/Components/CustomSpinner";
import { PaymentDataId } from "../../../../modules/Apis/Payment/PaymentApi";
import { ViewCard } from "./ViewCard";
import { Records } from "./Records";

export default function App (props) {
    const type = props?.location?.state?.type
    const detail = props?.location?.state?.detail
    const history = useHistory();
  const [loading, setLoading] = React.useState(true)
  const [mount, setMount] = React.useState(true);
  const [OrderData, setOrderData] = React.useState()

  const getOrderData = (data) => {
    setLoading(true);
    PaymentDataId(data).then(response => {
      setOrderData(response.data[0]);
      // console.log(response.data[0]);
      setLoading(false)
    })
      .catch(error => {
        // console.log(error);
        setLoading(false);
      });
  }

  React.useEffect(()=> {
    if (mount) {
        const data = {
            type : type,
            id : detail?.id
        }
      getOrderData(data)
    setMount(false)
}
}, [ getOrderData, mount])

  if (loading) {
    return (
      <Spinner />
    )
  }

  return (
    <Card>
      <CardHeader title={"Payout Detail View"}>
        <CardHeaderToolbar>
        <button
            className="btn btn-primary ml-2"
            onClick={() => {
              history.goBack();
            }}
          >
            Back
            </button>
        </CardHeaderToolbar>
      </CardHeader>
        
      <CardBody>
        {OrderData && <ViewCard OrderData={OrderData} type={type} />}
        {OrderData && <Records OrderData={OrderData} type={type} />}
      </CardBody>
    </Card>
  )
};
