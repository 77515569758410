import { timezone } from './timezone';
import http from "../../../Utils/http";

export const LOGIN_URL = "api/oauth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";

export function login(email, password) {
  const data = {
    username: email,
    password: password,
    timezone: timezone
  }
  return http.post(LOGIN_URL, data);
}

export function register(email, fullname, username, password) {
  return http.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return http.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return http.get(ME_URL);
}
