import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Spinner from "../../../Utils/Components/CustomSpinner";
import { Select } from "../../../Components";
import Table from "./Table";
import { PharmacyPayoutData, LaboratoryPayoutData } from "../../../modules/Apis/Payout/PayoutApi";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import { connect } from 'react-redux';

const Payment = (props) => {
  const user_type = props && props.me && props.me.user_type
  const [mounted, setMounted] = React.useState(true)
  const [loading, setLoading] = React.useState(true)
  const [payout_list, set_payout_list] = React.useState()
  const [paid, set_paid] = React.useState("")

  const StatusOptions = [
    {value:1, label:"Paid"},
    {value:0, label:"Not Paid"},
  ]

 
  const loadPharmacyPayoutData = (data) => {
    PharmacyPayoutData(data)
      .then(items => {
        setLoading(false)
        // console.log('items.data', items.data);
        set_payout_list(items.data)
      })
      .catch(err => {
        setLoading(false)
        set_payout_list(undefined)
        ErrorToast(err)
    })
  }

  const loadLaboratoryPayoutData = (data) => {
    LaboratoryPayoutData(data)
      .then(items => {
        setLoading(false)
        // console.log('items.data', items.data);
        set_payout_list(items.data)
      })
      .catch(err => {
        setLoading(false)
        set_payout_list(undefined)
        ErrorToast(err)
    })
  }

  const loadPageData = (no) => {
    setLoading(true)
    set_payout_list(undefined)
    const data = {
      no: no,
      paid : paid,
    }
    if (user_type == "PHARMACIST") {
      loadPharmacyPayoutData(data)
    }
    if (user_type == "LABORATORY") {
      loadLaboratoryPayoutData(data)
    }
  }

  React.useEffect(() => {
    const data = {
      no: 1,
      paid : paid,
    }
    if (user_type == "PHARMACIST") {
      if (mounted) {
        loadPharmacyPayoutData(data)
        setMounted(false)
      }
    }
    if (user_type == "LABORATORY") {
      if (mounted) {
        loadLaboratoryPayoutData(data)
        setMounted(false)
      }
    }
  }, [loadPharmacyPayoutData, loadLaboratoryPayoutData])

  

  if (loading) {
    return (
      <Spinner />
    )
  }

  return (
    <Card>
      <CardHeader title={payout_list?.next_payout_period ? ("Upcoming Payout Date: "+payout_list?.next_payout_period) : ("Upcoming Payout Date: NA")}>
        <CardHeaderToolbar>
          <Select
          margin="dense"
          none={true}
          value={paid}
          onChange = {(value) => {
            set_paid(value)
            setLoading(true)
            const data = {
              no: 1,
              paid : value,
            }
            if (user_type == "PHARMACIST") {
              loadPharmacyPayoutData(data)
              }
            if (user_type == "LABORATORY") {
              loadLaboratoryPayoutData(data)
              }
          }}
          label="Status"
          options={StatusOptions}
           />
        </CardHeaderToolbar>
      </CardHeader>
        
      <CardBody>
        <div className="mt-5">
          {payout_list && <Table 
          loadPageData={loadPageData}
          data={payout_list}
          user_type={user_type}
          />}
          
        </div>
      </CardBody>
    </Card>
  )
};

const mapStateToProps = function (state) {
  return {
    me: state.me,
  }
}

export default connect(mapStateToProps)(Payment);
