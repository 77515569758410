import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { ShowForm } from "./ShowForm";

export const DetailView = (props) => {
  const detail = props.location.state.detail
  const user_type = props.location.state.user_type
  // console.log("detail", detail);

  const backToStoresList = () => props.history.goBack()
  const saveStore = () => { }
  const btnRef = useRef();
  const resetbtnRef = useRef()
  const saveStoreClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };
  const resetForm = () => {
    if (resetbtnRef && resetbtnRef.current) {
      resetbtnRef.current.click()
    }
  }

  
  return (
    <Card>
      <CardHeader title={`${detail && detail.unique_id && detail.unique_id} Detail View`}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToStoresList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
              Back To List
            </button>
          
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          <ShowForm
            detail={detail}
            user_type={user_type}
          />
        </div>
      </CardBody>
    </Card>
  )
};
