import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
// import { StoresTable } from "./StoreTable";
import Spinner from "../../../Utils/Components/CustomSpinner";
import TestTable from "./TestTable";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import {loadTotalTestData, loadSelectedTestData} from "../../../modules/Apis/Laboratory/Tests/TestsApi";



export const ListStores = (props) => {
  const [selectdata, setSelectdata] = React.useState(undefined)
  const [lab_tests, setdata] = React.useState(undefined)
  const [mounted, setMounted] = React.useState(true)
  const [loading, setLoading] = React.useState(true)
  
  
  const loadData = () => {
    if (mounted) {
      setMounted(false)
      loadTotalTestData()
      .then(items => {
        setMounted(false)
        setLoading(false)
        setSelectdata(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
        setLoading(false)
        setMounted(false)
    })
    }
  }
  
  const loadTestData = () => {
    if (mounted) {
      setMounted(false)
      loadSelectedTestData()
      .then(items => {
        setMounted(false)
        setLoading(false)
        setdata(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
        setLoading(false)
        setMounted(false)
    })
    }
  }

  const updateData = () => {
    setLoading(true)
    setSelectdata(undefined)
    setdata(undefined)
    
    loadTotalTestData()
    .then(items => {
      setLoading(false)
      setSelectdata(items.data)
      
    })

    loadSelectedTestData()
      .then(items => {
        setLoading(false)
        setdata(items.data)
      })
  }
  
  React.useEffect(() => {
    loadData()
    loadTestData()
  }, [loadData, loadTestData])


  if (loading) {
    return (
      <Spinner />
    )
  }
  return (
    <Card>
      <CardHeader title="Test list">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => props.history.push('/test/add')}
          >
            Add new Test
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {selectdata && lab_tests && <TestTable 
        selectdata={selectdata}
        lab_tests={lab_tests.lab_tests}
        updateData={updateData}
        />}
      </CardBody>
    </Card>
  );
};
