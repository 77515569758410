// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import { useHistory } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map';
import { GetCurrencyCode } from "../../../Utils/GetData";
import TextField from '@material-ui/core/TextField';
import { DatePickerField } from "./../../../../_metronic/_partials/controls/forms/DatePickerField";
import { Button, Modal } from "react-bootstrap";

const numberandfullRex = /^[0-9]\d*(\.\d+)?$/
// Validation schema


const useStyles = makeStyles(theme => ({
    label: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center'
      },
    }));

export default function StoreEditForm(props) {
    const { Quotedata, EditQuote } = props
    const [show, setShow] = React.useState(false);
    const [values, setValues] = React.useState(false);

    const handleClose = () => {
        setShow(false);
    }

    const onSubmit = () => {
        // console.log('values', values);
        EditQuote(values)
        handleClose();
    }




    const initialValues = {
        quote_request_id : Quotedata.id,
        bill_number : Quotedata.bill_number ? Quotedata.bill_number : '',
        bill_amount : Quotedata.bill_amount ? Quotedata.bill_amount : '',
        bill_date : Quotedata.bill_date ? Quotedata.bill_date : '',
        status : "Dispensed"
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    bill_number: Yup.string()
                    .nullable()
                    .required('Bill number is required'),
                    bill_amount: Yup.string()
                        .required(`Bill amount is required`)
                        .matches(numberandfullRex, 'Invalid Data!')
                        .nullable(),
                    bill_date: Yup.date('Must be a valid date')
                    .nullable()
                    .required('Bill date field is required.'),
                })}
                onSubmit={(values) => {
                    setValues(values)
                    setShow(true)
                }
    }
            >
                {({ setFieldValue, handleChange, handleBlur, touched, values, errors }) => (
                    <>
                        <Form className="form form-label-right">
                        <Grid container spacing={3} style={{marginBottom:"2%", marginTop:"2%"}}>
                            <Grid item xs={4}>
                            <TextField
                            disabled={Quotedata.pharma_lab_status !== "Not Dispensed"}
                                fullWidth
                                required
                                label="Bill number"
                                name="bill_number"
                                type="text"
                                value={values.bill_number}
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                                {errors.bill_number && touched.bill_number && (
                                <p style={{color:"red"}}>{errors.bill_number}</p>
                                )}
                            </Grid>
                            <Grid item xs={4}>
                            <DatePickerField 
                            disabled={Quotedata.pharma_lab_status !== "Not Dispensed"}
                            mandatory
                            name="bill_date"
                            label="Bill Date"
                            onChange={val => {
                                setFieldValue('bill_date', String(val))
                            }}
                            onBlur={e => {
                                handleBlur(e);
                            }}
                            value={values.bill_date}
                            touched={touched.bill_date}
                            errors={errors.bill_date}
                                />
                            </Grid>
                            <Grid item xs={4}>                
                            <FormControl fullWidth variant="outlined">
                            <InputLabel required htmlFor="outlined-adornment-amount">Bill Amount</InputLabel>
                            <OutlinedInput
                            disabled={Quotedata.pharma_lab_status !== "Not Dispensed"}
                                id="outlined-adornment-amount"
                                label="Bill Amount"
                                name="bill_amount"
                                value={values.bill_amount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                startAdornment={<InputAdornment position="start">{getSymbolFromCurrency(GetCurrencyCode())}</InputAdornment>}
                            />
                            </FormControl>
                            
                            {errors.bill_amount && touched.bill_amount && (
                                            <p style={{color:"red"}}>{errors.bill_amount}</p>
                                            )}
                            </Grid>
                        </Grid>

                {Quotedata.pharma_lab_status === "Not Dispensed" && <Grid container spacing={3}style={{marginTop:"5px"}}>
                <Grid item xs={12} container justify={"flex-end"} >
                    {<button style={{width:'130px'}}
                    type="submit"
                    className="btn btn-primary"
                    >
                    Process
                    </button>}
                </Grid>
                    
                </Grid>}
                        </Form>
                    </>
                )}
            </Formik>

            <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Process Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to process ?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={onSubmit}>
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>


        </>
    );
}
