
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableHead from '@material-ui/core/TableHead';
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import { useHistory } from "react-router-dom";


const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));



const useStyles2 = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

export default function CustomPaginationActionsTable(props) {
  const history = useHistory();
  const classes = useStyles2();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const rows = props.data
  const {loadPageData, user_type} = props
  const [page, setPage] = React.useState(rows.current_page - 1);

  
  const fakeData = []
  for (var i=1; i<=page*10; i++)  
  {  
    fakeData.push({
        slNo: i,
    }) 
  }  

  // console.log('rows.data', rows.data);
  const data = []
  rows.data.map((item, index) => {
    const ItemData = {...item}
    ItemData["order_amount"] = rows.order_amount
    ItemData["home_delivery"] = rows.sample_collection
  data.push({
    slNo: (page)*10+(index+1),
    item: ItemData,
}) }
  )
  // const rowData = [...fakeData, ...data];
  const rowData = data;


  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    function handleFirstPageButtonClick(event) {
      onChangePage(event, 0);
      loadPageData(1)
    }
  
    function handleBackButtonClick(event) {
      onChangePage(event, page - 1);
      loadPageData(page)
    }
  
    function handleNextButtonClick(event) {
      onChangePage(event, page + 1);
      loadPageData(page + 2)
    }
  
    function handleLastPageButtonClick(event) {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      loadPageData(Math.max(0, Math.ceil(count / rowsPerPage)))
    }
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: '#64ADED',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
  }

//   const onDelete = (id) => {
//     let newPage = page + 1
//     if (rows.data.length == 1) {
//         newPage = page
//     } else {
//         newPage = page + 1
//     }
//     deleteData(id)
//         .then(items => {
//             loadPageData(newPage)
//             if (items && items.data && items.data.message) {
//             SuccessToast('Test Deleted Successfully')
//             }
            
//         })
//         .catch(err => {
//             ErrorToast(err)
//         })
// }

// const onEdit = (values, id) => {
//   editData(values, id)
//         .then(items => {
//             loadPageData(page+1)
//             if (items && items.data && items.data.message) {
//             SuccessToast(items.data.message)
//             }
            
//         })
//         .catch(err => {
//             ErrorToast(err)
//         })
// }

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Prescription ID</StyledTableCell>
            <StyledTableCell align="center">Appointment ID</StyledTableCell>
            <StyledTableCell align="center">Request Date</StyledTableCell>
            <StyledTableCell  component="th" scope="row">Doctor</StyledTableCell>
            <StyledTableCell  align="center">Clinic</StyledTableCell>
            <StyledTableCell component="th" scope="row">Patient</StyledTableCell>
            <StyledTableCell align="center">Total Items</StyledTableCell>
            <StyledTableCell align="center">Status</StyledTableCell>
            <StyledTableCell align="center">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
          <TableBody>
            {/* {rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => ( */}
              {rowData.map((row) => (
              <TableRow key={row.item.unique_id}>
              <TableCell align="center">{row.item?.prescription?.unique_id ? row.item?.prescription?.unique_id : "NA"}</TableCell>
                <TableCell align="center">{row.item?.prescription?.appointment?.appointment_unique_id ? row.item?.prescription?.appointment?.appointment_unique_id : "NA"}</TableCell>
                <TableCell align="center">{row.item.created_at}</TableCell>
                <TableCell component="th" scope="row">
                  {row?.item?.prescription?.appointment?.doctor ? 
                  "Dr. "+[
                    row.item.prescription.appointment.doctor.first_name,
                    row.item.prescription.appointment.doctor.middle_name,
                    row.item.prescription.appointment.doctor.last_name
                  ].join(" ") : "NA"}
                </TableCell>
                <TableCell align="center">
                  {row?.item?.prescription?.appointment?.clinic_address?.clinic_name ? 
                  row.item.prescription.appointment.clinic_address.clinic_name : "NA"}
                </TableCell>
                <TableCell component="th" scope="row">
                  { row?.item?.prescription?.appointment?.current_patient_info?.info ? 
                  [row.item.prescription.appointment.current_patient_info.info.first_name, 
                  row.item.prescription.appointment.current_patient_info.info.middle_name,
                  row.item.prescription.appointment.current_patient_info.info.last_name].join(" ") : "NA"}
                  </TableCell>
                <TableCell align="center">
                  {Array.isArray(row.item.test_list[0]) ? (row.item.test_list[0].length) : (row.item.test_list.length)}
                </TableCell>
                <TableCell align="center">
                {row.item.pharma_lab_status}
                </TableCell>
                
                <TableCell align="center">
                <div className="d-flex justify-content-center">
                  <div className="p-2 col-example text-left">
                  <a
                    className="btn btn-icon btn-light btn-hover-success btn-sm"
                    onClick={() => history.push({
                      pathname: '/assosiate-dispense/view',
                      state: { detail: row.item,
                      type: 'view',
                      user_type: user_type}
                    })}
                >
                <VisibilitySharpIcon color='primary' />
            </a>
                  </div>
                </div>
                </TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 48 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={10}
                // colSpan={3}
                count={rows.total}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'Rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </Paper>
  );
}
