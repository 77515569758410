
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import CheckBox from "./CheckBox";
import DeleteModal from "./page-components/DeleteModal";
import EditModel from "./page-components/EditModel";
import getSymbolFromCurrency from 'currency-symbol-map';
import { GetCurrencyCode } from "../../../Utils/GetData";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

export default function SimpleTable(props) {
  const classes = useStyles();
  const { Quotedata, searchlist, SaveNewQuoteData, user_type } = props
  const currency_code = getSymbolFromCurrency(GetCurrencyCode())
  // console.log('Quotedata', Quotedata);

  const GetTestTotal = (totals, row) => {
    var price = row.price
    if (!price) {
        price = 0
    }
    return (parseFloat(totals) + parseFloat(price))
}

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {/* <TableCell align="right"></TableCell> */}
            <TableCell component="th" scope="row">Test Name</TableCell>
            <TableCell style={{width:'200px'}} align="right">Note</TableCell>
            <TableCell align="right" style={{width:'300px'}}>Instructions</TableCell>
            {/* {parseInt(Quotedata.status) === 1 && <TableCell align="center">Unit Price ({getSymbolFromCurrency(GetCurrencyCode())})</TableCell>} */}
            {/* {parseInt(Quotedata.status) === 0 && <TableCell align="center">Actions</TableCell>} */}
          </TableRow>
        </TableHead>
        <TableBody>
          {Quotedata.test_list[0] != null && Quotedata.test_list.map((row, index) => (
            <TableRow >
            {/* <TableCell align="center">
            <CheckBox />
            </TableCell> */}
              <TableCell component="th" scope="row">
                {row.test_name ? (row.test_name) : (row.test.name)}
              </TableCell>
              <TableCell  style={{width:'200px'}} align="right">{row.note ? row.note : "NA"}</TableCell>
              <TableCell align="right" style={{width:'300px'}}>{row.instructions ? row.instructions : "NA"}</TableCell>
              {/* {parseInt(Quotedata.status) === 1 && <TableCell align="center">
                {row?.price}
              </TableCell>} */}
              {/* {parseInt(Quotedata.status) === 0 && <TableCell align="center">
              <div className="d-flex justify-content-center">
                  <div className="p-2 col-example text-left">
                  <DeleteModal
                    Quotedata={Quotedata}
                    index={index}
                    SaveNewQuoteData={SaveNewQuoteData}
                    user_type={user_type}
                    />
                  </div>
                </div>
              </TableCell>} */}
            </TableRow>
          ))}

          {/* {parseInt(Quotedata.status) === 1 &&  Quotedata?.test &&  
            <>
            <TableRow>
            <TableCell colSpan={2} rowSpan={4} />
            <TableCell  >Sub Total :</TableCell>
            <TableCell  align="center">{currency_code} {Quotedata.test.test_list.reduce((totals, row) => GetTestTotal(totals, row), 0)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell  >Home Sample Collection Charges :</TableCell>
            <TableCell  align="center">{currency_code} {Quotedata.test.delivery_charge}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell >Discount :</TableCell>
            <TableCell  align="center">{currency_code} {Quotedata.test.discount}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell >Total</TableCell>
            <TableCell align="center">{currency_code} {Quotedata.test.total}</TableCell>
          </TableRow>
          </>
          } */}
        </TableBody>
      </Table>
    </Paper>
  );
}
