import React, { useState, useMemo } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { SendEmailAndMobileOtp, VerifyMobileAndMobileOtp } from "../../Apis/Auth/ForgetPasswordApi";
import { ErrorToast } from "../../Toast/ErrorSnakBar";
import { SuccessToast } from "../../Toast/SuccessSnackBar";
import Alert from '@material-ui/lab/Alert';
import { useHistory } from "react-router-dom";
import {Timer} from "../../../Utils/Components/Timer";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';


function Registration(props) {
  const history = useHistory();
  const [disableEmailSend, setDisableEmailSend] = useState(true);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  
  const otp6 = new RegExp(/^[0-9]+$/)
  const passwordRegex = new RegExp(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&.])/)

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues : {
      email_or_mobile: '',
    },
    validationSchema: Yup.object().shape({
      email_or_mobile: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Email field is required"),
    }),
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setEmail(values.email_or_mobile)
      setSubmitting(true);
      enableLoading();
      SendEmailAndMobileOtp(values)
        .then((data) => {
          if (data && data.data && data.data.message) {
            SuccessToast(data.data.message)
          }
          setDisableEmailSend(false)
          disableLoading();
          setSubmitting(false);
        })
        .catch((err) => {
            ErrorToast(err)
          setSubmitting(false);
          disableLoading();
        });
    },
  });

  const formik2 = useFormik({
    initialValues : {
      // email_or_mobile: email,
      otp : '',
      password : '',
      password_confirmation : ''
    },
    validationSchema: Yup.object().shape({
      // email_or_mobile: Yup.string()
      //   .email("Wrong email format")
      //   .min(3, "Minimum 3 symbols")
      //   .max(50, "Maximum 50 symbols")
      //   .required("Email field is required"),
      otp: Yup.string()
      .nullable()
      .matches(otp6,"Must be a valid number")
      .length(6, "Must be a 6 digit")
      .required('The email OTP field is required.'),
      password: Yup.string()
        .min(8, 'Must be 8 characters or more.')
        .max(20, 'Minimum length of 20 characters.')
        .matches(passwordRegex, "Include at least  one upper case, lower case, numbers and symbols.")
        .required('The password field is required.'),
      password_confirmation: Yup.string()
        .min(8, 'Must be 8 characters or more.')
        .max(20, 'Minimum length of 20 characters.')
        .matches(passwordRegex, "Include at least  one upper case, lower case, numbers and symbols.")
        .required('The password field is required.')
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Password and Confirm Password didn't match"
          ),
        }),
    }),
    onSubmit: (values, { setStatus, setSubmitting }) => {
      values["email_or_mobile"] = email
      setSubmitting(true);
      enableLoading();
      VerifyMobileAndMobileOtp(values)
        .then((data) => {
          if (data && data.data && data.data.message) {
            SuccessToast(data.data.message)
          }
          history.push({
            pathname: '/auth/login'
          })
          disableLoading();
          setSubmitting(false);
        })
        .catch((err) => {
            ErrorToast(err)
          setSubmitting(false);
          disableLoading();
        });
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          {/* <FormattedMessage id="AUTH.REGISTER.TITLE" /> */}
          Forgot Password?
        </h3>
        {/* <p className="text-muted font-weight-bold">
          Verify OTP
        </p> */}
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Email */}
        {<div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6`}
            name="email_or_mobile"
            {...formik.getFieldProps("email_or_mobile")}
          />
          {formik.touched.email_or_mobile && formik.errors.email_or_mobile ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email_or_mobile}</div>
            </div>
          ) : null}
        </div>}
        {/* end: Email */}

        {!disableEmailSend && <Timer 
        id='email'
            setDisable={setDisableEmailSend}
            />}
            
            <div class="text-right">
            <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              !disableEmailSend
            }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Send Email OTP</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
            </div>
      </form>



      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik2.handleSubmit}
      >
        {<div className="form-group fv-plugins-icon-container">


          <div style={{marginTop:'20px'}} className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email OTP"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "otp"
            )}`}
            name="otp"
            {...formik2.getFieldProps("otp")}
          />
          {formik2.touched.otp && formik2.errors.otp ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik2.errors.otp}</div>
            </div>
          ) : null}
        </div>
        </div>}
        {/* end: Email */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <TextField
              fullWidth
              id="outlined-adornment-password"
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              autocomplete="new-password"
              placeholder="New Password"
              name="password"
              value={formik2.values.password}
              onChange={formik2.handleChange}
              onBlur={formik2.handleBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="Toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formik2.touched.password && formik2.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik2.errors.password}</div>
              </div>
            ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <TextField
              fullWidth
              id="outlined-adornment-Confirm-password"
              variant="outlined"
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder="Confirm New Password"
              name="password_confirmation"
              value={formik2.values.password_confirmation}
              onChange={formik2.handleChange}
              onBlur={formik2.handleBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="Toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          {formik2.touched.password_confirmation && formik2.errors.password_confirmation ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik2.errors.password_confirmation}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}
            
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={
              formik2.isSubmitting ||
              !formik2.isValid 
            }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Change Password</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
