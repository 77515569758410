import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MaterialSelect from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function Select (props) {
  const { disabled = false, margin, none = false, onChange = () => {}, onBlur = () => {}, value, label, name, options} = props
  const classes = useStyles();

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="outlined-age-native-simple">{label}</InputLabel>
        <MaterialSelect
          native
          disabled={disabled}
          margin={margin}
          value={value}
          onChange={event => {
            onChange(event.target.value)
        }}
          onBlur={onBlur}
          label={label}
          name={name}
        >
          {none && <option value=" ">All</option>}
          {options && 
          options.map((item) => <option value={item.value}>{item.label}</option>)
          }
        </MaterialSelect>
      </FormControl>
    </div>
  );
}
