import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";
import List from "./List"
import { View } from "./View";
import { connect } from 'react-redux';

const QuoteRequest = (props) => {
  const user_type = props && props.me && props.me.user_type
  var title
  if (user_type == "PHARMACIST") {
    title = "Quote Requests"
  }
  if (user_type == "LABORATORY") {
    title = "Test Quote Requests"
  }

  const suhbeader = useSubheader();
  suhbeader.setTitle(title);

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/quote/request"
        to="/quote/request/list"
      />
      <ContentRoute from="/quote/request/list" component={List} />
      <ContentRoute from="/quote/request/view" component={View} />
    </Switch>
  );
};
const mapStateToProps = function (state) {
  return {
    me: state.me,
  }
}

export default connect(mapStateToProps)(QuoteRequest);
