import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import QuoteRequest from "./pages/QuoteRequest/QuoteRequest";
import Dispense from "./pages/Dispense/Dispense";
import DispenseFromAssosiate from "./pages/DispenseFromAssosiate/DispenseFromAssosiate";
import { UserTypes } from "./pages/user-types";
import { DashboardPage } from "./pages/DashboardPage";
import {PharmacyProfilePage} from "./pages/Pharmacy/Profile/View";
import {LaboratoryProfilePage} from "./pages/Laboratory/Profile/View";
import PaymentAndPayout from "./pages/PaymentAndPayout/PaymentAndPayout";

import { Test } from "./pages/Laboratory/Test";
import { ChangePassword } from "./pages/ChangePassword";

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard" />
                }
                <ContentRoute path="/dashboard" component={DashboardPage} />
                <ContentRoute path="/quote/request" component={QuoteRequest} />
                <ContentRoute path="/dispense" component={Dispense} />
                <ContentRoute path="/assosiate-dispense" component={DispenseFromAssosiate} />
                <ContentRoute path="/change-password" component={ChangePassword} />
                <ContentRoute path="/user-types" component={UserTypes} />
                <ContentRoute path="/pharmacy/profile" component={PharmacyProfilePage} />
                <ContentRoute path="/laboratory/profile" component={LaboratoryProfilePage} />
                <ContentRoute path="/test" component={Test} />
                <ContentRoute path="/payment-payout" component={PaymentAndPayout} />
                <Redirect to="error/error-v1" />
            </Switch>
        </Suspense>
    );
}
