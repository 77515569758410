import React, {Component, useEffect} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";
import { meAction } from "./../../../../redux";
import { useDispatch } from 'react-redux';

const Logout = (props) => {
  const dispatch = useDispatch();

  useEffect(() =>{
    props.logout();
    dispatch(meAction(null))
  });
  const { hasAuthToken } = props;
  return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);
