
import React from 'react';
import TextField from '@material-ui/core/TextField';


export default function Text(props) {
  const {row, rows, index, Quotedata, SaveNewQuoteData} = props
  const [values, setValues] = React.useState({
    value: row.quantity,
  });

  const numberandfullRex = /^\d*\.?\d*$/
  const handleChange = name => event => {
    if (numberandfullRex.test(event.target.value)) {
      setValues({ ...values, [name]: event.target.value });
      row['quantity'] = event.target.value
      rows[index] = row
      Quotedata['medicine_list'] = rows
      SaveNewQuoteData(Quotedata)
    }
  };

  return (
    <form>
      <TextField
        style={{width:'100px'}}
        id="outlined-name"
        value={values.value}
        onChange={handleChange('value')}
        margin="dense"
        variant="outlined"
        // type="number"
      />
    </form>
  );
}
