import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { Form } from "./Form";
import { MedicineData, TestData } from "../../modules/Apis/Pharmacy/Quotes/QuotesApi";
import {ErrorToast} from "../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../modules/Toast/SuccessSnackBar";
import Spinner from "../../Utils/Components/CustomSpinner";

export const View = (props) => {
  const [Quotedata, setQuotedata] = useState(props.location.state.detail);
  const [searchlist, setSearchList] = React.useState()
  const [mounted, setMounted] = React.useState(true)
  const [loading, setLoading] = React.useState(true)
  const user_type = props.location.state.user_type

  const SaveNewQuoteData = (data) => {
    setQuotedata({...data})
  }

  const backToStoresList = () => props.history.goBack()
  const saveStore = () => { }
  const btnRef = useRef();
  const resetbtnRef = useRef()
  const saveStoreClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };
  const resetForm = () => {
    if (resetbtnRef && resetbtnRef.current) {
      resetbtnRef.current.click()
    }
  }

  const loadMedicineData = () => {
    MedicineData()
      .then(items => {
        setLoading(false)
        setSearchList(items.data)
      })
      .catch(err => {
        setLoading(false)
        ErrorToast(err)
    })
  }

  const loadTestData = () => {
    TestData()
      .then(items => {
        setLoading(false)
        setSearchList(items.data)
      })
      .catch(err => {
        setLoading(false)
        ErrorToast(err)
    })
  }


  React.useEffect(() => {
    if (user_type == "PHARMACIST") {
      if (mounted) {
        loadMedicineData()
        setMounted(false)
      }
    }
    if (user_type == "LABORATORY") {
      if (mounted) {
        loadTestData()
        setMounted(false)
      }
    }
  }, [loadMedicineData, loadTestData])

  if (loading) {
    return (
      <Spinner />
    )
  }

  return (
    <Card>
      <CardHeader title={`Quote Request - ${Quotedata && Quotedata.unique_id && Quotedata.unique_id}`}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToStoresList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
              Back To List
            </button>
          
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          <Form
            Quotedata={Quotedata}
            searchlist={searchlist}
            SaveNewQuoteData={SaveNewQuoteData}
            user_type={user_type}
          />
        </div>
      </CardBody>
    </Card>
  )
};
