// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import { useHistory } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map';
import { GetCurrencyCode } from "../../../Utils/GetData";
import TextField from '@material-ui/core/TextField';

const numberandfullRex = /^[0-9]\d*(\.\d+)?$/
// Validation schema


const useStyles = makeStyles(theme => ({
    label: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center'
      },
    }));

export default function StoreEditForm(props) {
    const {total, user_type, previousStep, onClickAddPharmacyQuote, onClickAddLaboratoryQuote, Quotedata} = props
    const history = useHistory();
    const classes = useStyles();
    const tempTotal = total

    var delivery_label 
    const home_delivery = Quotedata.home_delivery
    const order_amount = Quotedata.order_amount

    if (user_type === "PHARMACIST") {
        delivery_label = "Home Delivery Charge"
    }
    if (user_type === "LABORATORY") {
        delivery_label = "Home Sample Collection Charges"
    }




    const initialValues = {
        total : total,
        discount : '',
        delivery_charge : '',
        valid_till : ''
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    discount: Yup.string()
                        // .required("Discount is required")
                        .matches(numberandfullRex, 'Invalid Data!')
                        .nullable()
                        .test('match', 
                        'Discount should be less than the total price!', 
                        function(value) { 
                            if (!value) return true
                            return value < total; 
                        }),
                    delivery_charge: Yup.string()
                        // .required(`${delivery_label} is required`)
                        .matches(numberandfullRex, 'Invalid Data!')
                        .nullable(),
                    valid_till: Yup.date('Must be a valid date')
                    .nullable()
                    .min(new Date(), 'Quote valid till should be a future date' )
                    .required('Quote valid till field is required.'),
                })}
                onSubmit={(values) => {
                    // console.log('values', values);
                    const data = {...values}
                    if (!values.discount) {
                        data["discount"] = 0
                    }
                    if (!values.delivery_charge) {
                        data["delivery_charge"] = 0
                    }
                    if (user_type == "PHARMACIST") {
                        onClickAddPharmacyQuote(data)
                    }
                    if (user_type == "LABORATORY") {
                        onClickAddLaboratoryQuote(data)
                    }
                }
    }
            >
                {({ handleSubmit, setFieldValue, resetForm, handleChange, handleBlur, touched, values, errors }) => (
                    <>
                        <Form className="form form-label-right">
                        <Grid container spacing={3} style={{marginTop:"5px"}}>
                <Grid item xs={7}>
                </Grid>
                <Grid item xs={3}>
            <TextField
              fullWidth
              required
                label="Quote valid till"
                name="valid_till"
                type="date"
                value={values.valid_till}
                variant="outlined"
                onChange={e => {
                  if (new Date("9999-12-31") >= new Date(e.target.value)) {
                    handleChange(e);
                  }
                }}
                InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={handleBlur}
                  />
                    
                    {errors.valid_till && touched.valid_till && (
                                    <p style={{color:"red"}}>{errors.valid_till}</p>
                                    )}
                </Grid>
                <Grid item xs={2}>
                </Grid>
                </Grid>
                <Grid container spacing={3} style={{marginTop:"5px"}}>
                <Grid item xs={7}>
                </Grid>
                <Grid item xs={3}>
                
                    <FormControl fullWidth className={classes.margin} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount">{delivery_label}</InputLabel>
                    <OutlinedInput
                        disabled={parseInt(home_delivery) === 0} // || parseFloat(order_amount) < total
                        id="outlined-adornment-amount"
                        label={delivery_label}
                        name="delivery_charge"
                        value={values.delivery_charge}
                        onBlur={e => {
                            handleBlur(e)
                        }}
                        onChange={(e) => {
                            if (e.target.value >= 0 && e.target.value != '') {
                                setFieldValue("delivery_charge", e.target.value)
                                if (values.discount) {
                                    setFieldValue("total", (parseFloat(tempTotal) + parseFloat(e.target.value)) - parseFloat(values.discount))
                                } else {
                                    setFieldValue("total", parseFloat(tempTotal) + parseFloat(e.target.value))
                                }
                            } else {
                                setFieldValue("delivery_charge", '')
                                if (values.discount) {
                                    setFieldValue("total", (parseFloat(tempTotal) - parseFloat(values.discount)))
                                } else {
                                    setFieldValue("total", parseFloat(tempTotal))
                                }
                            }
                        }}
                        startAdornment={<InputAdornment position="start">{getSymbolFromCurrency(GetCurrencyCode())}</InputAdornment>}
                    />
                    </FormControl>
                    
                    {errors.delivery_charge && touched.delivery_charge && (
                                    <p style={{color:"red"}}>{errors.delivery_charge}</p>
                                    )}
                </Grid>
                <Grid item xs={2}>
                </Grid>
            </Grid>   

                <Grid container spacing={3} style={{marginTop:"5px"}}>
                    <Grid item xs={7}>
                    </Grid>
                    <Grid item xs={3}>
                    
                        <FormControl fullWidth className={classes.margin} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-amount">Discount</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            label="Discount"
                            name="discount"
                            value={values.discount}
                            onBlur={e => {
                                handleBlur(e)
                            }}
                            onChange={(e) => {
                                if (e.target.value >= 0 && e.target.value != '') {
                                    setFieldValue("discount", e.target.value)
                                    if (values.delivery_charge) {
                                        setFieldValue("total", (parseFloat(tempTotal) + parseFloat(values.delivery_charge)) - parseFloat(e.target.value))
                                    } else {
                                        setFieldValue("total", parseFloat(tempTotal) - parseFloat(e.target.value))
                                    }
                                } else {
                                    setFieldValue("discount", '')
                                    if (values.delivery_charge) {
                                        setFieldValue("total", (parseFloat(tempTotal) + parseFloat(values.delivery_charge)))
                                    } else {
                                        setFieldValue("total", parseFloat(tempTotal))
                                    }
                                }
                            }}
                            startAdornment={<InputAdornment position="start">{getSymbolFromCurrency(GetCurrencyCode())}</InputAdornment>}
                        />
                        </FormControl>
                        {errors.discount && touched.discount && (
                                    <p style={{color:"red"}}>{errors.discount}</p>
                                    )}
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                </Grid>  

                <Grid container spacing={3}style={{marginTop:"5px"}}>
                <Grid item xs={4} container justify={"flex-start"}>
                    <button style={{width:'130px'}}
                    className="btn btn-primary"
                    onClick={previousStep}
                    >
                    Back
                    </button>
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={3}>
                    
                        <FormControl fullWidth className={classes.margin} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-amount">Total</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            disabled
                            label="Total"
                            value={values.total}
                            startAdornment={<InputAdornment position="start">{getSymbolFromCurrency(GetCurrencyCode())}</InputAdornment>}
                        />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2} container justify={"flex-end"} >
                    {<button style={{width:'130px'}}
                    type="submit"
                    className="btn btn-primary"
                    >
                    SEND QUOTE
                    </button>}
                    </Grid>
                </Grid>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
