import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
// import { EditForm } from "./EditForm";
import EditForm from './Form'
import {ProfileData} from "../../../modules/Apis/Laboratory/Profile/Profile";
import Spinner from "../../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";

export default function App (props) {
  const [data, setData] = React.useState(undefined)
  const [mounted, setMounted] = React.useState(true)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [loading, setLoading] = React.useState(true)

  const loadData = () => {
    if (mounted) {
      ProfileData()
      .then(items => {
        setMounted(false)
        setLoading(false)
        console.log(items.data)
        setData(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
        setLoading(false)
    })
    }
  }

  const updateData = (data) => {
    setData(data)
  }

  const loadSaveData = () => {
    setData(undefined)
    ProfileData()
      .then(items => {
        setLoading(false)
        updateData(items.data)
      })
      .catch(err => {
        setLoading(false)
        ErrorToast(err)
    })
  }

  

  React.useEffect(() => {
    loadData()
  }, [loadData])

  

  const backToProductsList = () => props.history.goBack()
  const saveProduct = () => { }
  const btnRef = useRef();
  const resetbtnRef = useRef()
  const saveProductClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
      
    }
  };
  const resetForm = () => {
    if(resetbtnRef && resetbtnRef.current){
      resetbtnRef.current.click()
    }
  }

  if (loading) {
    return (
      <Spinner />
    )
  }
  return (
    <Card>
      <CardHeader title="Laboratory Info">
        <CardHeaderToolbar>
          {/* <button
            type="button"
            onClick={backToProductsList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
              Back
            </button>
          {`  `}
          <button 
            className="btn btn-light ml-2" 
            onClick={resetForm}>
            <i className="fa fa-redo"></i>
              Reset
            </button>
          {`  `} */}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveProductClick}
          >
            Save
            </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          {errorMessage}
          {data && <EditForm
            // actionsLoading={actionsLoading}
            setLoading={setLoading}
            data={data}
            btnRef={btnRef}
            resetbtnRef={resetbtnRef}
            saveProduct={saveProduct}
            loadSaveData={loadSaveData}
          >
          </EditForm>}
        </div>
      </CardBody>
    </Card>
  )
};
