import React, { useState, useEffect } from 'react'
import { useParams, Link } from "react-router-dom";
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Stepper from '@material-ui/core/Stepper';

import './styles.css';
import PharmacyDetails from './PharmacySteps/PharmacyDetails';
import PharmacyAddress from './PharmacySteps/PharmacyAddress';
import PharmacistDetails from './PharmacySteps/PharmacistDetails';
import LabDetails from './LaboratorySteps/LabDetails';
import LabAddress from './LaboratorySteps/LabAddress';
import LabTests from './LaboratorySteps/LabTests';
import LabBankDetails from './LaboratorySteps/LabBankDetails';
import { logOut } from "../../Logout";

const PharmacyAndLabRegister = ({ currentStep = 0 }) => {
    useEffect(() => {
      logOut()
    }, [logOut])
    const [activeStep, setActiveStep] = useState(currentStep);
    const { type } = useParams();
    const [userType] = useState(type || 'pharmacy');
    const stepsForRegistration = {
        pharmacy: ['Pharmacy Details', ' Pharmacy Address', 'Pharmacist Details'],
        laboratory: ['Laboratory Details', ' Laboratory Address', 'List of tests', 'Bank Details']
    }
    const steps = stepsForRegistration[userType];

    const handleStep = (step) => () => {
        setActiveStep(step);
    };
    return (
        <div className="w-100">
            <div className="w-100 d-flex justify-content-end font-weight-bold">
                <p>Already have an account? <Link to="/auth/login">Click here to Login</Link></p>
            </div>
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={label} disabled>
                        <StepButton
                            onClick={
                                handleStep(index)
                            }
                        >
                            {label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            <div className="registration-steps">
                {userType === 'pharmacy' ? (
                    <>
                        {activeStep === 0 && <PharmacyDetails onSubmit={handleStep(1)} />}
                        {activeStep === 1 && <PharmacyAddress onSubmit={handleStep(2)} />}
                        {activeStep === 2 && <PharmacistDetails />}
                    </>
                ) : <></>}
                {userType === 'laboratory' ? (
                    <>
                        {activeStep === 0 && <LabDetails onSubmit={handleStep(1)} />}
                        {activeStep === 1 && <LabAddress onSubmit={handleStep(2)} />}
                        {activeStep === 2 && <LabTests onSubmit={handleStep(3)} />}
                        {activeStep === 3 && <LabBankDetails />}
                    </>
                ) : <></>}
            </div>
        </div>
    )
}

export default PharmacyAndLabRegister;