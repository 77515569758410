import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
// import {ProfileData} from "../../../modules/Apis/Pharmacy/Profile/Profile";
import Spinner from "../../Utils/Components/CustomSpinner";
import { Select, Search } from "../../Components";
import Table from "./Table";
import { LoadMedicineData, LoadLaboratoryData } from "../../modules/Apis/Dispense/DispenseApi";
import {ErrorToast} from "../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../modules/Toast/SuccessSnackBar";
import { connect } from 'react-redux';

const List = (props) => {
  const user_type = props && props.me && props.me.user_type
  var title
  if (user_type == "PHARMACIST") {
    title = "Medicine Orders"
  }
  if (user_type == "LABORATORY") {
    title = "Test Orders"
  }
  const [mounted, setMounted] = React.useState(true)
  const [loading, setLoading] = React.useState(true)
  const [quoteList, setQuoteList] = React.useState()
 
  const loadPharmacyMedicineData = (data) => {
    LoadMedicineData(data)
      .then(items => {
        setLoading(false)
        // console.log('items.data', items.data);
        setQuoteList(items.data)
      })
      .catch(err => {
        setLoading(false)
        setQuoteList(undefined)
        ErrorToast(err)
    })
  }

  const loadLaboratoryQuoteData = (data) => {
    LoadLaboratoryData(data)
      .then(items => {
        setLoading(false)
        // console.log('items.data', items.data);
        setQuoteList(items.data)
      })
      .catch(err => {
        setLoading(false)
        setQuoteList(undefined)
        ErrorToast(err)
    })
  }

  const loadPageData = (no) => {
    const data = {
      no:no
    }
    if (user_type == "PHARMACIST") {
      loadPharmacyMedicineData(data)
    }
    
    if (user_type == "LABORATORY") {
      loadLaboratoryQuoteData(data)
    }
  }

  

  

  
  React.useEffect(() => {
    const data = {
      no:1
    }
    if (user_type == "PHARMACIST") {
      if (mounted) {
        loadPharmacyMedicineData(data)
        setMounted(false)
      }
    }
    if (user_type == "LABORATORY") {
      if (mounted) {
        loadLaboratoryQuoteData(data)
        setMounted(false)
      }
    }
  }, [loadPharmacyMedicineData, loadLaboratoryQuoteData])

  

  const backToProductsList = () => props.history.goBack()
  const saveProduct = () => { }
  const btnRef = useRef();
  const resetbtnRef = useRef()
  const saveProductClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
      
    }
  };
  const resetForm = () => {
    if(resetbtnRef && resetbtnRef.current){
      resetbtnRef.current.click()
    }
  }

  if (loading) {
    return (
      <Spinner />
    )
  }

  return (
    <Card>
      <CardHeader title={title}>
        <CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader>
        
      <CardBody>
        <div className="mt-5">
          {quoteList && <Table 
          loadPageData={loadPageData}
          data={quoteList}
          user_type={user_type}
          />}
        </div>
      </CardBody>
    </Card>
  )
};

const mapStateToProps = function (state) {
  return {
    me: state.me,
  }
}

export default connect(mapStateToProps)(List);
