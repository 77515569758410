import React from "react";
import { ViewCard } from "./ViewCard";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import PharmacyTable from "./PharmacyTable";
import LaboratoryTable from "./LaboratoryTable";
import { useHistory } from "react-router-dom";
import Spinner from "../../../Utils/Components/CustomSpinner";
import { LoadNewMedicineData, LoadNewLaboratoryData } from "../../../modules/Apis/Pharmacy/Quotes/QuotesApi";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";


const useStyles = makeStyles(theme => ({
    label: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center'
      },
    }));



export default function ShowForm (props) {
    const history = useHistory();
    const classes = useStyles();
    const { searchlist, SaveNewQuoteData, nextStep, user_type} = props
    const [ errorMessage, setErrorMessage ] = React.useState(<p></p>)
    const [ mount, setMount ] = React.useState(true)
    const [loading, setLoading] = React.useState(true)
    const [mounted, setMounted] = React.useState(true)
    const [Quotedata, setQuotedata] = React.useState(props.Quotedata)

    // console.log('Quotedata', Quotedata.medicine_list);


    const loadMedicineData = (id) => {
        LoadNewMedicineData(id)
          .then(items => {
            setLoading(false)
            Quotedata['medicine_list'] = items.data.medicine.medicine_list
            Quotedata['medicine'] = items.data.medicine
            Quotedata['valid_till'] = items.data.valid_till
            setQuotedata({...Quotedata})
            setLoading(false)
          })
          .catch(err => {
            setLoading(false)
            ErrorToast(err)
        })
      }
    
      const loadTestData = (id) => {
        LoadNewLaboratoryData(id)
          .then(items => {
            Quotedata['test_list'] = items.data.test.test_list
            Quotedata['test'] = items.data.test
            Quotedata['valid_till'] = items.data.valid_till
            setQuotedata({...Quotedata})
            setLoading(false)
          })
          .catch(err => {
            setLoading(false)
            ErrorToast(err)
        })
      }

    // if (mount) {
    //     if (user_type == "PHARMACIST") {
    //         if (Array.isArray(props.Quotedata.medicine_list[0])) {
    //             Quotedata['medicine_list'] = props.Quotedata.medicine_list[0]
    //         }
    //       }
    //     if (user_type == "LABORATORY") {
    //         if (Array.isArray(props.Quotedata.test_list[0])) {
    //             Quotedata['test_list'] = props.Quotedata.test_list[0]
    //         }
    //     }
    //       setMount(false)
    // }

    var onClickNext

    if (user_type == "PHARMACIST") {
    onClickNext = () => {
        const data = {...Quotedata}
        var medlist = []
        if (data.medicine_list[0]!= null) {
        data.medicine_list.map((item) => {
            item['unit'] = 1
            medlist.push(item)
        })
        if (medlist.length == 0) {
            setErrorMessage(<Alert severity="error">Atleast one medicine is needed!</Alert>)
        } else {
        data['medicine_list'] = medlist
        SaveNewQuoteData(data)
        nextStep()
        }
    } else {
        setErrorMessage(<Alert severity="error">Atleast one medicine is needed!</Alert>)
    }
    }
}
if (user_type == "LABORATORY") {
    onClickNext = () => {
        const data = {...Quotedata}
        // var medlist = []
        // data.medicine_list.map((item) => {
        //     item['unit'] = 1
        //     medlist.push(item)
        // })
        if (data.test_list.length == 0) {
            setErrorMessage(<Alert severity="error">Atleast one Test is needed!</Alert>)
        } else {
        // data['medicine_list'] = medlist
        // SaveNewQuoteData(data)
        nextStep()
        }
    }
}

React.useEffect(() => {
    const id = Quotedata.id
    if (user_type == "PHARMACIST") {
      if (mounted) {
          if (parseInt(Quotedata.status) === 1) {
            loadMedicineData(id)
          }
        setMounted(false)
      }
    }
    if (user_type == "LABORATORY") {
      if (mounted) {
        if (parseInt(Quotedata.status) === 1) {
            loadTestData(id)
        }
        setMounted(false)
      }
    }
    setLoading(false)
  }, [loadMedicineData, loadTestData])

  if (loading) {
    return (
      <Spinner />
    )
  }

    return (
        <div>
            <ViewCard Quotedata={Quotedata} />

            
                {user_type == "PHARMACIST" && <PharmacyTable Quotedata={Quotedata} 
                    searchlist={searchlist}  
                    SaveNewQuoteData={SaveNewQuoteData}
                    user_type={user_type}
                    />}
                {user_type == "LABORATORY" && <LaboratoryTable Quotedata={Quotedata} 
                    searchlist={searchlist}  
                    SaveNewQuoteData={SaveNewQuoteData}
                    user_type={user_type}
                    />}

                {errorMessage}

                {parseInt(Quotedata.status) === 0 && <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
                    <Grid item xs={9}>
                    </Grid>
                    <Grid item xs={3} container justify={"flex-end"}>
                    <button
                    type="submit"
                    className="btn btn-primary ml-2"
                    onClick={onClickNext}
                    >
                    GENERATE QUOTE +
                    </button>
                    </Grid>
                </Grid>}
        </div>
    )
}