import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import PaymentList from "./Payment/List"
import PayoutList from "./Payout/List"
import PayoutView from "./Payout/PayOutView/View"
import { ContentRoute } from "../../../_metronic/layout";

export default function PaymentAndPayout (props) {

  const suhbeader = useSubheader();
  suhbeader.setTitle("Payments and Payouts");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/payment-payout"
        to="/payment-payout/payment"
      />
      <ContentRoute from="/payment-payout/payment" component={PaymentList} />
      <ContentRoute from="/payment-payout/payout" component={PayoutList} />
      <ContentRoute from="/payment-payout/payout-view" component={PayoutView} />
    </Switch>
  );
};
