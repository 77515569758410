export const MenuItems = [
    {
        path: 'dashboard',
        label: 'Dashboard',
        hasSubMenu: false,
        iconSvg: "/media/svg/icons/Design/Layers.svg",
        subMenuItems: [],
        accessTypes: ['PHARMACIST','LABORATORY']
    },
    {
        path: 'pharmacy/profile',
        label: 'View and Edit Profile',
        hasSubMenu: false,
        iconSvg: "/media/svg/icons/Design/Layers.svg",
        subMenuItems: [],
        accessTypes: ['PHARMACIST']
    },
    {
        path: 'laboratory/profile',
        label: 'View and Edit Profile',
        hasSubMenu: false,
        iconSvg: "/media/svg/icons/Design/Layers.svg",
        subMenuItems: [],
        accessTypes: ['LABORATORY']
    },
    {
        path: 'quote/request',
        label: 'Quote Requests',
        hasSubMenu: false,
        iconSvg: "/media/svg/icons/Files/File.svg",
        subMenuItems: [],
        accessTypes: ['PHARMACIST']
    },
    {
        path: 'quote/request',
        label: 'Test Quote Requests',
        hasSubMenu: false,
        iconSvg: "/media/svg/icons/Files/File.svg",
        subMenuItems: [],
        accessTypes: ['LABORATORY']
    },
    {
        path: 'dispense',
        label: 'Medicine Orders',
        hasSubMenu: false,
        iconSvg: "/media/svg/icons/Files/File.svg",
        subMenuItems: [],
        accessTypes: ['PHARMACIST']
    },
    {
        path: 'dispense',
        label: 'Test Orders',
        hasSubMenu: false,
        iconSvg: "/media/svg/icons/Files/File.svg",
        subMenuItems: [],
        accessTypes: ['LABORATORY']
    },
    {
        path: 'assosiate-dispense',
        label: 'Medicine Dispense Requests',
        hasSubMenu: false,
        iconSvg: "/media/svg/icons/Files/File.svg",
        subMenuItems: [],
        accessTypes: ['PHARMACIST']
    },
    {
        path: 'assosiate-dispense',
        label: "Doctor's Test Requests",
        hasSubMenu: false,
        iconSvg: "/media/svg/icons/Files/File.svg",
        subMenuItems: [],
        accessTypes: ['LABORATORY']
    },
    {
        path: 'payment-payout',
        label: 'Payments and Payouts',
        hasSubMenu: true,
        iconSvg: "/media/svg/icons/Shopping/Money.svg",
        subMenuItems: [{
            path: 'payment',
            label: 'Payments',
            hasSubMenu: false,
            accessTypes: ['PHARMACIST', 'LABORATORY']
        }, {
            path: 'payout',
            label: 'Payouts',
            hasSubMenu: false,
            accessTypes: ['PHARMACIST', 'LABORATORY']
        }],
        accessTypes: ['PHARMACIST', 'LABORATORY']
    },
    {
        path: 'test',
        label: 'Test',
        hasSubMenu: true,
        iconSvg: "/media/svg/icons/Shopping/Bag2.svg",
        subMenuItems: [{
            path: 'list',
            label: 'List',
            hasSubMenu: false,
            accessTypes: ['LABORATORY']
        }, {
            path: 'add',
            label: 'Add',
            hasSubMenu: false,
            accessTypes: ['LABORATORY']
        }],
        accessTypes: ['LABORATORY']
    },
    {
        path: 'change-password',
        label: 'Account Settings',
        hasSubMenu: false,
        iconSvg: "/media/svg/icons/Files/File.svg",
        subMenuItems: [],
        accessTypes: ['PHARMACIST', 'LABORATORY']
    },
]