import { Button, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import React, { useState } from 'react';
import ButtonWithSpinner from './ButtonWithSpinner';
import TextField from '@material-ui/core/TextField';
import { checkUsername, changeUsername } from '../../../../../../app/modules/Apis/Auth/AccountApi';
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import {ErrorToast} from "../../../../../../app/modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../../../../app/modules/Toast/SuccessSnackBar";

const validationS = Yup.object({
    username: Yup.string()
    .nullable()
    .max(15, 'Must be 15 characters or less.')
    .required('The username field is required.'),
    })

export default function Add({ color, userName, updateUsername, onSuccess = () => { }, varient = "outline-primary", className = "mr-1", size = "sm" }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  
  function isUserValid(value, setFieldError){
    checkUsername(value).catch(err => {
        let message = 'The username has already been taken.';
        try {
            message = err.response.data.errors.username[0];
        } catch (e) {
            console.error(e)
        }
        setFieldError('username', message)
    })
}
  
const initialValues = {
    username: userName,
}
  return (
    <>
    {/* <Button variant="link" style={{color:color}} onClick={handleShow}>{userName}</Button> */}
    <Link onClick={handleShow}>{userName}</Link>

      <Formik
        initialValues={initialValues}
        validationSchema={validationS}
        onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
          setSubmitting(true);
          changeUsername(values)
          .then(items => {
            updateUsername(values.username)
            SuccessToast(items.data.message)
            setSubmitting(false)
            handleClose();
            onSuccess();
            resetForm();
          })
          .catch(err => {
            ErrorToast(err)
            setSubmitting(false)
        })
        }}
      >{({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        setFieldError,
        handleReset,
        isSubmitting,
        isValid,
        resetForm,
        dirty
        /* and other goodies */
      }) => (
          <Modal show={show} onHide={() => {
            resetForm();
            handleClose();
          }}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Username</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              {/** row 2*/}
              <div className="row form-row">
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                      <TextField
                          fullWidth
                          required
                          label="Username"
                          name="username"
                          type="text"
                          value={values.username}
                          variant="outlined"
                          onChange={e => {
                            setFieldTouched('username', true)
                            setFieldValue("username", e.target.value)
                            isUserValid(e.target.value, setFieldError)
                          }}
                          onBlur={e => {
                            handleBlur('username', e.target.value || '');
                            isUserValid(e.target.value, setFieldError)
                          }}
                          />
                          {errors.username && touched.username && (
                          <p style={{color:"red"}}>{errors.username}</p>
                          )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-default" onClick={() => {
                handleReset();
                handleClose();
              }}>Close</button>
              <ButtonWithSpinner
                onClick={handleSubmit}
                isLoading={isSubmitting}
                disabled={isSubmitting || !isValid || !dirty}
                variant="primary"
                type="submit">Save Changes</ButtonWithSpinner>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
}
