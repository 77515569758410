// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {FileUpload} from "../../../Utils/Components/FileUpload/FileUpload";
import TextField from '@material-ui/core/TextField';
import { DatePickerField } from "./../../../../_metronic/_partials/controls/forms/DatePickerField";
import { login } from "../../../modules/Auth/_redux/authCrud";

const FILE_SIZE = 1024 * 1024 * 2;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
//   "application/msword",
//   "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const validationSchema = Yup.object().shape({
    delivery_status: Yup.string()
    .required("Delivery Status is Required!"),
    delivery_info: Yup.mixed().when('delivery_status', {
        is: 'In-Progress',
        then: Yup.mixed()
        // .required('Test report is Required!')
        .test(
            "fileSize",
            "Test report file size should not be greater than 2mb",
            function (value) {
                if (!value) return true;
                if(typeof(value) === "string") return true;
                return value.size <= FILE_SIZE
            })
        .test(
        "fileFormat",
        "Test report format should be jpg, jpeg, png, pdf",
        function (value) {
            if (!value) return true;
            if(typeof(value) === "string") return true;
            return SUPPORTED_FORMATS.includes(value.type)
        })
      }),
      bill: Yup.mixed().when('delivery_status', {
          is: 'In-Progress',
          then: Yup.mixed()
          // .required('Test report is Required!')
          .test(
              "fileSize",
              "Bill file size should not be greater than 2mb",
              function (value) {
                  if (!value) return true;
                  if(typeof(value) === "string") return true;
                  return value.size <= FILE_SIZE
              })
          .test(
          "fileFormat",
          "Bill format should be jpg, jpeg, png, pdf",
          function (value) {
              if (!value) return true;
              if(typeof(value) === "string") return true;
              return SUPPORTED_FORMATS.includes(value.type)
          })
        }),
        name: Yup.string().when('delivery_status', {
            is :'Sample Collected',
            then: Yup.string()
            .required("Person name is Required!")
        }),
        date: Yup.string().when('delivery_status', {
            is :'Sample Collected',
            then: Yup.string()
            .required("Date is Required!")
        }),
        time: Yup.string().when('delivery_status', {
            is :'Sample Collected',
            then: Yup.string()
            .required("Time is Required!")
        })
})

export default function App(props) {
    const {user_type, Quotedata, EditQuoteData} = props
    const [error, setError] = React.useState(<p></p>)
    const [billError, setBillError] = React.useState(<p></p>)

    console.log('Quotedata', Quotedata);

    const initialValues = {
        delivery_status : Quotedata?.order?.delivery_status,
        delivery_info : Quotedata?.order?.delivery_info ? Quotedata?.order?.delivery_info : "",
        bill : Quotedata?.order?.bill_path ? Quotedata?.order?.bill_path : "",
        name : Quotedata?.order?.sample_collect?.name ? Quotedata?.order?.sample_collect?.name : "",
        date : Quotedata?.order?.sample_collect?.date ? Quotedata?.order?.sample_collect?.date : "",
        time : Quotedata?.order?.sample_collect?.time ? Quotedata?.order?.sample_collect?.time : "",
    }

    const reportFile = Quotedata?.order?.delivery_info ? Quotedata?.order?.delivery_info : ""
    const bill_path = Quotedata?.order?.bill_path ? Quotedata?.order?.bill_path : ""


     return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    if (values.delivery_info === "" && values.delivery_status != "Sample Collected") {
                        setError(<p style={{color:"red"}} >Test report is Required!</p>)
                    } else {
                        if (values.bill === "" && values.delivery_status != "Sample Collected") {
                            setBillError(<p style={{color:"red"}} >Bill is Required!</p>)
                        } else {
                            const formData = new FormData();
                            formData.append("delivery_status", values.delivery_status)
                            formData.append("sample[date]", values.date)
                            formData.append("sample[time]", values.time)
                            formData.append("sample[name]", values.name)
                            if (typeof(values.delivery_info) === "string") {
                                formData.append("delivery_info", "")
                            } else {
                                formData.append("delivery_info", values.delivery_info)
                            }
                            if (typeof(values.bill) === "string") {
                                formData.append("bill", "")
                            } else {
                                formData.append("bill", values.bill)
                            }
                            EditQuoteData(formData)
                        }
                    }
                }
    }
            >
                {({ handleSubmit, setFieldError, setFieldTouched, setErrors, setFieldValue, resetForm, handleChange, handleBlur, touched, values, errors, dirty }) => (
                    <>
                        {!(initialValues.delivery_status === "Cancelled") && <Form className="form form-label-right"> 

                <div className=" row" >
                <div className="col-lg-6">
                <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-age-native-simple">Status</InputLabel>
                    <Select
                    native
                    value={values.delivery_status}
                    name="delivery_status"
                    // onBlur={handleBlur}
                    onChange={e => {
                        if (values.delivery_status === "Pending" && e.target.value === "Sample Collected") {
                            setFieldValue("delivery_status", e.target.value)
                        }
                        if (values.delivery_status === "Sample Collected" && e.target.value === "In-Progress") {
                            
                            if (errors && Object.keys(errors).length === 0 && errors.constructor === Object) {
                                setFieldValue("delivery_status", e.target.value)
                            } else {
                                setFieldTouched('name', true)
                                setFieldTouched('date', true)
                                setFieldTouched('time', true)
                            }
                        }
                        if (values.delivery_status === "In-Progress" && e.target.value === "Completed") {
                            if (values.delivery_info === "") {
                                setError(<p style={{color:"red"}} >Test report is Required!</p>)
                            } else {
                                if (values.bill === "") {
                                    setBillError(<p style={{color:"red"}} >Bill is Required!</p>)
                                } else {
                                    setFieldValue("delivery_status", e.target.value)
                                    
                                }
                            }
                        }
                    }}
                    label="Status"
                    >Sample Collected
                    <option value="Pending">Pending</option>
                    <option value="Sample Collected">Sample Collected</option>
                    <option value="In-Progress">In-Progress</option>
                    <option value="Completed">Completed</option>
                    </Select>
                </FormControl>
                </div>
                <div className="col-lg-6">
                        {values.delivery_status === "In-Progress" && 
                        <>
                        <FileUpload
                        mandatory
                        label='Upload test reports'
                        errors={errors.delivery_info}
                        name="delivery_info"
                        value={values.delivery_info}
                        onChange={value => {
                            setFieldValue("delivery_info", value)
                            setError(<p></p>)
                            console.log('value', value);
                        }}
                        onBlur={handleBlur}
                        />
                        { !values.delivery_info.name && reportFile &&
                        <a href={reportFile} target="_blank" download="filename">View uploaded test report</a>
                        }
                        </>}
                        {error}
                        </div>
                    </div>

                    <div className=" row" >
                <div className="col-lg-6">
                </div>
                <div className="col-lg-6">
                        {values.delivery_status === "In-Progress" && 
                        <>
                        <FileUpload
                        mandatory
                        label='Upload Bill'
                        errors={errors.bill}
                        name="bill"
                        value={values.bill}
                        onChange={value => {
                            setFieldValue("bill", value)
                            setBillError(<p></p>)
                            console.log('value', value);
                        }}
                        onBlur={handleBlur}
                        />
                        { !values.bill.name && bill_path &&
                        <a href={bill_path} target="_blank" download="filename">View uploaded bill</a>
                        }
                        </>}
                        {billError}
                        </div>
                    </div>
                    { values.delivery_status === "Sample Collected" && 
                    <>
                    <div className=" row" >
                    <div className="col-lg-6">
                        <TextField
                            fullWidth
                            required
                            label="Person who collected the sample"
                            name="name"
                            type="text"
                            value={values.name}
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            {errors.name && touched.name && (
                            <p style={{color:"red"}}>{errors.name}</p>
                            )}
                    </div>
                    <div className="col-lg-6">
                    </div>
                    </div>

                    <div className=" row" style={{
                        marginTop: '20px'
                    }} >
                    <div className="col-lg-6">
                    <div className=" row" >
                    <div className="col-lg-6">
                   <DatePickerField 
                                mandatory
                                name="date"
                                label="Date Of Collection"
                                onChange={val => {
                                  setFieldValue('date', String(val))
                                }}
                                onBlur={e => {
                                  handleBlur(e);
                                }}
                                value={values.date}
                                touched={touched.date}
                                errors={errors.date}
                                 />
                    </div>
                    <div className="col-lg-6">
                    <TextField
                    fullWidth
                    required
                            id="time"
                            label="Time"
                            variant="outlined"
                            type="time"
                            name="time"
                            value={values.time}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            inputProps={{
                            step: 300, // 5 min
                            }}
                        />
                        {errors.time && touched.time && (
                        <p style={{color:"red"}}>{errors.time}</p>
                        )}
                    </div>
                    </div>
                    </div>
                    </div>
                    </>}

                    <div className=" row" >
                <div className="col-lg-10">
                        </div>
          <div className="col-lg-2" style={{display: 'flex', justifyContent: 'flex-end'}}>
                    {Quotedata?.order?.delivery_status === "Completed" ? (<></>) : (<button style={{width:'130px'}}
                    disabled={!dirty}
                    type="submit"
                    className="btn btn-primary"
                    >
                    Save
                    </button>)}
                    </div>
                    </div>
                        </Form>}
                    </>
                )}
            </Formik>
        </>
    );
}
