import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { toastSuccess } from '../helpers/util/toast';
import autoCatch from '../helpers/util/autoCatch';
import { AccountTypes } from '../helpers/general/Form/SepcificSelect'
import { mobileNumberRegex, regId, nameRegex, ifsc_code, handleNumberWithCode } from '../helpers/util/formUtil'
import ButtonWithSpinner from '../helpers/general/Form/ButtonWithSpinner';
import Input from './../helpers/general/Form/Input';
import DatePicker from './../helpers/general/Form/DatePicker';
import pharmacyRegister from '../helpers/api/pharmacy/pharmacyRegister';
import { PhoneNumberUtil } from 'google-libphonenumber'
import CountryCode from '../helpers/general/Form/CountryCode'
import FileUpload from './../helpers/general/Form/FileUpload'
import { connect } from "react-redux";
import { CourseSelect } from '../helpers/components/SepcificSelect'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { showSuccessToast, setUserData } from '../../../../../../redux';
import TermsAndConditions from "../../TermsAndConditions/model"
import { countryCodeData as options } from './../helpers/util/countyCodeMapping';

const defaultFormValues = {
    pharmacist_name: '',
    pharmacist_registration_number: '',
    alt_country_code: '+91',
    alt_mobile_number: '',
    issuing_authority: '',
    registration_date: '',
    valid_upto: '',
    account_holder_full_name: '',
    bank_city: '',
    bank_name: '',
    account_number: '',
    ifsc_code: '',
    account_type: 'SAVINGS',
    upload_dl: '',
    iAgree: false,
    course: 'D.Pharm',
    course_name: ''
}

export const handleNumber = (input, countryCodeInt) => {

    // Get an instance of `PhoneNumberUtil`.
    const phoneUtil = PhoneNumberUtil.getInstance();
    if (!input || !countryCodeInt) return false;

    try {
        const number = phoneUtil.parseAndKeepRawInput(input, countryCodeInt);
        const isValid = phoneUtil.isValidNumber(number);
        return isValid;
    } catch (e) {
        console.log(e)
    }
    return false;
}
const mapStateToProps = state => {
    const { pharmacyId } = state;
    return { pharmacyId };
};

const FILE_SIZE = 1024 * 1024 * 2;
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf"
];
function daysInMonth(anyDateInMonth) {
    return new Date(anyDateInMonth.getFullYear(),
        anyDateInMonth.getMonth() + 1,
        0).getDate();
}

const PharmacistDetails = (props) => {

    const [loading, setLoading] = useState(true);
    const [currentCountry, setCurrentCountry] = useState()
    const history = useHistory();
    const dispatch = useDispatch()

    const validationSchema = Yup.object({
        pharmacist_name: Yup.string()
            .matches(nameRegex, 'Must be a standard name')
            .required('Name of pharmicist  is required'),

        pharmacist_registration_number: Yup.string()
            // .matches(regId, "Must be a standard registration number.")
            .required('Registration number is required'),
        issuing_authority: Yup.string()
            .required('Issuing authority is required'),
        alt_country_code: Yup.string()
            .matches(mobileNumberRegex, "Invalid code")
            .required('Country code is required.'),
        alt_mobile_number: Yup.number('Must be a number')
            .nullable()
            .test('mobile-num-1', 'Invalid mobile number', function (value) {
                if (!value) return true;
                const { alt_country_code: country_code } = this.parent
                return handleNumberWithCode(value, country_code)
            }),
        upload_dl: Yup.mixed()
            .required("Registration certificate required")
            .test(
                "fileSize",
                "File size too large",
                value => value && value.size <= FILE_SIZE
            )
            .test(
                "fileFormat",
                "Invalid File Format",
                value => value && SUPPORTED_FORMATS.includes(value.type)
            ),
        registration_date: Yup.date('Must be a valid date')
            // .test('daysInMonth', 'Invalid Date',
            //     value => value && daysInMonth(value))
            .max(new Date(), 'Registration date cannot be future dates')
            .required('The Date of registration field is required.'),
        valid_upto: Yup.date('Must be a valid date')
            // .test('daysInMonth', 'Invalid Date',
            //     value => value && daysInMonth(value))
            .min(new Date(), 'Validity date must be future dates.')
            .required('The Date of validity field is required.'),
        // account_number: Yup.string()
        //     .matches(/^[0-9]*$/, 'Account number must be number')
        //     .required('The bank account numberfield is required.'),
        // account_holder_full_name: Yup.string()
        //     .matches(nameRegex, 'Must be a valid name')
        //     .required('The bank account holder field is required.'),
        // bank_name: Yup.string()
        //     .matches(nameRegex, 'Must be a valid bank name')
        //     .required('The bank name field is required.'),
        // bank_city: Yup.string()
        //     .matches(nameRegex, 'Must be a valid bank city')
        //     .required('The bank city field is required.'),
        // ifsc_code: Yup.string()
        //     .matches(ifsc_code, 'Must be a valid IFSC code')
        //     .required('The IFSC field is required.'),
        // account_type: Yup.string()
        //     .matches(nameRegex, 'Must be a valid account type')
        //     .required('The account type field is required.'),
        course: Yup.string()
            .required('Select a course'),
        course_name: Yup.string().when('course', {
            is: 'Others',
            then: Yup.string().required('Course name is required')
        }),

    })

    let initialValues = defaultFormValues;
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setErrors, setSubmitting }) => {
                setSubmitting(true)
                let formData = new FormData();
                const {
                    pharmacist_name,
                    pharmacist_registration_number,
                    issuing_authority,
                    alt_country_code,
                    alt_mobile_number,
                    valid_upto,
                    upload_dl,
                    registration_date,
                    account_number,
                    account_holder_full_name,
                    bank_name,
                    bank_city,
                    ifsc_code,
                    account_type,
                    course,
                    course_name
                } = values
                let alt_mob = values.alt_mobile_number === '' ? '' : values.alt_mobile_number
                if (pharmacist_name && pharmacist_registration_number && registration_date
                    && issuing_authority && valid_upto && props.pharmacyId.addressId) {
                    formData.append('data_id', props.pharmacyId.addressId)
                    formData.append('pharmacist_name', pharmacist_name)
                    formData.append('pharmacist_reg_number', pharmacist_registration_number)
                    formData.append('reg_date', registration_date)
                    formData.append('issuing_authority', issuing_authority)
                    formData.append('reg_valid_upto', valid_upto)
                    formData.append('alt_mobile_number', alt_mob)
                    formData.append('alt_country_code', alt_country_code)
                }
                // if (account_number && account_holder_full_name && bank_name && ifsc_code
                //     && bank_city && account_type) {
                    formData.append('bank_account_number', account_number)
                    formData.append('bank_account_holder', account_holder_full_name)
                    formData.append('bank_name', bank_name)
                    formData.append('bank_ifsc', ifsc_code)
                    formData.append('bank_city', bank_city)
                    formData.append('bank_account_type', account_type)
                // }

                if (course !== 'Others') {
                    formData.append('course', course)
                } else {
                    formData.append('course', course_name)
                }
                if (upload_dl) {
                    formData.append('reg_certificate', upload_dl)
                }
                autoCatch(pharmacyRegister.AdditionalDetailsRegister(formData).then(res => {
                    if (res.status === 200) {
                        toastSuccess('registration successful')
                        const { email, mobile_number } = res.data
                        dispatch(setUserData({ email, mobile_number }))
                        dispatch(showSuccessToast("Please complete OTP verification."))
                        setSubmitting(false);
                        history.push('/auth/registration/pharmacy/confirm?via=WEB');
                    }
                    setLoading(true);
                    setSubmitting(false);
                }), data => {
                    if (data && data.errors) {
                        setErrors(data.errors)
                    }
                    setSubmitting(false);
                })
            }}
        >{({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setTouched,
            setFieldTouched,
            isSubmitting,
            dirty,
            isValid,
        }) => (
                <form onSubmit={handleSubmit}>
                    <h4 className="card-title my-4">Pharmacist Details</h4>
                    <h5 className="card-title my-4">Additional details</h5>
                    {/** row 1 */}
                    <div className="row form-row">
                        <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                                <label>Name of Pharmacist</label>
                                <Input
                                    name="pharmacist_name"
                                    mandatory
                                    label="Name of Pharmacist"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.pharmacist_name}
                                    touched={touched.pharmacist_name}
                                    errors={errors.pharmacist_name}
                                />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                                <label>Registration Number of Pharmacist</label>
                                <Input
                                    mandatory
                                    name="pharmacist_registration_number"
                                    label="Registration Number"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.pharmacist_registration_number}
                                    touched={touched.pharmacist_registration_number}
                                    errors={errors.pharmacist_registration_number}
                                />
                            </div>
                        </div>
                    </div>
                    {/** row 2 */}
                    <div className="row form-row">
                        <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                                <label>Issuing Authority</label>
                                <Input
                                    mandatory
                                    name="issuing_authority"
                                    label="Issuing Authority"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.issuing_authority}
                                    touched={touched.issuing_authority}
                                    errors={errors.issuing_authority}
                                />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                                <label>Alternative Mobile Number </label>
                                <div className="phone-number">
                                    <div className="w-100 d-flex">
                                        <div className="w-25">
                                            {/* <PhoneCode
                                defaultValue="+91"
                                name="country_code"
                                onChange={setFieldValue}
                                value={values.country_code}
                                /> */}
                                            
                                            
                                            
                                            <select className='form-control'
                                                name="alt_country_code"
                                                onChange={handleChange}
                                                onBlur={handleBlur} 
                                                value={values.alt_country_code}
                                                >
                                                {
                                                    options.map((option, index) => {
                                                    return <option key={index} value={option.value}>{option.label}</option>
                                                    })
                                                }
                                                </select>
                                        </div>
                                        <div className="w-75">
                                            <Input
                                                name="alt_mobile_number"
                                                label="Alternative Mobile number"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="number"
                                                value={values.alt_mobile_number}
                                                touched={touched.alt_mobile_number}
                                                errors={errors.alt_mobile_number}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/** row 3 */}
                    <div className="row form-row">
                        <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                                <label>Upload Registration Certificate<span className="text-danger">*</span></label>
                                <FileUpload
                                    errors={errors.upload_dl}
                                    name="upload_dl"
                                    mandatory
                                    onChange={value => {
                                        console.log(value)
                                        setFieldValue("upload_dl", value)
                                    }}
                                    onBlur={setTouched}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                                <label>Select Course<span className="text-danger">*</span></label>
                                <CourseSelect
                                    value={values.course}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />
                            </div>
                        </div>
                        {values.course === 'Others' && (
                            <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                    <label>Course Name<span className="text-danger">*</span></label>
                                    <Input
                                        name="course_name"
                                        label="Course Name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.course_name}
                                        touched={touched.course_name}
                                        errors={errors.course_name}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {/** row 4 */}
                    <div className="row form-row">
                        <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                                {/* <label>Registration Date<span className="text-danger">*</span></label>
                                <input
                                    type="date"
                                    className={"form-control" + (errors.registration_date && touched.registration_date ? " is-invalid" : "")}
                                    name="registration_date"
                                    onChange={e => {
                                      if (new Date("9999-12-31") >= new Date(e.target.value)) {
                                        setFieldValue('registration_date', e.target.value)
                                        handleChange(e);
                                      }
                                    }}
                                    onBlur={e => {
                                        setFieldValue('registration_date', e.target.value)
                                        handleBlur(e);
                                    }}
                                    value={values.registration_date}
                                />

                                {errors.registration_date && (
                                    <div style={{ margin: 0, position: "absolute" }} className="invalid-feedback">{errors.registration_date}</div>
                                )} */}
                                
                
                                <DatePicker
                                mandatory
                                name="registration_date"
                                label="Registration Date"
                                placeholder="Registration Date"
                                onChange={val => {
                                    setFieldValue('registration_date', String(val))
                                }}
                                onBlur={e => {
                                    handleBlur(e);
                                }}
                                value={values.registration_date}
                                touched={touched.registration_date}
                                errors={errors.registration_date}
                                />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                                {/* <label>Registration Valid Upto<span className="text-danger">*</span></label>
                                <input
                                    type="date"
                                    className={"form-control" + (errors.valid_upto && touched.valid_upto ? " is-invalid" : "")}
                                    name="valid_upto"
                                    onChange={e => {
                                      if (new Date("9999-12-31") >= new Date(e.target.value)) {
                                        setFieldValue('valid_upto', e.target.value)
                                        handleChange(e);
                                      }
                                    }}
                                    onBlur={e => {
                                        setFieldValue('valid_upto', e.target.value)
                                        handleBlur(e);
                                    }}
                                    value={values.valid_upto}
                                />

                                {errors.valid_upto && (
                                    <div style={{ margin: 0, position: "absolute" }} className="invalid-feedback">{errors.valid_upto}</div>
                                )} */}
                                <DatePicker
                                mandatory
                                name="valid_upto"
                                label="Registration Valid Upto"
                                placeholder="Registration Valid Upto"
                                onChange={val => {
                                    setFieldValue('valid_upto', String(val))
                                }}
                                onBlur={e => {
                                    handleBlur(e);
                                }}
                                value={values.valid_upto}
                                touched={touched.valid_upto}
                                errors={errors.valid_upto}
                                />
                            </div>
                        </div>
                    </div>
                    {/** row 5 */}
                    <h5 className="card-title my-4">Banking Details</h5>
                    <div className="row form-row">
                        <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                                <label>Bank Account Number</label>
                                <Input
                                    
                                    name="account_number"
                                    label="Account Number"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.account_number}
                                    touched={touched.account_number}
                                    errors={errors.account_number}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                                <label>Account Holder Full Name</label>
                                <Input
                                    
                                    name="account_holder_full_name"
                                    label="Account Holder Full Name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.account_holder_full_name}
                                    touched={touched.account_holder_full_name}
                                    errors={errors.account_holder_full_name}
                                />
                            </div>
                        </div>
                    </div>
                    {/** row 6 */}
                    <div className="row form-row">
                        <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                                <label>Bank Name</label>
                                <Input
                                    
                                    name="bank_name"
                                    label="Bank Name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.bank_name}
                                    touched={touched.bank_name}
                                    errors={errors.bank_name}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                                <label>Bank City</label>
                                <Input
                                    
                                    name="bank_city"
                                    label="Bank City"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.bank_city}
                                    touched={touched.bank_city}
                                    errors={errors.bank_city}
                                />
                            </div>
                        </div>
                    </div>
                    {/** row 7 */}
                    <div className="row form-row">
                        <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                                <label>Bank IFSC Code</label>
                                <Input
                                    
                                    name="ifsc_code"
                                    label="IFSC Code"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.ifsc_code}
                                    touched={touched.ifsc_code}
                                    errors={errors.ifsc_code}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                              
                                <AccountTypes
                                    value={values.account_type}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />
                            </div>
                        </div>
                    </div>
                    {/** row 8 */}
                    <div className="row form-row">
                        <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                                {/* <div className="form-check">
                            <input className="form-check-input" 
                                type="checkbox" 
                                value="" 
                                id="invalidCheck" 
                                name="tandc"
                                />
                            <label className="form-check-label">
                                Agree to terms and conditions {dirty} {isValid} {isSubmitting}
                            </label>
                            <div className="invalid-feedback">
                                You must agree before submitting.
                            </div>
                        </div> */}
                                <div className="form-check form-check-inline mb-3">
                                    <input
                                        className="form-check-input"
                                        onChange={handleChange}
                                        type="checkbox"
                                        value="agree"
                                        name="iAgree"
                                        id="agreeCheckbox" />
                                        <label className="form-check-label" htmlFor="agreeCheckbox">I agree to the</label>
                                        <TermsAndConditions color="blue" />
                                        </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="form-group text-right">
                                <ButtonWithSpinner
                                    isLoading={isSubmitting}
                                    type="submit"
                                    disabled={!dirty || !isValid || !values.iAgree || isSubmitting}
                                >
                                    Register
                        </ButtonWithSpinner>
                            </div>
                        </div>
                    </div>

                </form>
            )}
        </Formik>
    )
}

export default connect(mapStateToProps, {})(PharmacistDetails);