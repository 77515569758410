import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { StoreEditForm } from "./StoreEditForm";
import Spinner from "../../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import {loadTotalTestData, loadSelectedTestData} from "../../../modules/Apis/Laboratory/Tests/TestsApi";

export const AddStores = (props) => {
  const [selectdata, setSelectdata] = React.useState(undefined)
  const [lab_tests, setdata] = React.useState(undefined)
  const [mounted, setMounted] = React.useState(true)
  const [loading, setLoading] = React.useState(true)


  const loadData = () => {
    if (mounted) {
      setMounted(false)
      loadTotalTestData()
      .then(items => {
        setMounted(false)
        setLoading(false)
        setSelectdata(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
        setLoading(false)
        setMounted(false)
    })
    }
  }

  const loadTestData = () => {
    if (mounted) {
      setMounted(false)
      loadSelectedTestData()
      .then(items => {
        setMounted(false)
        setLoading(false)
        setdata(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
        setLoading(false)
        setMounted(false)
    })
    }
  }

  React.useEffect(() => {
    loadData()
    loadTestData()
  }, [loadData, loadTestData])

  const updateData = () => {
    setLoading(true)
    setSelectdata(undefined)
    setdata(undefined)
    
    loadTotalTestData()
    .then(items => {
      setLoading(false)
      setSelectdata(items.data)
      
    })

    loadSelectedTestData()
      .then(items => {
        setLoading(false)
        setdata(items.data)
      })
  }

  const backToStoresList = () => props.history.goBack()
  const saveStore = () => { }
  const btnRef = useRef();
  const resetbtnRef = useRef()
  const saveStoreClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };
  const resetForm = () => {
    if (resetbtnRef && resetbtnRef.current) {
      resetbtnRef.current.click()
    }
  }

  if (loading) {
    return (
      <Spinner />
    )
  }
  return (
    <Card>
      <CardHeader title="Add Test">
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToStoresList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
              Back
            </button>
          {`  `}
          {/* <button
            className="btn btn-light ml-2"
            onClick={resetForm}>
            <i className="fa fa-redo"></i>
              Reset
            </button>
          {`  `} */}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveStoreClick}
          >
            Add
            </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          {selectdata && lab_tests && <StoreEditForm
            // actionsLoading={actionsLoading}
            btnRef={btnRef}
            resetbtnRef={resetbtnRef}
            saveProduct={saveStore}
            selectdata={selectdata}
            lab_tests={lab_tests.lab_tests}
            updateData={updateData}
          >
          </StoreEditForm>}
        </div>
      </CardBody>
    </Card>
  )
};
