import React from "react";
import RequestView from "./QuoteRequestView/ShowForm";
import CreateView from "./CreateNewQuote/ShowForm";

export function Form (props) {
    const [step, setStep] = React.useState(1)
    const{user_type} =props



          const nextStep = () => {
              setStep(step + 1)
          }

          const previousStep = () => {
            setStep(step - 1)
        }

          switch (step) {
            case 1 :
                return (
                    <div>
                        <RequestView
                            Quotedata={props.Quotedata}
                            searchlist={props.searchlist}
                            SaveNewQuoteData={props.SaveNewQuoteData}
                            nextStep={nextStep}
                            user_type={user_type}
                        />
                    </div>
                )
            case 2 :
                return (
                    <div>
                        <CreateView
                            Quotedata={props.Quotedata}
                            searchlist={props.searchlist}
                            SaveNewQuoteData={props.SaveNewQuoteData}
                            previousStep={previousStep}
                            user_type={user_type}
                        />
                    </div>
                )
            default:
                return (
                    <div></div>
                )
    }

}