
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteModel from "./DeleteModal";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import {AddTestData} from "../../../modules/Apis/Laboratory/Tests/TestsApi";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));


export default function SimpleTable(props) {
    const {selectdata, lab_tests} = props
  const classes = useStyles();
  const rows =[]

  
  lab_tests.map((item) => {
      const data = selectdata.filter(row => row.id == item.id)
      item['data'] = data[0]
    rows.push(item)
  })

  console.log('rows', rows);

  const editDetails = (data) => {
    const reqData = {
        lab_tests : data
    }
    AddTestData(reqData)
      .then(items => {
          props.updateData()
        // if (items && items.data && items.data.message) {
        //   SuccessToast(items.data.message)
        // }
        SuccessToast("Test Deleted Succesfully")
        
      })
      .catch(err => {
        ErrorToast(err)
    })
  }
  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>SL NO</TableCell>
            <TableCell align="center">TEST ID</TableCell>
            <TableCell align="left">TEST NAME</TableCell>
            <TableCell align="center">SAMPLE COLLECT FROM HOME</TableCell>
            <TableCell align="center">DELETE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {index+1}
              </TableCell>
              <TableCell align="center">{row?.data?.unique_id}</TableCell>
              <TableCell align="left">{row?.data?.name}</TableCell>
              {row?.sample_collect == 1 && <TableCell align="center">{'yes'}</TableCell>}
              {row?.sample_collect == 0 && <TableCell align="center">{'No'}</TableCell>}
              <TableCell align="center">
                  <DeleteModel row={row} lab_tests={lab_tests} editDetails={editDetails} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
